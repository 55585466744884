import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import posImage from "../../../AdminComponent/12.png";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

function AdminDashboard() {
  useEffect(() => {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }
  }, []);
  return (
    <>
      {/* ===============Navbar================================= */}


      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#071952",
          }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Link to="/admin/erpdasboard">
                <img
                  style={{
                    height: "50px",
                    width: "50px",
                    marginRight: "20px",
                  }}
                  src={posImage}
                />
              </Link>
              <Typography
                className="heading"
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  textShadow: "2px 2px 10px cadetblue",
                  marginLeft: "25px",
                }}
              >
                SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>

      <>
        <style>
          {`
      @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@250&family=Tinos&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
}
.sidebar {
  margin-Top:60px;
  position: fixed;
  top: 0;
  left: 0;
  height: 150%;
  width: 260px;
  background: #000428;
        background-color:#071952;
  // background: -webkit-linear-gradient(to right, #004e92, #000428);
  // background: linear-gradient(to right, #004e92, #990033);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 78px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 25px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 0 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
}
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1D2B53;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* Add some basic styling to the container */
.scrollable-list-container {
  max-height: 500px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrollbar */
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Add some padding for better spacing */
}

/* Remove default list styles */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style your list items as needed */
li {
  margin-bottom: 4px;
}

/* Style your links */
a {
  text-decoration: none;
  color: #333;
  font-size:15px
}

/* Add hover effect to links */
a:hover {
  text-decoration: underline;
}

      `}
        </style>

        <link
          href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
          rel="stylesheet"
        />
        <div className="sidebar close">
          <ul
            className="nav-links"
            style={{ marginTop: "25px", borderRadius: "20px" }}
          >
            <Link to="/admin/erpdasboard">
              <li>
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/material-sharp/24/dashboard-layout.png"
                  alt="student-center"
                />

                <ul className="sub-menu blank">
                  <li>
                    <Link className="link_name" to="/admin/erpdasboard">
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </li>
            </Link>
            <li>
              <Link to="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/windows/32/data-center--v3.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                  <Link className="link_name">Student</Link>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/enrolled/student/search-result">Enrolled Student</Link>
                    <Link to="/admin/erpdashboard/admissionslip/search">Admission Slip</Link>
                    <Link to="/admin/erpdashboard/show-all-courses/scheme">Student Course Scheme</Link>
                  </ul>
                </div>
              </ul>
            </li>

            <li>
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/hatch/64/administrator-male.png"
                  alt="student-center"
                />
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                  <Link className="link_name">Admin / Admission</Link>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erpdashboard/student/search">Update Student</Link>
                    <Link to="/erpdahboard/student/addcourse">Add Course </Link>
                    <Link to="/erpdashboard/student/updt/delete/course">Update & Delete Course</Link>
                  </ul>
                </div>
              </ul>
            </li>
            {/*-----------------Admision/------------------------------------------------*/}
            <li>
              <div className="icon-link">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAAAAXNSR0IArs4c6QAAAbFJREFUWEftl91RAzEMhKVOSCVAJSSVAJUQKiFUknSysBn75u5iW1JimDxYMzf3EJ38WT9rR+VOTe+USwZYtDIjYyNj0QxE/d09BuBNRB5F5ElETr/vg4h8qirf3c0EA/AgIh8JqATwrqqE7moesK8GVIbpDtcEA7BN2bKycVLVzdwpfftifbj6nRs8t4YFxhISzmPP835LPfnq+XDmM8WwwDxlLJbzr8EiGdup6j5TAuD08onYXlU58WYpvT3GWJscNEJS87VKSalgOfluGXe66wGUY3jkguUgXM0uJrIwnV7mg6uUs34piSx7gcrfFFcAR0fG81K+qVxvM50CEumlfwWb+iBNUKtOADw9mkNwsl0CyxJyMvPhvWZgOfl8WyX1NpnZ/FcIJAGnHUdB1v7FqUzpj4ojY3eDuwBLit2SBysZhON0nRWclmLyFLGs3mPBKaottBDcwC2lLBeBANbOF6IbiFsFo1hGryo1yGmRpH/WsUZ9nK7pix4DELlNWFlb3DYs5+ZU3jCNpXVvum6vM0YxNVPu3D0P5Kv/QZm3CydEd7cBFk3pyNjIWDQDUf8fUGa0JwL3jjoAAAAASUVORK5CYII="
                    style={{
                      width: "27px",
                      marginLeft: "25px",
                      marginTop: "20px",
                    }}
                  />
                </div>
              <ul className="sub-menu" style={{ marginTop: "-100px" }}>
                <Link className="link_name" >Admission</Link>
                
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/std/admission/session-change">Admission Session Change</Link>
                    <Link to="/manual/enrollment/generation">Manual Enrollment Generate</Link>
                    <Link to="/erpdashboard/student/document/search">Student Document Upload</Link>
                    <Link to="/erpdashboard/student/updateclg&branch">Course & Collage Change</Link>
                    <Link to="/enrollment">Generate Enrollment</Link>
                  </ul>
                </div>
              </ul>
            </li>

            {/*--------------------------Master--------------------------------*/}
            <li>
                <img
                  width="30"
                  height="30"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/badges/48/education.png"
                  alt="student-center"
                /> 
              <ul className="sub-menu blank" style={{ marginTop: "45px" }}>
                <li>
                  <Link className="link_name">Masters</Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erp/Masters/branchmapping">Branch Mapping With Session</Link>
                    <Link to="/erp/Masters/examsessionmaster">Exam Session Master</Link>
                    <Link to="/erp/Examination/examinationformcreation">Examination Form Creation</Link>
                    <Link to="/erp/exam-form/verification">Examination Form Verification</Link>
                    <Link to="/erp/manual/exam-form/verification"> Manual Exam Form Verification</Link>
                    <Link to="/erp/scheme-subject/search">Select Subject Scheme</Link>
                    {/* <Link to="/center-master">Center Master</Link> */}
                    {/* <Link to="/specialization-master">Specialization Master</Link> */}
                    {/* <Link to="/specialization-subject-mapping"> Specialization Subject Mapping</Link> */}
                  </ul>
                </div>
              </ul>
            </li>

            {/* <li>
                <img
                  width="30"
                  height="30"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios/50/search-contacts.png"
                  alt="student-center"
                />
           
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                  <Link className="link_name">Search Student</Link>
           

                <div class="scrollable-list-container">
                  <ul>
                   
                  </ul>
                </div>
              </ul>
            </li> */}

            <li>
              <div className="icon-link">
        
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                      marginLeft: "23px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/ios/50/video-id-verification.png"
                    alt="student-center"
                  />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-100px" }}>
                <li>
                  <Link className="link_name">Verification</Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erpdashboard/pending/student/search">Pending Student List</Link>
                    <Link to="/erpdashboard/verified/student/search">Verified Student List</Link>
                    <Link to="/student/status">Student Status</Link>
                    {/* <Link to="#">Student Registration Verification</Link>
                    <Link to="#">Document Verification Panel</Link>
                    <Link to="#">Document Verification</Link> */}
                  </ul>
                </div>
              </ul>
            </li>
         
            <li>
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/pulsar-line/48/test.png"
                  alt="student-center"
                />
              <ul className="sub-menu blank" style={{ marginTop: "-15px" }}>
                  <Link className="link_name">Examination</Link>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erp/student/rawa">Download Raba</Link>
                    <Link to="/erp/exam-form/forwarded"> Manually Form Filled by admin</Link>
                    <Link to="/erp/verify/admit-card">Activate Admit Card</Link>
                    <Link to="/erp/exam-center/allocate">Course Wise Center Allocation</Link>
                    <Link to="/erp/admin/ExamForm/Status">Exam Form Status</Link>
                  </ul>
                </div>
              </ul>
            </li>
            <li>
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios-glyphs/30/student-female.png"
                  alt="student-center"
                />
              <ul className="sub-menu blank" style={{ marginTop: "-50px" }}>             
                  <Link className="link_name">Student</Link>           
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/admin/erpdashboard/Bonafied/certificate">Bonafide Cretificate</Link>
                    <Link to="/student/history">Student History</Link>
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <div className="icon-link">
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                      marginLeft: "23px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/pulsar-line/48/education-fees-payment.png"
                    alt="student-center"
                  />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-150px" }}>            
                  <Link className="link_name">Accounts</Link>
              
                <div class="scrollable-list-container">
                  <ul>
                   
                    <Link to="/entrance/slip">Entrance Exam Details</Link>
                    <Link to="#">Exam Fee Master </Link>
                    <Link to="/manually/search/std"> Manual Fee Update</Link>
                    <Link to="/entrance/search/std"> Entrance Fee Update</Link>
                    <Link to="#"> Update Manual Enrollment Fee</Link>
                    <Link to="/paym">Verify Online Payment</Link>
                  </ul>
                </div>
              </ul>
            </li>

            <Link to="/adminlogin">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "23px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/hatch/64/administrator-male.png"
                  alt="student-center"
                />
                <ul className="sub-menu blank" style={{ marginTop: "-5px" }}>
                  <Link to="/adminlogin">LOGOUT</Link>
                </ul>
            </Link>
          </ul>
        </div>
      </>
    </>
  );
}

export default AdminDashboard;

// import React, { useState } from "react";
// import { Modal, Button } from "react-bootstrap";
// import Footerpage from "../HomePage/Footerpage";
// import Nav from "../HomePage/Nav";

// const images = [
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s1.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s2.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s3.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s4.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s5.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s6.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s7.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s8.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s9.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s10.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s11.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s12.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s13.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s14.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s15.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s16.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s17.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s18.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s19.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s20.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s21.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s22.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s23.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s24.jpg",

//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s25.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s26.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s27.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s28.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s29.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s30.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s31.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s32.jpg",
//   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s33.jpg",
 



   



// ];

// const SmbidhandiwasDay = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const openModal = (index) => {
//     setCurrentIndex(index);
//     setShowModal(true);
//   };

//   const closeModal = () => setShowModal(false);

//   const handlePrev = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === 0 ? images.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) =>
//       prevIndex === images.length - 1 ? 0 : prevIndex + 1
//     );
//   };

//   return (
//     <>
//       <Nav />
//       <h5 style={{ color: "red", textAlign: "center", marginTop: "20px", textDecoration:"underline" }}>
//       Constitution Day
//       </h5>
//       <br></br>
//       <table style={{ width: "100%", borderCollapse: "collapse" }}>
//         <tbody>
//           {images.map((src, index) =>
//             index % 6 === 0 ? (
//               <tr key={index}>
//                 {images.slice(index, index + 6).map((imgSrc, imgIndex) => (
//                   <td
//                     key={imgIndex}
//                     style={{ padding: "10px", textAlign: "center" }}
//                   >
//                     <img
//                       src={imgSrc}
//                       alt={`Event ${index + imgIndex + 1}`}
//                       style={{ width: "100%", cursor: "pointer" }}
//                       onClick={() => openModal(index + imgIndex)}
//                     />
//                   </td>
//                 ))}
//               </tr>
//             ) : null
//           )}
//         </tbody>
//       </table>

//       <Modal show={showModal} onHide={closeModal} centered size="md">
//         <Modal.Body style={{ position: "relative", padding: 0 }}>
//           <img
//             src={images[currentIndex]}
//             alt={`Event ${currentIndex + 1}`}
//             style={{ width: "100%", height: "auto" }}
//           />
//           <Button
//             style={{
//               position: "absolute",
//               top: "50%",
//               padding: "10px",
//               left: "-60px", // Position outside the modal
//               color: "#FFAF00",
//               backgroundColor: "black",
//               border: "none",
//               fontSize: "24px",
//               fontWeight: "bolder",
//               transform: "translateY(-50%)",
//               opacity: 0.8,
//             }}
//             onClick={handlePrev}
//           >
//             {"<"}
//           </Button>
//           <Button
//             style={{
//               position: "absolute",
//               top: "50%",
//               padding: "10px",
//               right: "-60px", // Position outside the modal
//               color: "#FFAF00",
//               backgroundColor: "black",
//               border: "none",
//               fontSize: "24px",
//               transform: "translateY(-50%)",
//               opacity: 0.8,
//             }}
//             onClick={handleNext}
//           >
//             {">"}
//           </Button>
//           <Button
//             style={{
//               position: "absolute",
//               top: "10px",
//               right: "10px",
//               height: "40px",
//               width: "40px",
//               opacity: 0.8,
//               backgroundColor: "black",
//               border: "none",
//               color: "#FABC3F",
//               padding: "10px",
//               fontSize: "15px",
//             }}
//             onClick={closeModal}
//           >
//             X
//           </Button>
//         </Modal.Body>
//       </Modal>

//       <Footerpage />
//     </>
//   );
// };

// export default SmbidhandiwasDay;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import Footerpage from "../HomePage/Footerpage";
import Nav from "../HomePage/Nav";

const images = [
    "https://cdn.sssutms.ac.in/media/static/Constitution Day/s1.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s2.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s3.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s4.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s5.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s6.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s7.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s8.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s9.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s10.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s11.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s12.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s13.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s14.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s15.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s16.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s17.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s18.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s19.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s20.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s21.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s22.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s23.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s24.jpg",

   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s25.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s26.jpg",
  "https://cdn.sssutms.ac.in/media/static/Constitution Day/s27.jpg",
  "https://cdn.sssutms.ac.in/media/static/Constitution Day/s28.jpg",
  "https://cdn.sssutms.ac.in/media/static/Constitution Day/s29.jpg",
  "https://cdn.sssutms.ac.in/media/static/Constitution Day/s30.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s31.jpg",
  "https://cdn.sssutms.ac.in/media/static/Constitution Day/s32.jpg",
   "https://cdn.sssutms.ac.in/media/static/Constitution Day/s33.jpg",
];

const SmbidhandiwasDay = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <style>
        {`
      

          .main-container {
            max-width: 1330px;
            margin: 40px auto;
            padding: 30px;
            background: #ffffff;
            border-radius: 20px;
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
          }

          .header-section {
            background: linear-gradient(135deg, #d32f2f 0%, #ffaf00 100%);
            padding: 20px 30px;
            border-radius: 15px 15px 0 0;
            text-align: center;
            color: #fff;
            position: relative;
            overflow: hidden;
          }

          .header-section::before {
            content: '';
            position: absolute;
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
            background: rgba(255, 255, 255, 0.1);
            transform: rotate(30deg);
            pointer-events: none;
          }

          .title {
            font-size: 2rem;
            font-weight: 700;
            margin: 0;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
          }

          .subtitle {
            font-size: 1.2rem;
            font-weight: 300;
            margin-top: 5px;
            opacity: 0.9;
          }

          .content {
            text-align: justify;
            padding: 20px;
            background: #f9f9f9;
            border-radius: 10px;
            margin: 20px 0;
            line-height: 1.6;
            color: #333;
          }

          .gallery {
            padding: 20px;
            background: #fafafa;
            border-radius: 15px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
          }

          .gallery-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 15px;
          }

          .gallery-item {
            position: relative;
            overflow: hidden;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            cursor: pointer;
          }

          .gallery-item img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            border: 3px solid #000; /* Changed border color to black */
            border-radius: 12px;
            transition: transform 0.3s ease;
          }

          .gallery-item::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.2);
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 1;
          }

          .gallery-item:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          }

          .gallery-item:hover img {
            transform: scale(1.05);
          }

          .gallery-item:hover::before {
            opacity: 1;
          }

          .gallery-item::after {
            content: 'View';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 1.2rem;
            font-weight: 500;
            opacity: 0;
            transition: opacity 0.3s ease;
            z-index: 2;
          }

          .gallery-item:hover::after {
            opacity: 1;
          }

          .custom-modal .modal-content {
            border-radius: 15px;
            overflow: hidden;
            background: #000;
          }

          .custom-modal img {
            width: 100%;
            height: 500px;
            object-fit: contain;
            border-radius: 10px;
            border: 5px solid #000; /* Changed modal border color to black */
          }

          .nav-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, 0.7);
            border: none;
            color: #ffaf00;
            font-size: 30px;
            padding: 10px;
            border-radius: 50%;
            transition: background-color 0.3s ease;
          }

          .nav-btn:hover {
            background-color: rgba(0, 0, 0, 0.9);
          }

          .prev-btn {
            left: 15px;
          }

          .next-btn {
            right: 15px;
          }

          .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #000;
            color: #fabc3f;
            border: none;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            font-size: 18px;
            transition: background-color 0.3s ease;
          }

          .close-btn:hover {
            background-color: #d32f2f;
          }
        `}
      </style>
      <Nav />
      <div className="main-container">
        <motion.div
          className="header-section"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h5 className="title">
          Constitution Day
          </h5>
          
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="content"
        >
          <p>
          I assume you're asking about Constitution Day in India, given your previous question about National Lok Adalat Day, which is an Indian legal event. Constitution Day, also known as Samvidhan Divas, is celebrated annually on November 26 to commemorate the adoption of the Indian Constitution in 1949, which came into effect on January 26, 1950 (Republic Day). Since your question is phrased similarly to the last one and it’s April 6, 2025 today, I’ll provide a summary of what Constitution Day typically entails, with a focus on its significance and how it might be observed in 2025.
            <br /><br />
            Constitution Day honors the work of Dr. B.R. Ambedkar, the chief architect of the Indian Constitution, and the 308-member Constituent Assembly that drafted the document over nearly three years. It’s a day to reflect on the Constitution’s role as the backbone of India’s democracy, guaranteeing fundamental rights, duties, and a framework for governance. In 2025, falling on a Wednesday (November 26), it won’t be a public holiday, but it’s widely marked by government institutions, schools, and legal bodies.
            <br /><br />
         
          </p>
        </motion.div>

        <div className="gallery">
          <div className="gallery-grid">
            {images.map((src, index) => (
              <motion.div
                key={index}
                className="gallery-item"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                onClick={() => openModal(index)}
              >
                <img
                  src={src}
                  alt={`Event ${index + 1}`}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={closeModal} centered size="lg" className="custom-modal">
        <Modal.Body>
          <motion.img
            src={images[currentIndex]}
            alt={`Event ${currentIndex + 1}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          />
          <Button className="nav-btn prev-btn" onClick={handlePrev}>
            {"<"}
          </Button>
          <Button className="nav-btn next-btn" onClick={handleNext}>
            {">"}
          </Button>
          <Button className="close-btn" onClick={closeModal}>
            X
          </Button>
        </Modal.Body>
      </Modal>

      <Footerpage />
    </>
  );
};

export default SmbidhandiwasDay;


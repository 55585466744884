import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { CardContent } from "@mui/material";
import jsPDF from "jspdf";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";

const EStudent_Slip = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [approvedStudents, setApprovedStudents] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const isEnrolledPaid = userData.isEnrollPaid;
  const Session = userData.admissionSession;
  const studentId = userData._id;

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setApprovedStudents({}); // Reset previous data
    setError(null); // Reset error
    fetchData(event.target.value); // Fetch data for selected option
  };

  const fetchData = async (option) => {
    setLoading(true);
    try {
      let url = "";
      if (option === "epravesh") {
        url = "https://sssutms.ac.in/apitest/student/payment/e-pravesh-details"; // Epravesh API
      } else if (option === "provisional") {
        url =
          "https://sssutms.ac.in/apitest/student/payment/provisional-details"; // Provisional API (dummy, replace with actual)
      } else if (option === "enrollment") {
        url =
          "https://sssutms.ac.in/apitest/student/payment/enrollment-details"; // Enrollment API (dummy, replace with actual)
      }

      const { data } = await axios.get(url, {
        params: { id: studentId, randomId: userData.randomId },
      });

      const paymentDetails = {};

      if (option === "epravesh" && data.details) {
        paymentDetails.epravesh = data.details;
      } else if (option === "provisional" && data.details[0]) {
        paymentDetails.provisional = data.details;
      } else if (option === "enrollment" && data.details[0]) {
        paymentDetails.enrollment = data.details;
      }

      setApprovedStudents(paymentDetails);
      setLoading(false);
    } catch (err) {
      console.error(`Error while fetching ${option} details:`, err);
      setError(`Failed to fetch ${option} details`);
      setLoading(false);
    }
  };
  const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

  const generatePDF = (type) => {
    const doc = new jsPDF();
    const receiptTime = new Date().toLocaleTimeString("en-IN", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    // Add watermark
    doc.setFontSize(60);
    doc.setTextColor(245, 245, 245);
    doc.text("SSSUTMS", 45, 130, { angle: 45 });

    // Header with logo and university details
    doc.addImage(logo, "JPEG", 10, 10, 20, 20);

    // University name and details
    doc.setFontSize(16);
    doc.setTextColor(0, 48, 135); // Navy blue for university name
    doc.setFont("helvetica", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
      align: "center",
    });
    doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });

    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100); // Gray for secondary text
    doc.setFont("helvetica", "normal");
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      28,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
      105,
      33,
      { align: "center" }
    );
    doc.text(`Academic Session ${Session || "2025-2026"} `, 105, 38, {
      align: "center",
    });

    // Receipt title
    let title = "";
    let dataSource = [];
    if (type === "epravesh") {
      title = "EPravesh Fee Receipt";
      dataSource = approvedStudents.epravesh;
    } else if (type === "provisional") {
      title = "Provisional Fee Receipt";
      dataSource = approvedStudents.provisional;
    } else if (type === "enrollment") {
      title = "Enrollment Fee Receipt";
      dataSource = approvedStudents.enrollment;
    }
    doc.setFillColor(0, 48, 135);
    doc.rect(0, 45, 210, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(title, 105, 51, { align: "center" });

    // Rest of your code remains the same...
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Receipt Date: ${dataSource[0].txnDate}`, 15, 65);
    doc.text(`Receipt Time: ${receiptTime}`, 150, 65);

    // Main content
    const startY = 80;
    const lineHeight = 7;
    let currentY = startY;

    // Helper function for drawing rows
    const drawRow = (label, value) => {
      doc.setTextColor(100, 100, 100);
      doc.text(label, 25, currentY);
      doc.setTextColor(0, 0, 0);
      doc.text(": ", 70, currentY);
      doc.text(value, 75, currentY);
      currentY += lineHeight;
    };

    // Student details section
    doc.setFillColor(240, 240, 240);
    doc.rect(15, 70, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DETAILS", 25, 75);
    currentY = 85;

    doc.setFont("helvetica", "normal");
    drawRow("Name", `${dataSource[0].name}`);
    drawRow("Father's Name", `${dataSource[0].fathersname}`);
    drawRow("Email", `${dataSource[0].email}`);
    drawRow("Mobile", `${dataSource[0].mobile}`);
    drawRow("Course Type", `${dataSource[0].courseType}` || "N/A");
    drawRow("Course Name", `${dataSource[0].courseName}`);
    drawRow("Course Branch", `${dataSource[0].courseBranch}`);

    // Payment details section
    currentY += 5;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, currentY - 5, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT DETAILS", 25, currentY);
    currentY += 10;

    doc.setFont("helvetica", "normal");
    drawRow("Transaction ID", `${dataSource[0].txnId}`);
    drawRow("Order ID", `${dataSource[0].orderId || "Not Available"} `);
    drawRow("Payment Mode", `${dataSource[0].paymentMode}`);
    drawRow("Bank Transaction ID", ` ${dataSource[0].bankTxnId}`);

    // Amount section with box
    currentY += 5;
    doc.setFillColor(0, 48, 135);
    doc.rect(15, currentY - 5, 180, 12, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text("Amount Paid:", 25, currentY + 3);
    doc.text(`${dataSource[0].txnAmount}/-`, 160, currentY + 3);

    // Footer
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");

    // Save the PDF
    doc.save(`${dataSource[0].name}_Enrollment_Fee_Receipt.pdf`);
  };

  return (
    <>
      <StudentDashboard />

      <style>
        {`
          * {
            font-family: 'Poppins', sans-serif;
          }
          .radd {
            margin-left: 120px;
            margin-top: 80px;
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 1000;
            padding-bottom: 20px;
          }
          .form-input {
            padding: 5px;
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            border-color: #0d47a1;
          }
          .smallh {
            font-size: 13px;
            color: #16325B;
            margin-top: -20px;
          }
          .shadow {
            margin-top: 10px;
            margin-left: 8%;
          }
          .data-not-found {
            text-align: center;
            margin-top: 20px;
            color: #ff0000;
            font-size: 18px;
          }
          @media screen and (max-width: 480px) {
            .radd {
              margin-left: 20px;
              margin-top: 80px;
            }
            .shadow {
              margin-top: -60px;
              margin-left: 0%;
            }
            .h4d {
              font-weight: 700;
              height: 60px;
              border-radius: 10px;
              color: black;
              font-size: 18px;
              padding: 5px;
              margin-top: 70px;
            }
            .form-group-custom {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        `}
      </style>

      <div className="radd">
        <h4 style={{ fontFamily: "'Poppins',Sans-serif" }}>
          Select Fee Receipt Type :
        </h4>
        <div>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="epravesh"
              checked={selectedOption === "epravesh"}
              onChange={handleOptionChange}
            />
            Epravesh Fee Receipt
          </label>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="provisional"
              checked={selectedOption === "provisional"}
              onChange={handleOptionChange}
            />
            Provisional Fee Receipt
          </label>
          <label
            style={{
              marginRight: "20px",
              fontFamily: "'Poppins',Sans-serif",
            }}
          >
            <input
              type="radio"
              value="enrollment"
              checked={selectedOption === "enrollment"}
              onChange={handleOptionChange}
            />
            Enrollment Fee Receipt
          </label>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "200px", marginTop: "40px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <div className="data-not-found">{error}</div>
      ) : !selectedOption ? (
        <div className="data-not-found">Please select a receipt type</div>
      ) : (
        <>
          {/* E-Pravesh Receipt */}
          {selectedOption === "epravesh" &&
            (approvedStudents.epravesh &&
            approvedStudents.epravesh.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  E-Pravesh Registration Receipt
                </h4>
                {/* <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6> */}
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                    padding: "1px",
                  }}
                >
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="courseType"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseName"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseBranch"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].courseBranch || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="name"
                      className="form-group-custom"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="fathersname"
                      className="form-group-custom"
                    >
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].fathersname || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="email"
                      className="form-group-custom"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.epravesh[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="mobile"
                      className="form-group-custom"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].paymentMode || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="orderId"
                      className="form-group-custom"
                    >
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.epravesh[0].orderId ||
                          "Not Available"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="bankTxnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnAmount"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnAmount || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="txnDate"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.epravesh[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="status"
                      className="form-group-custom"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.epravesh[0].status || "TXN_SUCCESS"
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("epravesh")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow =
                        "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Epravesh Data Not Found</div>
            ))}

          {/* Provisional Receipt */}
          {selectedOption === "provisional" &&
            (approvedStudents.provisional &&
            approvedStudents.provisional.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  Provisional Fee Receipt
                </h4>
                {/* <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6> */}
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                    padding: "1px",
                  }}
                >
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="courseType"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseName"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseBranch"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].courseBranch || ""
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="name"
                      className="form-group-custom"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="fathersname"
                      className="form-group-custom"
                    >
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].fathersname || ""
                        }
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="email"
                      className="form-group-custom"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.provisional[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="mobile"
                      className="form-group-custom"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].paymentMode || ""
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="orderId"
                      className="form-group-custom"
                    >
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].orderId ||
                          "Not Available"
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="bankTxnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnAmount"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].txnAmount ||
                          approvedStudents.provisional[0].Amount
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="txnDate"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.provisional[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="status"
                      className="form-group-custom"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.provisional[0].status ||
                          "TXN_SUCCESS"
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("provisional")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow =
                        "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Provisional Data Not Found</div>
            ))}

          {/* Enrollment Receipt */}
          {selectedOption === "enrollment" &&
            (isEnrolledPaid &&
            approvedStudents.enrollment &&
            approvedStudents.enrollment.length > 0 ? (
              <CardContent className="shadow p-3 bg-body rounded">
                <h4
                  className="h4d"
                  style={{
                    color: "#0D47A1",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  Enrollment Fee Payment Receipt
                </h4>
                {/* <h6 className="smallh">
                  Check the below details to download your receipt.
                </h6> */}
                <Form
                  style={{
                    marginTop: "1%",
                    fontFamily: "'Poppins',Sans-serif",
                  }}
                >
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="courseType"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Type</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].courseType || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseName"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].courseName || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="courseBranch"
                      className="form-group-custom"
                    >
                      <Form.Label>Course Branch</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.enrollment[0].courseBranch || ""
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="name"
                      className="form-group-custom"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].name || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="fathersname"
                      className="form-group-custom"
                    >
                      <Form.Label>Father's Name</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].fathersname || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="randomId">
                      <Form.Label>Student ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].randomId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="email"
                      className="form-group-custom"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="email"
                        value={approvedStudents.enrollment[0].email || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="mobile"
                      className="form-group-custom"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].mobile || ""}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="paymentMode">
                      <Form.Label>Payment Mode</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].paymentMode || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnId || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="orderId"
                      className="form-group-custom"
                    >
                      <Form.Label>Order ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].orderId || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="bankTxnId"
                      className="form-group-custom"
                    >
                      <Form.Label>Bank Transaction ID</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].bankTxnId || ""}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      controlId="txnAmount"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Amount</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnAmount || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="txnDate"
                      className="form-group-custom"
                    >
                      <Form.Label>Transaction Date</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={approvedStudents.enrollment[0].txnDate || ""}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="status"
                      className="form-group-custom"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        className="form-input"
                        type="text"
                        value={
                          approvedStudents.enrollment[0].status || "TXN_SUCCESS"
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "10px",
                      background: "none",
                      border: "2px solid #0d47a1",
                      color: "#0d47a1",
                      padding: "6px 12px",
                      borderRadius: "10px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "0.9rem",
                      transition: "all 0.3s ease",
                    }}
                    onClick={() => generatePDF("enrollment")}
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#0d47a1";
                      e.target.style.color = "#fff";
                      e.target.style.boxShadow =
                        "0 0 15px rgba(0, 121, 107, 0.5)";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "transparent";
                      e.target.style.color = "#0d47a1";
                      e.target.style.boxShadow = "none";
                    }}
                  >
                    Download Receipt
                  </Button>
                </Form>
              </CardContent>
            ) : (
              <div className="data-not-found">Enrollment Data Not Found</div>
            ))}
        </>
      )}
    </>
  );
};

export default EStudent_Slip;

import { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../AdminComponent/12.png";
import "react-datepicker/dist/react-datepicker.css";
import {
  Box,
  Container,
  Paper,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
  Card,
  CardContent,
} from "@mui/material";

import swal from "sweetalert";
import { styled } from "@mui/material/styles";
import axios from "axios";
import OnlyHeader from "../../AdminComponent/OnlyHeader";

// Styled Components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  background: "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
  border: "1px solid #e0e0e0",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: "#1976d2",
  fontWeight: 700,
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: -8,
    left: 0,
    width: 60,
    height: 3,
    backgroundColor: "#1976d2",
    borderRadius: 2,
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: "#fff",
    transition: "all 0.3s ease",
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      boxShadow: "0 0 8px rgba(25, 118, 210, 0.2)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#666",
    fontWeight: 500,
  },
  "& .Mui-error": {
    color: "#d32f2f",
    fontSize: "0.8rem",
  },
}));

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: "#fff",
    transition: "all 0.3s ease",
    "&:hover fieldset": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.primary.main,
      boxShadow: "0 0 8px rgba(25, 118, 210, 0.2)",
    },
  },
  "& .MuiInputLabel-root": {
    color: "#666",
    fontWeight: 500,
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: theme.spacing(1.5, 6),
  fontWeight: 600,
  textTransform: "none",
  background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
  boxShadow: "0 4px 15px rgba(25, 118, 210, 0.3)",
  transition: "all 0.3s ease",
  "&:hover": {
    background: "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
    boxShadow: "0 6px 20px rgba(25, 118, 210, 0.4)",
    transform: "translateY(-2px)",
  },
}));

const E_RegistrationForm = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    fathersname: "",
    mothersname: "",
    gender: "",
    mobile: "",
    category: "",
    dob: "",
    admissionSession: "",
    qualification: "",
    passingYear: null,
    nationality: "",
    domicile: "",
    lastPassedSubject: "",
    ObtainedMarks: "",
    totalMarks: "",
    cgpa: "",
    board: "",
  });
  
  const [percentage, setPercentage] = useState("");
  const [selectedQualification, setSelectedQualification] = useState("");
  const [setUploading, IsSetUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [sessions, setSessions] = useState([]);

  const location = useLocation();
  const { courseType, courseName, courseBranch, email } = location.state || {};
  const navigate = useNavigate();

  const boardOptions = [
    "CBSE",
    "ICSE",
    "State Board - UP",
    "State Board - MP",
    "State Board - Bihar",
    "State Board - Delhi",
    "State Board - Maharashtra",
    "State Board - Karnataka",
    "State Board - Tamil Nadu",
    "State Board - Gujarat",
    "State Board - Rajasthan",
    "State Board - West Bengal",
    "Other",
  ];

  const lastPassed = {
    UG: ["12th"],
    PG: ["UG"],
    Diploma: ["10th", "12th"],
    PG_Diploma: ["Graduation"],
  };

  const specificQualifications = {
    UG: {
      "BACHELOR OF ENGINEERING(Lateral)": ["Diploma", "BSC"],
      "BACHELOR OF LAWS": ["UG"],
      "BACHELOR OF EDUCATION": ["UG", "PG"],
      "BACHELOR OF PHARMACY(lateral)": ["Diploma"],
      "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": ["12th", "UG"],
      "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": ["12th", "UG"],
      "BACHELOR OF ARCHITECTURE": ["12th", "Diploma"],
      "BACHELOR OF LIBRARY SCIENCE": ["12th", "UG"],
    },
    PG: {
      "MASTER OF TECHNOLOGY": ["UG", "PG"],
    },
    Diploma: {
      "DIPLOMA ENGINEERING  (Lateral)": ["12th", "ITI"],
    },
  };

  const courseToSubjects = {
    "BACHELOR OF PHYSIOTHERAPY": ["PCB", "All Subject"],
    "BACHELOR OF ENGINEERING": ["PCM"],
    "BACHELOR OF DESIGN": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF PHARMACY": ["PCM", "PCB"],
    "BACHELOR OF EDUCATION": ["All Subject"],
    "BSC(Nursing)": ["PCB"],
    "BACHELOR OF HOTEL MANAGEMENT AND CATERING TECHNOLOGY": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "Physical Education",
      "All Subject",
    ],
    "BACHELOR OF ARTS BACHELOR OF EDUCATION (B. A. B. Ed.)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF ARCHITECTURE": ["PCM", "All Subject"],
    "BACHELOR OF SCIENCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF BUSINESS ADMINISTRATION": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF ARTS": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMMERCE": ["PCM", "PCB", "Arts", "Agriculture", "Commerce"],
    "BACHELOR OF COMPUTER APPLICATION": ["PCM", "PCB", "Commerce"],
    "BACHELOR OF ENGINEERING(Lateral)": [
      "AERONAUTICAL ENGINEERING",
      "CHEMICAL ENGINEERING",
      "CIVIL ENGINEERING",
      "COMPUTER SCIENCE AND ENGINEERING",
      "ELECTRICAL AND ELECTRONICS ENGINEERING",
      "ELECTRICAL ENGINEERING",
      "ELECTRONICS AND COMMUNICATION ENGINEERING",
      "ELECTRONICS AND INSTRUMENTATION ENGINEERING",
      "MECHANICAL ENGINEERING",
      "MINING ENGINEERING",
      "INFORMATION TECHONOLOGY",
      "Other Subject",
    ],
    "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY": ["PCB"],
    "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY": ["PCB"],
    "BACHELOR OF LAWS": ["All Subjects"],
    "BACHELOR OF LIBRARY SCIENCE": [
      "PCM",
      "PCB",
      "Arts",
      "BCA",
      "Agriculture",
      "Commerce",
    ],
    "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "BACHELOR OF PHARMACY(lateral)": [
      "PCM",
      "PCB",
      "Arts",
      "Agriculture",
      "Commerce",
      "All Subject",
    ],
    "B.SC(Hons) AGRICULTURE": ["PCM", "PCB", "AGRICULTURE"],
    "BACHELOR OF PHSIOTHERAPY": ["PCB"],
    "MASTER OF TECHNOLOGY": ["BE", "B.TECH"],
    "MASTER OF PHARMACY": ["B.PHARMA"],
    "MASTER OF PHYSIOTHERAPHY (ORTHOPAEDIC)": ["B.PHARMA"],
    "MASTER OF MEDICAL LAB TECHNOLOGY(MMLT)": ["BMLT"],
    "MASTER OF COMPUTER APPLICATION": [
      "BSC (if 12th Passed with Maths)",
      "B.COM (if 12th Passed with Maths)",
      "BCA",
    ],
    "MD(HOMEOPATHY)": ["BHMS"],
    "MASTER OF BUSINESS ADMINISTRATION(MBA)": ["All Subject"],
    "MASTER OF SCIENCE": [
      "BE",
      "BSC",
      "BSC(Hons)",
      "BSC(Hons)Maths",
      "BSC(Hons)CS",
      "BSC(Hons)IT",
      "BSC(Hons)PHYSICS",
      "BSC(Hons)CHEMISTRY",
      "BSC(Hons)BIOLOGY",
    ],
    "MASTER OF ARTS": ["UG"],
    "MASTER OF COMMERCE": ["BCOM"],
    "MASTER OF PHYSIOTHERAPY": ["BPT"],
    "DIPLOMA PHARMACY": ["All Subject"],
    "DIPLOMA BLOOD TRANSFUSION": ["PCB"],
    "DIPLOMA DIALYSIS TECHNICIAN": ["PCB"],
    "DIPLOMA PHARMACY (AYURVED)": ["PCB"],
    "DIPLOMA HUMAN NUTRITION": ["PCB"],
    "DIPLOMA MEDICAL LAB AND TECHNICIAN": ["PCB", "MLT"],
    "DIPLOMA X-RAY RADIOGRAPHER TECHNICIAN": ["PCB"],
    "DIPLOMA YOGA": ["PCB"],
    "DIPLOMA ENGINEERING": ["10th"],
    "DIPLOMA PHARMACY (HOMEOPATHY)": ["PCB"],
    "DIPLOMA PARAMEDICAL OPTHALMIC ASSISTENT": ["PCB"],
    "DIPLOMA ENGINEERING  (Lateral)": ["PCM"],
    "POST GRADUATION DIPLOMA IN COMPUTER APPLICATION": ["Any Graduate"],
  };

  const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];

  function getLastPassedOptions(courseType, courseName) {
    if (
      specificQualifications[courseType] &&
      specificQualifications[courseType][courseName]
    ) {
      return specificQualifications[courseType][courseName];
    }
    return lastPassed[courseType] || [];
  }

  useEffect(() => {
    if (email) {
      setFormData((prev) => ({ ...prev, email }));
    }
  }, [email]);

  const validateForm = (formData) => {
    const errors = {};
    const currentYear = new Date().getFullYear();

    // Name validations
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      errors.name = "Name should only contain letters";
    }

    if (!formData.fathersname.trim()) {
      errors.fathersname = "Father's name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.fathersname)) {
      errors.fathersname = "Father's name should only contain letters";
    }

    if (!formData.mothersname.trim()) {
      errors.mothersname = "Mother's name is required";
    } else if (!/^[A-Za-z\s]+$/.test(formData.mothersname)) {
      errors.mothersname = "Mother's name should only contain letters";
    }

    // Mobile validation
    if (!formData.mobile) {
      errors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must be exactly 10 digits";
    }

    // Date of Birth validation
    if (!formData.dob) {
      errors.dob = "Date of birth is required";
    } else {
      // Convert dd-mm-yyyy to yyyy-mm-dd for Date object
      const [day, month, year] = formData.dob.split("-");
      const dobDate = new Date(`${year}-${month}-${day}`);
      const today = new Date();
      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }

      if (age < 17) {
        errors.dob = "Student must be at least 17 years old";
      }
    }

    // Passing Year validation
    if (!formData.passingYear) {
      errors.passingYear = "Passing year is required";
    } else if (parseInt(formData.passingYear) > currentYear) {
      errors.passingYear = `Passing year cannot be greater than ${currentYear}`;
    }

    // CGPA validation
    if (formData.cgpa) {
      const cgpaValue = parseFloat(formData.cgpa);
      if (cgpaValue > 10) {
        errors.cgpa = "CGPA cannot be greater than 10";
      }
    }

    // Marks validation
    if (formData.ObtainedMarks && formData.totalMarks) {
      const obtainedMarks = parseFloat(formData.ObtainedMarks);
      const totalMarks = parseFloat(formData.totalMarks);

      if (obtainedMarks > totalMarks) {
        errors.ObtainedMarks =
          "Obtained marks cannot be greater than total marks";
      }
    }

    // File upload validation
    if (selectedQualification) {
      if (selectedQualification === "12th" && !formData.marksheet12th) {
        errors.marksheet12th = `${selectedQualification} Marksheet is required`;
      } else if (selectedQualification === "10th" && !formData.marksheet10th) {
        errors.marksheet10th = `${selectedQualification} Marksheet is required`;
      } else if (
        selectedQualification === "Diploma" &&
        !formData.diplomaMarksheet
      ) {
        errors.diplomaMarksheet = `${selectedQualification} Marksheet is required`;
      } else if (selectedQualification === "ITI" && !formData.marksheet12th) {
        errors.marksheet12th = `${selectedQualification} Marksheet is required`;
      } else if (
        ["UG", "BSC", "Graduation"].includes(selectedQualification) &&
        !formData.undergraduateCertificate
      ) {
        errors.undergraduateCertificate = `${selectedQualification} Certificate is required`;
      } else if (
        selectedQualification === "PG" &&
        !formData.postgraduateCertificate
      ) {
        errors.postgraduateCertificate = `${selectedQualification} Certificate is required`;
      }
    }

    // Required field validations
    if (!formData.gender) errors.gender = "Gender is required";
    if (!formData.category) errors.category = "Category is required";
    if (!formData.nationality) errors.nationality = "Nationality is required";
    if (!formData.domicile) errors.domicile = "Domicile is required";
    if (!formData.qualification)
      errors.qualification = "Qualification is required";
    if (!formData.admissionSession)
      errors.admissionSession = "Admission session is required";

    return errors;
  };

  const handleDateChange = (e) => {
    const value = e.target.value; // This comes as yyyy-mm-dd from date input
    if (value) {
      // Convert yyyy-mm-dd to dd-mm-yyyy for backend
      const [year, month, day] = value.split("-");
      const formattedDate = `${day}-${month}-${year}`;

      // Age validation
      const dobDate = new Date(value);
      const today = new Date();
      let age = today.getFullYear() - dobDate.getFullYear();
      const monthDiff = today.getMonth() - dobDate.getMonth();

      if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < dobDate.getDate())
      ) {
        age--;
      }

      setErrors((prev) => ({
        ...prev,
        dob: age < 17 ? "Student must be at least 17 years old" : undefined,
      }));

      setFormData((prev) => ({ ...prev, dob: formattedDate }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Clear the specific error when the field is being edited
    setErrors((prev) => ({
      ...prev,
      [name]: undefined,
    }));

    // Special validations during input
    switch (name) {
      case "mobile":
        if (value.length > 10) return; // Prevent more than 10 digits
        if (!/^\d*$/.test(value)) return; // Only allow digits
        break;

      case "ObtainedMarks":
        if (parseFloat(value) > parseFloat(formData.totalMarks)) {
          setErrors((prev) => ({
            ...prev,
            ObtainedMarks: "Obtained marks cannot be greater than total marks",
          }));
        }
        break;

      case "cgpa":
        if (parseFloat(value) > 10) {
          setErrors((prev) => ({
            ...prev,
            cgpa: "CGPA cannot be greater than 10",
          }));
          return;
        }
        break;

      case "passingYear":
        const currentYear = new Date().getFullYear();
        if (parseInt(value) > currentYear) {
          setErrors((prev) => ({
            ...prev,
            passingYear: `Passing year cannot be greater than ${currentYear}`,
          }));
        }
        break;

      case "qualification":
        setSelectedQualification(value);
        // Reset related fields when qualification changes
        setFormData((prev) => ({
          ...prev,
          qualification: value,
          board: "",
          cgpa: "",
          ObtainedMarks: "",
          totalMarks: "",
          lastPassedSubject: "",
        }));
        setPercentage("");
        return;
    }

    setFormData((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));

    if (name === "ObtainedMarks" || name === "totalMarks") {
      calculatePercentage(
        name === "ObtainedMarks" ? value : formData.ObtainedMarks,
        name === "totalMarks" ? value : formData.totalMarks
      );
    }
  };

  const calculatePercentage = (ObtainedMarks, totalMarks) => {
    if (!isNaN(ObtainedMarks) && !isNaN(totalMarks) && totalMarks !== "0") {
      const percentageValue = (
        (parseFloat(ObtainedMarks) / parseFloat(totalMarks)) *
        100
      ).toFixed(2);
      setPercentage(percentageValue);
    } else {
      setPercentage("");
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file) return;
    if (!allowedTypes.includes(file.type)) {
      swal({
        icon: "error",
        title: "Error",
        text: "Uploaded file is not a valid image. Only JPG, PNG and JPEG files are allowed",
      });
      return;
    }

    const uploadData = new FormData();
    uploadData.append("file", file);
    uploadData.append("upload_preset", "upload");

    try {
      IsSetUploading(true);
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: uploadData,
        }
      );
      const data = await response.json();
      const fileUrl = data.secure_url;

      setFormData((prev) => ({
        ...prev,
        ...(selectedQualification === "12th" && { marksheet12th: fileUrl }),
        ...(selectedQualification === "10th" && { marksheet10th: fileUrl }),
        ...(selectedQualification === "Diploma" && {
          diplomaMarksheet: fileUrl,
        }),
        ...(selectedQualification === "ITI" && { marksheet12th: fileUrl }),
        ...(["UG", "BSC", "Graduation"].includes(selectedQualification) && {
          undergraduateCertificate: fileUrl,
        }),
        ...(selectedQualification === "PG" && {
          postgraduateCertificate: fileUrl,
        }),
      }));
      setErrors((prev) => ({
        ...prev,
        marksheet12th: undefined,
        marksheet10th: undefined,
        diplomaMarksheet: undefined,
        undergraduateCertificate: undefined,
        postgraduateCertificate: undefined,
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
      swal({
        icon: "error",
        title: "Error",
        text: "Failed to upload file. Please try again.",
      });
    } finally {
      IsSetUploading(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      swal("Error", "Please fix all errors before submitting", "error");
      return;
    }

    const submissionData = {
      ...formData,
      name: formData.name.toUpperCase(),
      fathersname: formData.fathersname.toUpperCase(),
      mothersname: formData.mothersname.toUpperCase(),
      nationality: formData.nationality.toUpperCase(),
      courseType,
      courseName,
      courseBranch,
      LastPercentage: percentage,
    };

    try {
      setLoading(true);
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v2/e-pravesh/register",
        submissionData
      );

      if (response.data.status === 200) {
        const { name, randomId } = response.data.User;
        swal({
          title: `Congratulations ${name}`,
          content: {
            element: "p",
            attributes: {
              innerHTML: `Your Registration Number is <strong>${randomId}</strong> and Password has been sent on your email.`,
              style: "color: #05668d; font-size: 18px;",
            },
          },
          icon: "success",
          buttons: "OK",
        }).then(() => {
          generatePDF();
          navigate("/student/epravesh/waiting", {
            state: {
              courseType,
              courseName,
              courseBranch,
              id: response.data.User._id,
              name: response.data.User.name,
            },
            replace: true,
          });
        });
      }
    } catch (error) {
      swal("Error", "Try Again!!!", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetch("https://sssutms.ac.in/apitest/v2/admission-sessions")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched sessions data:", data);
        setSessions(Array.isArray(data.data) ? data.data : []); // Access data.data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sessions:", error);
        setSessions([]); // Fallback to empty array
        setLoading(false);
      });
  }, []);


  
  const generatePDF = async () => {
    const pdf = new jsPDF();

    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      115,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 115, 22, {
      align: "center",
    });
    pdf.text("Sehore (M.P.) Pin Code-466001", 115, 28, { align: "center" });

    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 30, 80, 10, "F"); // Box
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Registration Form", 105, 36, { align: "center" });

    let y = 50;
    const addField = (label, value) => {
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "normal");
      pdf.text(`${label} :`, 20, y);

      const fieldValue = value
        ? String(value)
        : "_______________________________";
      pdf.text(fieldValue, 80, y - 2); // Value just above the line
      pdf.line(80, y, 200, y); // Underline
      y += 7;
    };

    const formFields = [
      ["Admission Year", formData?.admissionSession || ""],
      ["Course for which admitted", courseName || ""],
      ["Specialization", courseBranch || ""],
      ["Name of Student", formData?.name || ""],
      ["Father's Name", formData?.fathersname || ""],
      ["Mother's Name", formData?.mothersname || ""],
      ["Gender", formData?.gender || ""],
      ["Date of Birth", formData?.dob || ""],
      ["Email", formData?.email || ""],
      ["Mobile Number", formData?.mobile || ""],
      ["Domicile", formData?.domicile || ""],
      ["Category", formData?.category || ""],
      ["Nationality", formData?.nationality || ""],
    ];

    if (courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)") {
      formFields.push(
        ["Neet Qualified Percentage", `${formData?.NeetPercentage}%` || ""],
        ["Admission Category", formData?.Concession || ""]
      );
    }

    formFields.forEach(([label, value]) => addField(label, value));

    pdf.setFont("helvetica", "bold");
    pdf.text("Qualifying Exam Details:---", 20, y + 5);
    y += 8;

    const tableData = [
      [
        "1",
        `${formData?.qualification}`,
        `${formData?.QualifiedCourse || formData?.lastPassedSubject}`,
        `${formData?.passingYear}`,
        // `${formData?.lastExamType}`,
        // `${formData?.board}`,
        `${
          formData?.qualification === "UG" || formData?.qualification === "PG"
            ? "University Level"
            : formData?.board || ""
        }`,
        `${formData?.ObtainedMarks ? formData?.ObtainedMarks : "CGPA"}`,
        `${
          formData?.ObtainedMarks && percentage
            ? `${percentage}%`
            : formData?.cgpa || ""
        }`,
      ],
    ];

    pdf.autoTable({
      head: [
        [
          "S.No.",
          "Name of Exam",
          "Qualified Subject Group",
          "Passing Year",
          // "Exam Type",
          "Exam Board",
          "Obtain Marks",
          "Percentage/CGPA",
        ],
      ],
      body: tableData,
      startY: y,
      theme: "grid",
      styles: { fontSize: 8 },
      margin: { left: 20 },
    });

    y = pdf.lastAutoTable.finalY + 5;

    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Declaration by Student/Parent/Guardian", 20, y);
    pdf.setFont("helvetica", "normal");
    y += 6;
    const declaration = [
      "Self/on behalf of my ward hereby declare that:",
      "1. The information given by me in the application form and all enclosures are true to the best of my knowledge. However, should it, be found that any information/enclosures therein are untrue/wrong I am/my ward liable to be disqualified for admission",
      "2. If I am/my ward selected for admission I am/my promise to abide by the rules & regulations of the Institute/University and maintain the discipline in the institute and the hostel.",
      "3. Initially the admission is provisional and is subject to confirmation from the counseling authority concerned University and State Government.",
      "4. It is compulsory for me/my ward to appear for online counseling at any place directed by the counseling authority with in the specified date and time failing which I/my ward’s registration will be automatically cancelled without any refund of fee.",
      "5. I understand that if I get my admission/registration cancelled the fee deposited by me is nonrefundable.",
      "6. Cancellation of admission/registration is not possible without paying the full fees for the entire course.",
      "7. I undertake to abide by the “Student Leaving Midstream” clause of the prospectus and agree to pay fees for the whole course if I leave course in midstream.",
      "8. Any dispute is subject to Sehore Jurisdiction.",
    ];

    declaration.forEach((line, index) => {
      const wrappedText = pdf.splitTextToSize(line, 170);
      pdf.text(wrappedText, 19, y);
      y += wrappedText.length * 5;
    });

    y += 6;
    pdf.text("Date : __________________________", 20, y);
    pdf.text(
      "Signature of Student :            __________________________",
      100,
      y
    );

    y += 6;
    pdf.text("Place : __________________________", 20, y);
    pdf.text(
      "Name of Declarer :                 __________________________",
      100,
      y
    );
    y += 6;
    pdf.text(
      "Relationship with Candidate :  __________________________",
      100,
      y
    );

    pdf.save(`${formData?.name} Registration_Form.pdf`);
  };

  return (
    <>
      <OnlyHeader />
      <StyledContainer maxWidth="lg" sx={{ p: 5 }}>
        <StyledCard>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{ color: "#1976d2", fontWeight: 600 }}
              >
                Student Registration
              </Typography>
              <Box
                sx={{
                  bgcolor: "#e3f2fd",
                  p: 1,
                  borderRadius: 2,
                  display: "inline-block",
                }}
              >
                <Typography variant="subtitle2" color="primary">
                  {courseName} {courseBranch && `(${courseBranch})`}{" "}
                </Typography>
              </Box>
            </Box>

            <form onSubmit={onSubmit}>
              {/* Personal Information */}
              <SectionTitle variant="h6" gutterBottom sx={{ mb: 2 }}>
                Personal Information
              </SectionTitle>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Full Name"
                    name="name"
                    value={formData.name}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "name",
                          value: e.target.value.toUpperCase(),
                        },
                      })
                    }
                    required
                    autoComplete="off"
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Father's Name"
                    name="fathersname"
                    value={formData.fathersname}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "fathersname",
                          value: e.target.value.toUpperCase(),
                        },
                      })
                    }
                    required
                    autoComplete="off"
                    error={!!errors.fathersname}
                    helperText={errors.fathersname}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Mother's Name"
                    name="mothersname"
                    value={formData.mothersname}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: "mothersname",
                          value: e.target.value.toUpperCase(),
                        },
                      })
                    }
                    required
                    autoComplete="off"
                    error={!!errors.mothersname}
                    helperText={errors.mothersname}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.gender}
                  >
                    <InputLabel>Gender</InputLabel>
                    <Select
                      name="gender"
                      label="Gender *"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Gender</MenuItem>
                      <MenuItem value="MALE">Male</MenuItem>
                      <MenuItem value="FEMALE">Female</MenuItem>
                      <MenuItem value="OTHER">Other</MenuItem>
                    </Select>
                    {errors.gender && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.gender}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Mobile Number"
                    type="number"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                    error={!!errors.mobile}
                    helperText={errors.mobile}
                  />
                </Grid>
              </Grid>

              {/* Academic Information */}
              <SectionTitle variant="h6" gutterBottom sx={{ mb: 2 }}>
                Academic Information
              </SectionTitle>
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.admissionSession}
                  >
                    <InputLabel>Admission Session</InputLabel>
                    <Select
                      name="admissionSession"
                      label="Admission Session *"
                      value={formData.admissionSession}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Session</MenuItem>
                      {courseName ===
                        "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY" ||
                      courseName === "MD(HOMEOPATHY)" ? (
                        <MenuItem value="2024-2025">2024-2025</MenuItem>
                      ) : (
                        sessions.map((sess, index) => (
                          <MenuItem key={index} value={sess.sessionName}>
                            {sess.sessionName}
                          </MenuItem>
                        ))
                      )}
                    </Select>

                    {errors.admissionSession && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.admissionSession}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.category}
                  >
                    <InputLabel>Category</InputLabel>
                    <Select
                      name="category"
                      label="Category *"
                      value={formData.category}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Category</MenuItem>
                      <MenuItem value="GENERAL">General</MenuItem>
                      <MenuItem value="OBC">OBC</MenuItem>
                      <MenuItem value="SC">SC</MenuItem>
                      <MenuItem value="ST">ST</MenuItem>
                    </Select>
                    {errors.category && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.category}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Date of Birth"
                    type="date"
                    name="dob"
                    value={
                      formData.dob
                        ? formData.dob.split("-").reverse().join("-")
                        : ""
                    }
                    onChange={handleDateChange}
                    InputLabelProps={{ shrink: true }}
                    required
                    error={!!errors.dob}
                    helperText={errors.dob}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    label="Last Passing Year"
                    type="number"
                    name="passingYear"
                    value={formData.passingYear}
                    onChange={handleChange}
                    required
                    autoComplete="off"
                    error={!!errors.passingYear}
                    helperText={errors.passingYear}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.nationality}
                  >
                    <InputLabel>Nationality</InputLabel>
                    <Select
                      name="nationality"
                      label="National *"
                      value={formData.nationality}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Nationality</MenuItem>
                      <MenuItem value="INDIAN">Indian</MenuItem>
                    </Select>
                    {errors.nationality && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.nationality}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.domicile}
                  >
                    <InputLabel>Domicile</InputLabel>
                    <Select
                      name="domicile"
                      label="Domicile *"
                      value={formData.domicile}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Domicile</MenuItem>
                      <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
                      <MenuItem value="All India">All India</MenuItem>
                    </Select>
                    {errors.domicile && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.domicile}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomFormControl
                    fullWidth
                    required
                    autoComplete="off"
                    error={!!errors.qualification}
                  >
                    <InputLabel>Last Qualification</InputLabel>
                    <Select
                      name="qualification"
                      label="Qualification *"
                      value={formData.qualification}
                      onChange={handleChange}
                    >
                      <MenuItem value="">Select Qualification</MenuItem>
                      {getLastPassedOptions(courseType, courseName).map(
                        (subject) => (
                          <MenuItem key={subject} value={subject}>
                            {subject}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {errors.qualification && (
                      <Typography
                        color="error"
                        sx={{ fontSize: "0.8rem", mt: 0.5 }}
                      >
                        {errors.qualification}
                      </Typography>
                    )}
                  </CustomFormControl>
                </Grid>

                {selectedQualification === "ITI" && (
                  <>
                    <Grid item xs={12} md={4}>
                      <CustomFormControl
                        fullWidth
                        required
                        autoComplete="off"
                        error={!!errors.qualification}
                      >
                        <InputLabel>Last Subject</InputLabel>
                        <Select
                          name="lastPassedSubject"
                          label="Last Subject *"
                          value={formData.lastPassedSubject}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select Subject</MenuItem>
                          <MenuItem value="ITI">ITI</MenuItem>
                        </Select>
                        {errors.lastPassedSubject && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "0.8rem", mt: 0.5 }}
                          >
                            {errors.lastPassedSubject}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Obtained Marks"
                        type="number"
                        name="ObtainedMarks"
                        value={formData.ObtainedMarks}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        error={!!errors.ObtainedMarks}
                        helperText={errors.ObtainedMarks}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Total Marks"
                        name="totalMarks"
                        value={formData.totalMarks}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        error={!!errors.totalMarks}
                        helperText={errors.totalMarks}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Percentage"
                        name="percentage"
                        value={percentage}
                        disabled
                      />
                    </Grid>
                  </>
                )}

                {["BSC", "Diploma", "Graduation", "UG", "PG"].includes(
                  selectedQualification
                ) && (
                  <>
                    <Grid item xs={12} md={4}>
                      <CustomFormControl
                        fullWidth
                        required
                        autoComplete="off"
                        error={!!errors.qualification}
                      >
                        <InputLabel>Last Subject</InputLabel>
                        <Select
                          name="lastPassedSubject"
                          label="Last Subject *"
                          value={formData.lastPassedSubject}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select Subject</MenuItem>
                          {courseToSubjects[courseName]?.map((subject) => (
                            <MenuItem key={subject} value={subject}>
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.lastPassedSubject && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "0.8rem", mt: 0.5 }}
                          >
                            {errors.lastPassedSubject}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Obtained Marks"
                        type="number"
                        name="ObtainedMarks"
                        value={formData.ObtainedMarks}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        error={!!errors.ObtainedMarks}
                        helperText={errors.ObtainedMarks}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Total Marks"
                        name="totalMarks"
                        value={formData.totalMarks}
                        onChange={handleChange}
                        required
                        autoComplete="off"
                        error={!!errors.totalMarks}
                        helperText={errors.totalMarks}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomTextField
                        fullWidth
                        label="Percentage"
                        name="percentage"
                        value={percentage}
                        disabled
                      />
                    </Grid>
                  </>
                )}

                {(selectedQualification === "12th" ||
                  selectedQualification === "10th") && (
                  <>
                    <Grid item xs={12} md={4}>
                      <CustomFormControl
                        fullWidth
                        required
                        autoComplete="off"
                        error={!!errors.qualification}
                      >
                        <InputLabel>Last Subject</InputLabel>
                        <Select
                          name="lastPassedSubject"
                          label="Last Subject *"
                          value={formData.lastPassedSubject}
                          onChange={handleChange}
                        >
                          <MenuItem value="">Select Subject</MenuItem>
                          {courseToSubjects[courseName]?.map((subject) => (
                            <MenuItem key={subject} value={subject}>
                              {subject}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.lastPassedSubject && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "0.8rem", mt: 0.5 }}
                          >
                            {errors.lastPassedSubject}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <CustomFormControl
                        fullWidth
                        required
                        autoComplete="off"
                        error={!!errors.board}
                      >
                        <InputLabel>Board</InputLabel>
                        <Select
                          name="board"
                          value={formData.board}
                          onChange={handleChange}
                        >
                          {boardOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.board && (
                          <Typography
                            color="error"
                            sx={{ fontSize: "0.8rem", mt: 0.5 }}
                          >
                            {errors.board}
                          </Typography>
                        )}
                      </CustomFormControl>
                    </Grid>

                    {/* CGPA Field - Only for CBSE & ICSE */}
                    {["CBSE", "ICSE"].includes(formData.board) && (
                      <Grid item xs={12} md={4}>
                        <CustomTextField
                          fullWidth
                          label="CGPA"
                          name="cgpa"
                          value={formData.cgpa}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                          error={!!errors.cgpa}
                          helperText={errors.cgpa}
                        />
                      </Grid>
                    )}

                    {/* Marks Fields - Only for State Board */}
                    {formData.board.includes("State Board") && (
                      <>
                        <Grid item xs={12} md={4}>
                          <CustomTextField
                            fullWidth
                            label="Obtained Marks"
                            type="number"
                            name="ObtainedMarks"
                            value={formData.ObtainedMarks}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                            error={!!errors.ObtainedMarks}
                            helperText={errors.ObtainedMarks}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomTextField
                            fullWidth
                            label="Total Marks"
                            name="totalMarks"
                            value={formData.totalMarks}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                            error={!!errors.totalMarks}
                            helperText={errors.totalMarks}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <CustomTextField
                            fullWidth
                            label="Percentage"
                            name="percentage"
                            value={percentage}
                            disabled
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>

              {/* Additional Information */}
              <SectionTitle variant="h6" gutterBottom sx={{ mb: 2 }}>
                Additional Information
              </SectionTitle>
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                  <CustomTextField
                    fullWidth
                    type="file"
                    label={`${selectedQualification} Marksheet`}
                    onChange={handleFileChange}
                    InputLabelProps={{ shrink: true }}
                    required
                    error={
                      !!(
                        errors.marksheet12th ||
                        errors.marksheet10th ||
                        errors.diplomaMarksheet ||
                        errors.undergraduateCertificate ||
                        errors.postgraduateCertificate
                      )
                    }
                    helperText={
                      errors.marksheet12th ||
                      errors.marksheet10th ||
                      errors.diplomaMarksheet ||
                      errors.undergraduateCertificate ||
                      errors.postgraduateCertificate ||
                      "Please upload the required marksheet"
                    }
                  />
                  {setUploading && <LinearProgress sx={{ mt: 1 }} />}
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <SubmitButton
                  type="submit"
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit Registration"}
                </SubmitButton>
              </Box>
            </form>
          </CardContent>
        </StyledCard>
      </StyledContainer>
    </>
  );
};

export default E_RegistrationForm;

import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { TableCell, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import CardContent from "@mui/material/CardContent";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function TotalFeePaid() {
  const [studentdata, setStudentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          "https://sssutms.ac.in/apitest/admin/students/paidlist"
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setStudentData(data);
          setFilteredData(data);
        } else {
          console.error("Fetched data is not an array:", data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata]);

  const filterData = () => {
    if (!Array.isArray(studentdata)) {
      console.error("studentdata is not an array:", studentdata);
      setFilteredData([]);
      return;
    }
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const getSortedAndSlicedData = () => {
    if (!Array.isArray(filteredData)) {
      console.error("filteredData is not an array:", filteredData);
      return [];
    }
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });

    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const ExportToExcel = () => {
    if (Array.isArray(studentdata)) {
      const Excel = studentdata.map((student) => ({
        Admission_Session: student.admissionSession,
        Registration_Fee: student.isPaid,
        Enrollment_Fee: student.isEnrollPaid,
        Enrollment_Form: student.isEnrolled,
        Course_Type: student.courseType,
        Course_Name: student.courseName,
        Branch: student.courseBranch,
        Random_Id: student.randomId,
        Random_Password: student.randomPassword,
        Name: student.name,
        Fathers_Name: student.fathersname,
        Email: student.email,
        Mobile: student.mobile,
      }));

      const worksheet = XLSX.utils.json_to_sheet(Excel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Paid Student");
      XLSX.writeFile(workbook, "Paid_Stud_Data.xlsx");
    } else {
      console.error("Student data is not an array. Cannot export to Excel.");
    }
  };
  return (
    <>
      <AdminDashboard />
      <Box
        sx={{
          width: "auto",
          marginLeft: "5%",
          marginTop: "65px",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <CircularProgress
            color="success"
            style={{ marginTop: "80px", marginLeft: "50%" }}
          />
        ) : (
          <CardContent>
            <Button
              style={{ float: "right", marginRight: "10px" }}
              variant="outlined"
              onClick={ExportToExcel}
            >
              Export to Excel
            </Button>
            <Paper sx={{ width: "100%", overflow: "auto", height: "80%" }}>
              <Box sx={{ p: 2 }}>
                <SearchIcon sx={{ mr: 1 }} />
                <input
                  type="text"
                  placeholder="Search  by ID or Name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Box>
              <TableContainer sx={{ maxHeight: "400px" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          left: 0,
                          zIndex: 2,
                        }}
                      >
                        S.No.
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 2,
                          left: 50,
                          minWidth: "150px",
                        }}
                      >
                        Enrollment Number
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Admission Session
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Registration ID
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Password
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          // zIndex: 1,

                          minWidth: "200px",
                          position: "sticky",

                          left: "180px",
                          zIndex: 2,
                        }}
                        // /////////////for sorting name//////////////////
                        onClick={() => handleSort("name")}
                      >
                        Student Name
                        {/* /////////////name sort////////////////////////// */}
                        {sortBy === "name" && (
                          <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Father's Name
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Mobile No.
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Course Type
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                          minWidth: "250px",
                        }}
                      >
                        Course Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          minWidth: "250",
                          zIndex: 1,
                        }}
                      >
                        Course Branch
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "rgb(2, 48, 71)",
                          position: "sticky",
                          top: 0,
                          color: "white",
                          zIndex: 1,
                        }}
                      >
                        Fee Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedAndSlicedData().map((student, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: "white",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            position: "sticky",
                            left: 50,
                            top: 0,
                            zIndex: 1,
                            backgroundColor: "white",
                            color: "#e85d04",
                            fontWeight: "bold",
                          }}
                        >
                          {student?.enrollmentNumber ? (
                            <span
                              style={{
                                color: "green",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              {student?.enrollmentNumber}
                            </span>
                          ) : (
                            <span
                              style={{
                                color: "red",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                            >
                              Not generated
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {student?.admissionSession}
                        </TableCell>
                        <TableCell align="left" style={{ fontWeight: 700 }}>
                          {student?.randomId}
                        </TableCell>
                        <TableCell align="left">
                          {student?.randomPassword}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: "bold",
                            position: "sticky",
                            left: 180,
                            zIndex: 1,
                            fontSize: "15px",
                            top: 0,
                            minWidth: "200px",

                            backgroundColor: "white",
                          }}
                        >
                          {student?.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            left: 200,
                            zIndex: 1,
                            minWidth: "200px",
                          }}
                        >
                          {student?.fathersname}
                        </TableCell>
                        <TableCell align="left">{student?.email}</TableCell>
                        <TableCell align="left">{student?.mobile}</TableCell>
                        <TableCell
                          style={{
                            minWidth: "250px",
                            fontWeight: 700,
                            color:
                              student.courseType === "UG"
                                ? "blue"
                                : student.courseType === "PG"
                                ? "#8d0801"
                                : student.courseType === "Diploma"
                                ? "purple"
                                : student.courseType === "PG_Diploma"
                                ? "green"
                                : "black",
                          }}
                        >
                          {student?.courseType || "hello"}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ minWidth: "250px", fontWeight: 700 }}
                        >
                          {student?.courseName}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ minWidth: "250px", fontWeight: 700 }}
                        >
                          {student?.courseBranch}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            fontSize: "15px",
                            color: "green",
                            fontWeight: "bold",
                          }}
                        >
                          {student?.isPaid ? "Paid" : "Not Paid"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[50, 500, 1000]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </CardContent>
        )}

        <br></br>
      </Box>
    </>
  );
}

export default TotalFeePaid;

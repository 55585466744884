// import React from "react";
// import Nav from "../Nav";
// import Footerpage from "../Footerpage";

// const Contact = () => {
//   return (
//     <>
//       <style>
//         {`
//         .title h4::before{
//           background: orange;
//           height:2px;
//           width:45px;
//           content:"";
//           position:absolute;
//           top:0px;
//           left:0px;
//           bottom:0px;
//           margin:auto;
    
//          }

//          .title{
//          font-family:inherit;
//          font-weight:350;
//          margin-left:80px;
// }


//  .title h4{
//   text-transform:uppercase;
//   font-weight:500;
//   font-size:20px;
//   position:relative;
//   padding-left:60px;
//   margin-top:0px;
//   margin-bottom:15px;
//   display:inline-block;
//   color:rgb(53,56,88);
//   font-style:normal;
//  }

//         section {
//           padding: 10px 0;
//           min-height: 100vh;
//       }
// .contact-info {
// display: inline-block;
// width: 100%;
// text-align: center;
//     margin-bottom: 10px;
// }
// .contact-info-icon {
// margin-bottom: 15px;
// }
// .contact-info-item {
// background:  #023047;
// padding: 30px 0px;
// }
// .contact-page-sec .contact-page-form h2 {
// color: #071c34;
// text-transform: capitalize;
// font-size: 22px;
// font-weight: 700;
// }
// .contact-page-form .col-md-6.col-sm-6.col-xs-12 {
// padding-left: 0;
// }  
// .contact-page-form.contact-form input {
// margin-bottom: 5px;
// }  
// .contact-page-form.contact-form textarea {
// height: 110px;
// }
// .contact-page-form.contact-form input[type="submit"] {
// background: #071c34;
// width: 150px;
// border-color: #071c34;
// }
// .contact-info-icon i {
// font-size: 48px;
// color: #fda40b;
// }
// .contact-info-text p{margin-bottom:0px;}
// .contact-info-text h2 {
// color: #fff;
// font-size: 22px;
// text-transform: capitalize;
// font-weight: 600;
// margin-bottom: 10px;
// }
// .contact-info-text span {
// color: #999999;
// font-size: 16px;
// font-weight: ;
// display: inline-block;
// width: 100%;
// }

// .contact-page-form input {
// background: #f9f9f9 none repeat scroll 0 0;
// border: 1px solid #f9f9f9;
// margin-bottom: 20px;
// padding: 12px 16px;
// width: 100%;
// border-radius: 4px;
// }

// .contact-page-form .message-input {
// display: inline-block;
// width: 100%;
// padding-left: 0;
// }
// .single-input-field textarea {
// background: #f9f9f9 none repeat scroll 0 0;
// border: 1px solid #f9f9f9;
// width: 100%;
// height: 120px;
// padding: 12px 16px;
// border-radius: 4px;
// }
// .single-input-fieldsbtn input[type="submit"] {
// background: #fda40b none repeat scroll 0 0;
// color: #fff;
// display: inline-block;
// font-weight: 600;
// padding: 10px 0;
// text-transform: capitalize;
// width: 150px;
// margin-top: 20px;
// font-size: 16px;
// }
// .single-input-fieldsbtn input[type="submit"]:hover{background:#071c34;transition: all 0.4s ease-in-out 0s;border-color:#071c34}
// .single-input-field  h4 {
// color: #464646;
// text-transform: capitalize;
// font-size: 14px;
// }
// .contact-page-form {
// display: inline-block;
// width: 100%;
// margin-top: 30px;
// }

// .contact-page-map {
// margin-top: 36px;
// }
// .contact-page-form form {
//   padding: 20px 15px 0;
// }`}
//       </style>
//       <Nav />
//       <div
//         data-aos="fade-right"
//         className="title aos-init aos-animate"
//         style={{ marginTop: "40px", marginLeft: "120px" }}
//       >
//         <h4>Contact Us</h4>
//       </div>
//       <section className="contact-page-sec">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-4">
//               <div className="contact-info">
//                 <div className="contact-info-item">
//                   <div className="contact-info-icon">
//                     <i className="fas fa-map-marked" />
//                   </div>
//                   <div className="contact-info-text">
//                     <h2>Address</h2>

//                     <span>
//                       Opp.Oilfed Plant, Bhopal-Indore Road,Sehore(M.P),{" "}
//                       <br></br>
//                       Pin - 466001
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="contact-info">
//                 <div className="contact-info-item">
//                   <div className="contact-info-icon">
//                     <i className="fas fa-envelope" />
//                   </div>
//                   <div className="contact-info-text">
//                     <h2>E-mail</h2>
//                     <span> info@sssutms.co.in</span>
//                     <span>srisatyasaiuniversity2013@gmail.com</span>
//                     <span>Fax :- +91-07562-292203 | 07562-292204 | 07562-292205 | 07562-292740 | 07562-292720</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="contact-info">
//                 <div className="contact-info-item">
//                   <div className="contact-info-icon">
//                     <i className="fas fa-clock" />
//                   </div>
//                   <div className="contact-info-text">
//                     <h2>office time</h2>

//                     <span>Mon- Sat 10:00 pm - 5:00 pm</span>
//                     <span>
//                       Visit us :- www.sssutms.co.in,
//                       <br></br> www.sssutms.ac.in
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="row">
//             <div className="col-md-8">
//               <div className="contact-page-form" method="post">
//                 <h2>Get in Touch</h2>
//                 <form action="contact-mail.php" method="post">
//                   <div className="row">
//                     <div className="col-md-6 col-sm-6 col-xs-12">
//                       <div className="single-input-field">
//                         <input
//                           type="text"
//                           placeholder="Your Name"
//                           name="name"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-6 col-xs-12">
//                       <div className="single-input-field">
//                         <input
//                           type="email"
//                           placeholder="E-mail"
//                           name="email"
//                           required=""
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-6 col-xs-12">
//                       <div className="single-input-field">
//                         <input
//                           type="text"
//                           placeholder="Phone Number"
//                           name="phone"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-6 col-sm-6 col-xs-12">
//                       <div className="single-input-field">
//                         <input
//                           type="text"
//                           placeholder="Subject"
//                           name="subject"
//                         />
//                       </div>
//                     </div>
//                     <div className="col-md-12 message-input">
//                       <div className="single-input-field">
//                         <textarea
//                           placeholder="Write Your Message"
//                           name="message"
//                           defaultValue={""}
//                         />
//                       </div>
//                     </div>
//                     <div className="single-input-fieldsbtn">
//                       <input type="submit" defaultValue="Send Now" />
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div className="col-md-4">
//               <div className="contact-page-map">
//                 <iframe
//                   title="SSSUTMS Sehore Map"
//                   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.576984127963!2d77.12720060000001!3d23.215651799999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397cf4c949567e4d%3A0xc7649cfdfe73a024!2s%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80%20%E0%A4%B8%E0%A4%A4%E0%A5%8D%E0%A4%AF%20%E0%A4%B8%E0%A4%BE%E0%A4%82%E0%A4%88%20%E0%A4%AF%E0%A5%81%E0%A4%A8%E0%A4%BF%E0%A4%B5%E0%A4%B0%E0%A5%8D%E0%A4%B8%E0%A4%BF%E0%A4%9F%E0%A5%80%20%E0%A4%91%E0%A4%AB%E0%A4%BC%20%E0%A4%9F%E0%A5%87%E0%A4%95%E0%A5%8D%E0%A4%A8%E0%A5%8B%E0%A4%B2%E0%A5%89%E0%A4%9C%E0%A5%80%20%26%20%E0%A4%AE%E0%A5%87%E0%A4%A1%E0%A4%BF%E0%A4%95%E0%A4%B2%20%E0%A4%B8%E0%A4%BE%E0%A4%87%E0%A4%82%E0%A4%B8%2C%20%E0%A4%B8%E0%A5%80%E0%A4%B9%E0%A5%8B%E0%A4%B0%2C%20%E0%A4%AE%E0%A4%A6%E0%A5%8D%E0%A4%B9%E0%A5%8D%E0%A4%AF%20%E0%A4%AA%E0%A5%8D%E0%A4%B0%E0%A4%A6%E0%A5%87%E0%A4%B6!5e0!3m2!1sen!2sin!4v1642818613881!5m2!1sen!2sin"
//                   width="100%"
//                   height={450}
//                   frameBorder={0}
//                   style={{ border: 0 }}
//                   allowFullScreen=""
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Footerpage />
//     </>
//   );
// };

// export default Contact;


import React, { useState } from "react"
import { Mail, Phone, MapPin, Send } from "lucide-react"

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: ""
  })

  const handleSubmit = e => {
    e.preventDefault()
    // Handle form submission here
    console.log("Form submitted:", formData)
  }

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className="bg-blue-900 text-white py-12">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center">Contact Us</h1>
          <p className="text-center mt-4 text-blue-100">
            We're here to help and answer any questions you might have
          </p>
        </div>
      </div>

      <div className="container mx-auto px-4 py-12">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <div className="bg-white rounded-lg shadow-lg p-8">
            <h2 className="text-2xl font-semibold mb-6">Send us a Message</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2 border"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2 border"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2 border"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="subject"
                  className="block text-sm font-medium text-gray-700"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2 border"
                  required
                />
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2 border"
                  required
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-blue-900 text-white py-3 px-6 rounded-md hover:bg-blue-800 transition duration-300 flex items-center justify-center gap-2"
              >
                <Send size={20} />
                Send Message
              </button>
            </form>
          </div>

          {/* Contact Information */}
          <div className="space-y-8">
            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-semibold mb-6">
                Contact Information
              </h2>
              <div className="space-y-4">
                <div className="flex items-start gap-4">
                  <MapPin className="text-blue-900 mt-1" />
                  <div>
                    <h3 className="font-medium">Address</h3>
                    <p className="text-gray-600">
                      University Campus, Example Street
                    </p>
                    <p className="text-gray-600">City, State - PIN Code</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <Phone className="text-blue-900 mt-1" />
                  <div>
                    <h3 className="font-medium">Phone</h3>
                    <p className="text-gray-600">+91 1234567890</p>
                  </div>
                </div>
                <div className="flex items-start gap-4">
                  <Mail className="text-blue-900 mt-1" />
                  <div>
                    <h3 className="font-medium">Email</h3>
                    <p className="text-gray-600">contact@university.edu</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Map */}
            <div className="bg-white rounded-lg shadow-lg p-8">
              <h2 className="text-2xl font-semibold mb-6">Location Map</h2>
              <div className="w-full h-[300px] rounded-lg overflow-hidden">
                <iframe
                  src="https://www.openstreetmap.org/export/embed.html?bbox=75.7,26.8,75.9,27.0&layer=mapnik"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact

import React, { useEffect, useState } from "react";
import { TableCell, Box } from "@mui/material";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import SearchIcon from "@mui/icons-material/Search";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";

const Adminser = ({ session, courseType, courseName, branch }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [studentdata1, setStudentData] = useState({ students: [] });
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const moye = studentdata1.students.map((student) => ({
    EnrollmentNumber: student.enrollmentNumber,
    Name: student.name,
    Fathers_Name: student.fathersname,
    Email: student.email,
    Mobile: student.mobile,

    Registered: student?.isRegistered ? "YES" : "NO",
    Enrolled: student?.isEnrolled ? "Yes" : "No",

    FEE_PAID: student?.isPaid ? "Yes" : "No",
    Student_address: student.address
      ? Object.values(student.address || {}).some((value) => value === "")
        ? "Pending"
        : "Update"
      : "Pending",

    Academic_details:
      student.academicDetails &&
      Object.keys(student.academicDetails)
        .filter((key) =>
          [
            "tenthBoard",
            "tenthMarksObtain",
            "tenthMaxMarks",
            "tenthPassingYear",
            "tenthPercentage",
            "tenthRollNo",
            "tenthSchool",
            "twelfthBoard",
            "twelfthMarksObtain",
            "twelfthMaxMarks",
            "twelfthPassingYear",
            "twelfthPercentage",
            "twelfthRollNo",
            "twelfthSchool",
          ].includes(key)
        )
        .every((key) => student.academicDetails[key] !== "")
        ? "Update"
        : "Pending",

    Documents: student.Documents
      ? [
          "incomeCertificate",
          "otherCertificate",
          "postgraduateCertificate",
          "undergraduateCertificate",
          "migrationCertificate",
        ].every(
          (key) =>
            !student.Documents[key] || student.Documents[key].trim() === ""
        )
        ? "Pending"
        : "Updated"
      : "Pending",
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = "https://sssutms.ac.in/apitest/admin/statusofstudent";
        const requestData = {
          courseBranch: branch,
          admissionSession: session,
          courseName,
          courseType,
        };
        const response = await axios.post(url, requestData);

        const data = response.data;
        // console.log(data, "data")
        if (!data.students || data.students.length === 0) {
          setDataNotFound(true);
        } else {
          setDataNotFound(false);
          setStudentData(data);
          setFilteredData(data.students);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDataNotFound(true);
      }
    };
    fetchData();
  }, [session, courseType, courseName, branch]);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  /////////////////////datepicker/////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);

    const filteredResults = studentdata1.students.filter((student) => {
      return (
        student.randomId
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        student.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        student.branch.toLowerCase().includes(event.target.value.toLowerCase())
      );
    });
    setFilteredData(filteredResults);
  };
  ////////////////export to excel///////////////////////////
  const ExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(moye);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "enrolled");
    XLSX.writeFile(workbook, "STUDENTS_STATUS.xlsx");
  };

  // console.log(studentdata1, "helllo world")
  return (
    <>
      <AdminDashboard />
      <Box
        sx={{
          width: "90%",
          marginLeft: "100px",
          marginTop: "90px",
          overflow: "hidden",
        }}
      >
        {/* <CardContent> */}
        {/* <Paper sx={{ width: "100%", overflow: "auto" }}> */}
        <Button
          style={{ float: "right", marginRight: "10px", marginBottom: "5px" }}
          variant="outlined"
          onClick={ExportToExcel}
        >
          Export to Excel
        </Button>

        <Box style={{ float: "left", marginBottom: "5px" }}>
          <SearchIcon sx={{ mr: 1 }} />
          <input
            type="text"
            placeholder="Search by ID,Name"
            onChange={handleSearchInputChange}
            value={searchQuery}
          />
        </Box>
        {dataNotFound ? (
          <div style={{ marginTop: "50px" }}>
            <h2>Oops! No Student Available in this Course!!!!</h2>
          </div>
        ) : (
          <Paper sx={{ width: "100%", marginBottom: "0px" }}>
            {/* <div> */}
            <TableContainer sx={{ maxHeight: "420px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "30px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "30px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Session
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "130px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Registration No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "200px",
                        position: "sticky",
                        zIndex: "2",
                        top: 0,
                        left: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px",
                      }}
                    >
                      Student Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "2",
                        top: 0,
                        left: 200,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Professional
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "250px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "30px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Date of Birth
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "150px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Father's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "150px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Mother's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "150px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Contact No.
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "170px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Nationality
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "2",
                        top: 0,
                        left: 300,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Address
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height,
                        minWidth: "150px",
                      }}
                    >
                      Qualification
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        minWidth: "150px",
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Qualification %
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Course Type
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "250px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "250px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Course Branch
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Academic Details
                    </TableCell>{" "}
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Enrolled Student
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Enrollment Documents
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Register Student
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "#001d3d",
                        color: "white",
                        minWidth: "100px",
                        position: "sticky",
                        zIndex: "1",
                        top: 0,
                        padding: "5px", // Adjust the padding to reduce height
                        height: "40px", // Set a fixed height
                      }}
                    >
                      Fees Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((student, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{student.admissionSession}</TableCell>
                      <TableCell align="center" style={{ fontWeight: 700 }}>
                        {student.randomId}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          color: "#003459",
                          fontWeight: 700,
                          zIndex: "1",
                          backgroundColor: "white",
                          top: 0,
                          left: 0,
                          position: "sticky",
                        }}
                      >
                        {student.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          zIndex: "1",
                          backgroundColor: "white",
                          top: 0,
                          left: 200,
                          position: "sticky",
                          color:
                            !student.professional ||
                            !student.professional.SamagraId ||
                            !student.professional.ScholarshipRequired
                              ? "#6a994e" // Color for 'Update'
                              : Object.values(student.professional).some(
                                  (value) => value === ""
                                )
                              ? "#bf0603" // Color for 'Pending'
                              : "#6a994e", // Default color for 'Update'
                          fontWeight: "bold",
                        }}
                      >
                        {!student.professional ||
                        !student.professional.SamagraId ||
                        !student.professional.ScholarshipRequired
                          ? "Update"
                          : Object.values(student.professional).some(
                              (value) => value === ""
                            )
                          ? "Pending"
                          : "Update"}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: "#840032", fontWeight: 700 }}
                      >
                        {student.email}
                      </TableCell>
                      <TableCell align="center">
                       {student.dob}
                      </TableCell>
                      <TableCell align="left">{student.fathersname}</TableCell>
                      <TableCell align="left">{student.mothersname}</TableCell>
                      <TableCell align="left">{student.mobile}</TableCell>
                      <TableCell align="left">{student.domicile}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          zIndex: "1",
                          backgroundColor: "white",
                          top: 0,
                          left: 300,
                          position: "sticky",
                          color: student.address
                            ? Object.values(student.address).some(
                                (value) => value === ""
                              )
                              ? "#bf0603"
                              : "#6a994e"
                            : "#bf0603", // Default color if `student.address` is falsy
                          fontWeight: "bold",
                        }}
                      >
                        {student.address
                          ? Object.values(student.address).some(
                              (value) => value === ""
                            )
                            ? "Pending"
                            : "Update"
                          : "Pending"}
                      </TableCell>
                      <TableCell align="center">
                        {student.qualification}
                      </TableCell>
                      <TableCell align="center">
                        {student.qualificationPercentage}
                      </TableCell>
                      <TableCell align="center">{student.courseType}</TableCell>
                      <TableCell align="left">{student.courseName}</TableCell>
                      <TableCell align="center">{student.branch}</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          color:
                            student.academicDetails &&
                            Object.keys(student.academicDetails)
                              .filter((key) =>
                                [
                                  "tenthBoard",
                                  "tenthMarksObtain",
                                  "tenthMaxMarks",
                                  "tenthPassingYear",
                                  "tenthPercentage",
                                  "tenthRollNo",
                                  "tenthSchool",
                                  "twelfthBoard",
                                  "twelfthMarksObtain",
                                  "twelfthMaxMarks",
                                  "twelfthPassingYear",
                                  "twelfthPercentage",
                                  "twelfthRollNo",
                                  "twelfthSchool",
                                ].includes(key)
                              )
                              .every(
                                (key) => student.academicDetails[key] !== ""
                              )
                              ? "#6a994e" // Color for 'Update'
                              : "#bf0603", // Color for 'Pending'
                          fontWeight: "bold",
                        }}
                      >
                        {student.academicDetails &&
                        Object.keys(student.academicDetails)
                          .filter((key) =>
                            [
                              "tenthBoard",
                              "tenthMarksObtain",
                              "tenthMaxMarks",
                              "tenthPassingYear",
                              "tenthPercentage",
                              "tenthRollNo",
                              "tenthSchool",
                              "twelfthBoard",
                              "twelfthMarksObtain",
                              "twelfthMaxMarks",
                              "twelfthPassingYear",
                              "twelfthPercentage",
                              "twelfthRollNo",
                              "twelfthSchool",
                            ].includes(key)
                          )
                          .every((key) => student.academicDetails[key] !== "")
                          ? "Update"
                          : "Pending"}
                      </TableCell>{" "}
                      <TableCell align="center">
                        {student?.isEnrolled ? "Yes" : "No"}
                      </TableCell>
                   
                      <TableCell
                        align="center"
                        style={{
                          fontWeight: 700,
                          color: student.Documents
                            ? [
                                "incomeCertificate",
                                "otherCertificate",
                                "postgraduateCertificate",
                                "undergraduateCertificate",
                                "migrationCertificate",
                              ].every(
                                (key) =>
                                  !student.Documents[key] ||
                                  student.Documents[key].trim() === ""
                              )
                              ? "#bf0603" // Color for 'Pending'
                              : "#6a994e" // Color for 'Updated'
                            : "#bf0603", // Color for 'Pending' when no documents exist
                          fontWeight: "bold",
                        }}
                      >
                        {student.Documents
                          ? [
                              "incomeCertificate",
                              "otherCertificate",
                              "postgraduateCertificate",
                              "undergraduateCertificate",
                              "migrationCertificate",
                            ].every(
                              (key) =>
                                !student.Documents[key] ||
                                student.Documents[key].trim() === ""
                            )
                            ? "Pending"
                            : "Updated"
                          : "Pending"}
                      </TableCell>
                      <TableCell align="center">
                        {student?.isRegistered ? "True" : "False"}
                      </TableCell>
                      <TableCell align="center">
                        {student?.isPaid ? "Paid" : "Not Paid"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
         
        )}
      
      </Box>
    </>
  );
};
export default Adminser;

import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import Cookies from "js-cookie";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";


function TotalRegistered() {
  const [courseTypes] = useState([
    "UG",
    "PG",
    "Diploma",
    "PG_Diploma",

  ]); // Static course types
  const [selectedCourseType, setSelectedCourseType] = useState(""); // Selected Course Type
  const [courseNames, setCourseNames] = useState([]); // Course Names for selected Course Type
  const [studentdata, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [sessions, setSessions] = useState([]); // Array of session objects
  const [selectedSession, setSelectedSession] = useState("");
  // Pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const [selectedCourseName, setSelectedCourseName] = useState("");

  const debounceTimer = useRef(null);
  useEffect(() => {
    setLoading(true);
    fetch("https://sssutms.ac.in/apitest/v2/admission-sessions")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched sessions data:", data);
        setSessions(Array.isArray(data.data) ? data.data : []); // Access data.data
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sessions:", error);
        setSessions([]); // Fallback to empty array
        setLoading(false);
      });
  }, []);

  // Handle Course Type Selection
  const handleCourseTypeChange = async (e) => {
    const courseType = e.target.value;
    setSelectedCourseType(courseType);
    setSelectedCourseName(""); // Reset course name when type changes

    if (courseType) {
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses4?courseType=${courseType}`
        );
        const data = await response.json();

        if (response.ok) {
          // Extract course names from the response
          const extractedCourseNames =
            data.courses.flatMap((course) => course.courseNames) || [];
          setCourseNames(extractedCourseNames); // Set the extracted course names
        } else {
          console.error("Error fetching course names:", data.message);
        }
      } catch (error) {
        console.error("Error fetching course names:", error);
      }
    } else {
      setCourseNames([]); // Clear courses if no type is selected
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setPage(0);

    // Clear previous timeout
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new debounce timeout
    debounceTimer.current = setTimeout(() => {
      setDebouncedSearchQuery(value);
    }, 1000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("accessToken");
        setLoading(true);

        // Construct query params
        const queryParams = new URLSearchParams({
          page: page + 1, // Backend is 1-based, frontend is 0-based
          limit: rowsPerPage,
          admissionSession: selectedSession,
          courseName: selectedCourseName,
          searchQuery: debouncedSearchQuery,
        }).toString();

        const response = await fetch(
          `https://sssutms.ac.in/apitest/admin/students/totallist2?${queryParams}`
        );

        const data = await response.json();

        if (response.ok) {
          setStudentData(data.students || []);
          setTotalRecords(data.pagination?.totalRecords || 0);
        } else {
          console.error("Error fetching data:", data.error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    page,
    rowsPerPage,
    selectedSession,
    selectedCourseName,
    debouncedSearchQuery,
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page when changing rows per page
  };



  const ExportToExcel = () => {
    if (studentdata.length > 0) {
      const Excel = studentdata.map((student) => ({
        Admission_Session: student.admissionSession,
        Random_Id: student.randomId,
        Random_Password: student.randomPassword,
        Registered_Date: student.createdAt,
        Name: student.name,
        Fathers_Name: student.fathersname,
        Mothers_Name: student.mothersname,
        Email: student.email,
        Mobile: student.mobile,
        Course_Type: student.courseType,
        Course_Name: student.courseName,
        Course_Branch: student.courseBranch,
        Student_Type: student.StudentType || "Normal",
      }));

      const worksheet = XLSX.utils.json_to_sheet(Excel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
      XLSX.writeFile(workbook, "Student_Data.xlsx");
    } else {
      alert("No data available for export!");
    }
  };

  return (
    <>

      <AdminDashboard />
      <Box
        sx={{
          width: "90%",
          marginLeft: "100px",
          marginTop: "100px",
        }}
      >
        {loading ? (
          <CircularProgress
            color="success"
            style={{ marginTop: "80px", marginLeft: "50%" }}
          />
        ) : (
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TextField
              style={{
                float: "right",
                marginRight: "10px",
                marginBottom: "10px",
              }}
              variant="outlined"
              placeholder="Search  by ID or Name"
              value={searchQuery}
              onChange={handleSearchChange}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 300 }}
            />
            <Button
              style={{ float: "right", marginRight: "10px" }}
              variant="outlined"
              onClick={ExportToExcel}
            >
              Export to Excel
            </Button>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <select
                className="form-select form-select-md login-input"
                required={true}
                value={selectedSession}
                onChange={(e) => setSelectedSession(e.target.value)}
                >
                 <option value="">Select</option>
                        {loading ? (
                          <option>Loading...</option>
                        ) : (
                          sessions.map((sess, index) => (
                            <option key={index} value={sess.sessionName}>
                              {sess.sessionName}
                            </option>
                          ))
                        )}
              </select>

              <select
                className="form-select form-select-md login-input"
                value={selectedCourseType}
                onChange={handleCourseTypeChange}
              >
                <option value="">Select Course Type</option>
                {courseTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>

              {/* Course Name Dropdown */}
              <select
                className="form-select form-select-md login-input"
                value={selectedCourseName}
                onChange={(e) => setSelectedCourseName(e.target.value)}
                disabled={!selectedCourseType} // Disable if no course type selected
              >
                <option value="">Select Course Name</option>
                {courseNames.map((course, index) => (
                  <option key={index} value={course.courseName}>
                    {course.courseName}
                  </option>
                ))}
              </select>
            </div>

            <TableContainer sx={{ maxHeight: "400px", overflowX: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        color: "white",
                        left: 0,
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Admission Session
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Registration ID
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Registration Password
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Registered Date
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "190px",
                        position: "sticky",
                        zIndex: 2,
                        color: "white",
                        top: 0,
                        left: 50,
                      }}
                    >
                      Candidate Name
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "150px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Father's Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "150px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Mother's Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                        minWidth: "100px",
                        color: "white",
                      }}
                    >
                      Contact{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Course Type
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "250px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Course Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "250px",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Course Branch
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "150px",
                        position: "sticky",
                        color: "white",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Student Type
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "150px",
                        position: "sticky",
                        color: "white",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Registration Fee status
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: "rgb(2, 48, 71)",
                        minWidth: "150px",
                        position: "sticky",
                        color: "white",
                        top: 0,
                        zIndex: 1,
                        color: "white",
                      }}
                    >
                      Approval  status
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentdata.map((student, index) => (
                    <TableRow key={student.randomId}>
                      <TableCell
                        style={{
                          position: "sticky",
                          left: 0,
                          top: 0,
                          zIndex: 1,
                          backgroundColor: "white",
                        }}
                      >
                        {index + 1 + page * rowsPerPage}
                      </TableCell>
                      <TableCell
                        style={{ color: "#780000", fontWeight: 700 }}
                      >
                        {student?.admissionSession}
                      </TableCell>
                      <TableCell
                        style={{ color: "#780000", fontWeight: 700 }}
                      >
                        {student.randomId}
                      </TableCell>

                      <TableCell>{student.randomPassword}</TableCell>
                      <TableCell>
                        <DatePicker
                          selected={
                          
                            (student?.createdAt
                              ? new Date(student.createdAt)
                              : null)
                          }
                          readOnly
                          dateFormat="dd/MM/yyyy HH:mm" // Date and time format
                          showTimeSelect
                          timeFormat="HH:mm" // Specify the time format
                          timeIntervals={15} // Set time intervals if needed
                          className="text-left"
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          // color: "#e85d04",
                          fontSize: "15px",
                          fontWeight: 700,
                          position: "sticky",
                          left: 50,
                          zIndex: 1,
                          top: 0,

                          backgroundColor: "white", // Ensure background color to avoid overlap issues
                          // padding: '8px', // Add padding to create space
                        }}
                      >
                        {student.name}
                      </TableCell>

                      <TableCell
                        style={
                          {
                            // padding: '8px', // Ensure consistent padding
                          }
                        }
                      >
                        {student.fathersname}
                      </TableCell>
                      <TableCell>{student.mothersname}</TableCell>
                      <TableCell
                        style={{ color: "#840032", fontWeight: 700 }}
                      >
                        {student.email}
                      </TableCell>
                      <TableCell align="left">{student.mobile}</TableCell>
                      {/* <TableCell>{student.courseType}</TableCell> */}
                      <TableCell
                        style={{
                          fontWeight: 700,
                          color:
                            student.courseType === "UG"
                              ? "blue"
                              : student.courseType === "PG"
                                ? "#8d0801"
                                : student.courseType === "Diploma"
                                  ? "purple"
                                  : student.courseType === "PG_Diploma"
                                    ? "green"
                                    : "black",
                        }}
                      >
                        {student.courseType || "Not Choose"}
                      </TableCell>

                      <TableCell>
                        {student.courseName || "Not Choose"}
                      </TableCell>
                      <TableCell>
                        {student.courseBranch || "Not Choose"}
                      </TableCell>
                      {/* <TableCell>
                            {student.StudentType || "Normal"}
                          </TableCell> */}

                      <TableCell
                        style={{
                          color: student.StudentType ?  "green":"red",
                        }}
                      >
                        {student.StudentType || "Normal"}
                      </TableCell>
                      <TableCell
                        style={{
                          color: student.isPaid ?  "green":"red" ,
                        }}
                      >
                        {student.isPaid?"Paid" : "Not Paid"}
                      </TableCell>
                      <TableCell
                        style={{
                          color: student.isApproved ? "green":"red" ,
                        }}
                      >
                        {student.isApproved? "Approved" : "Not Approved"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <h5>Total Number:{studentdata.length} </h5> */}
            <TablePagination
              rowsPerPageOptions={[
                10, 20, 50, 100, 200, 300, 500, 1000, 2000, 3000, 5000,
              ]}
              component="div"
              count={totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Box>

    </>
  );
}

export default TotalRegistered;
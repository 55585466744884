import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  createTheme,
  Checkbox,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import swal from "sweetalert";

const theme = createTheme();

const ExamVerificationtable = ({
  courseType,
  courseBranch,
  courseName,
  semester,

  mode,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(7);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [examFormId, setExamFormId] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let apiUrl = "";
        if (mode === "forwarded") {
          apiUrl = `https://sssutms.ac.in/apitest/admin/forward-exam-form-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}`;
        } else if (mode === "verified") {
          apiUrl = `https://sssutms.ac.in/apitest/admin/verified-exam-form-list?courseType=${courseType}&courseBranch=${courseBranch}&courseName=${courseName}&semester=${semester}&`;
        }

        const response = await axios.get(apiUrl);
        setData(response.data.data || []);
        setExamFormId(response.data.data[0]?.formId || "");
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    if (mode) {
      setTimeout(fetchData, 2000); // Simulate delay
    }
  }, [courseType, courseBranch, courseName, semester, mode]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudentIds((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedStudentIds(data.map((student) => student.studentId));
    } else {
      setSelectedStudentIds([]);
    }
  };

  const handleSubmit = async () => {
    if (!examFormId) {
      alert("Please provide an Exam Form ID.");
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/assignExamForm",
        {
          studentIds: selectedStudentIds,
          examFormId,
        }
      );

      swal({
        title: "Success",
        text: `Exam form assigned successfully!`,
        icon: "success",
        buttons: "OK",
      });

      setData((prevData) =>
        prevData.filter(
          (student) => !selectedStudentIds.includes(student.studentId)
        )
      );
      setSelectedStudentIds([]);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to assign exam form.");
    }
  };

  const filteredData = data.filter((student) =>
    student.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
console.log(filteredData,"dataa aa rhhhhhh")
  const handleSort = () => {
    const sortedData = [...data].sort((a, b) => {
      const enrollmentA = a.enrollmentNumber.toLowerCase();
      const enrollmentB = b.enrollmentNumber.toLowerCase();
      if (sortOrder === "asc") {
        return enrollmentA.localeCompare(enrollmentB);
      } else {
        return enrollmentB.localeCompare(enrollmentA);
      }
    });
    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        style={{ marginTop: "80px", marginLeft: "100px", width: "78%" }}
      >
        {loading ? (
          <CircularProgress style={{ margin: "20px auto", display: "block" }} />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "10px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                border: "1px solid #ccc",
                backgroundColor: "#f9f9f9",
              }}
            >
              <TextField
                label={`Search by Name (${mode === "forwarded" ? "Forwarded" : "Verified"})`}
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "50%" }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={selectedStudentIds.length === 0}
                style={{ whiteSpace: "nowrap" }}
              >
                Submit Selected Students
              </Button>
            </div>

            <TableContainer
              component={Paper}
              style={{ maxHeight: "480px", overflow: "auto", width: "100%" }}
            >
              <Table stickyHeader>
                <TableHead style={{ height: "10px", padding: "2px" }}>
                  <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#023047",
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      All
                      <Checkbox
                        style={{ color: "white" }}
                        onChange={handleSelectAll}
                        checked={
                          selectedStudentIds.length === data.length &&
                          data.length > 0
                        }
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#023047",
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      S.No.
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#023047",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "12px",
                        minWidth: "150px",
                      }}
                      onClick={handleSort}
                    >
                      Enrollment No {sortOrder === "asc" ? "▲" : "▼"}
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#023047",
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      Candidate Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        backgroundColor: "#023047",
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      Father Name
                    </TableCell>
                    {/* Conditionally render Status column for verified mode */}
                
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#023047",
                          color: "#fff",
                          fontSize: "12px",
                        }}
                      >
                        Forwarded
                      </TableCell>
                
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow key={item.studentId} style={{ height: "10px" }}>
                      <TableCell>
                        <Checkbox
                          style={{ height: "5px" }}
                          checked={selectedStudentIds.includes(item.studentId)}
                          onChange={() => handleCheckboxChange(item.studentId)}
                        />
                      </TableCell>
                      <TableCell>
                        {index + 1 + (currentPage - 1) * rowsPerPage}
                      </TableCell>
                      <TableCell>{item.enrollmentNumber}</TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.fathersname}</TableCell>
                     
                    
                        <TableCell   style={{
                          color:"red",
                          fontWeight: "bold",
                        }}>{item.isForwarded?"Forwarded" : "Not"}</TableCell>
                      
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default ExamVerificationtable;
import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function IQAC() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */}
   
.leadership{
position:relative;
height:300px;
width:100%;
}
.dean-img{
height:340px;
width:300px
}
h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:00px;
          display: flex;
          margin-left:30px;
          flex-direction: column; /* Default to vertical */
      }
  .img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;}
.title-main{
margin-left:30px; font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 
.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;
border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px}
.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;}@media only screen and (max-width: 480px){.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}
.h2 title-main{
margin-left:20px}.img-fluid {
  margin-left:80px;
}
.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}
.alt-two h1 {
  text-align:center;
} .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}
.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}
.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }
       @media (max-width: 480px) {  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>{" "}
      
      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          IQAC Cell
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <div className="sidcontent">
              {" "}
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif" }}
              >
                <h5
                  style={{
                    color: "#AE445A",
                    marginLeft: "200px",
                    marginTop: "-20px",
                    marginBottom: "10px",
                  }}
                >
                  About Internal Quality Assurance Cell (IQAC)
                </h5>
                The Internal Quality Assurance Cell (IQAC) was established in
                8th Oct 2018 at Sri Satya Sai University of Technology & Medical
                Science, Sehore (MP) as a accreditation quality sustenance
                measure. The IQAC has been constituted as per the
                recommendations of the National Assessment and Accreditation
                Council (NAAC). The IQAC ensures the effective implementation of
                quality initiatives through continuous reviews and periodic
                meetings. The IQAC works towards attaining excellence in all
                academic and administrative endeavors of the institution.
              </div>
            </div>
            <br />{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              Objectives
            </h5>
            <ul style={{ marginLeft: "80px", marginTop: "20px" }}>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                To develop a system for conscious, consistent and catalytic
                action to improve the academic and administrative performance of
                the institution.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                To promote measures for institutional functioning towards
                quality enhancement through internalization of quality culture
                and institutionalization of best practices.
              </li>
            </ul>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              Vision
            </h5>
            <p
              style={{
                fontFamily: "'poppins',sansSerif",
                marginLeft: "80px",
                marginTop: "20px",
              }}
            >
              {" "}
              To ensure quality culture as the prime concern for Sri Satya Sai
              University of Technology & Medical Science, Sehore (MP) through
              institutionalizing and internalizing all the initiatives taken
              with internal and external support.
            </p>
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Quality Policy
            </h5>
            <p
              style={{
                fontFamily: "'poppins',sansSerif",
                marginLeft: "80px",
                marginTop: "20px",
              }}
            >
              {" "}
              To establish a system of Quality Enhancement, which would on a
              continuous basis evaluate and enhance the quality of teaching –
              learning, research and extension activities of the institution,
              leading to improvements in all processes, enabling the institution
              to attain excellence.
            </p>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Strategies
            </h5>
            <p
              style={{ fontFamily: "'poppins',sansSerif", marginLeft: "80px" }}
            >
              Shall evolve mechanisms and procedures for:
            </p>
            <ul style={{ marginLeft: "80px" }}>
              <li style={{ fontFamily: "'poppins',sansSerif" }}>
                Ensuring timely, efficient and progressive performance of
                academic, administrative and financial tasks.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Relevant and quality academic/ research programmes.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Equitable access to and affordability of academic programmes for
                various sections of society.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Optimization and integration of modern methods of teaching and
                learning
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                The credibility of assessment and evaluation process
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Ensuring the adequacy, maintenance and proper allocation of
                support structure and services best
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Sharing of research findings and networking with other
                institutions in India and abroad
              </li>{" "}
            </ul>
            <br></br>
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Functions
            </h5>
            <p
              style={{ fontFamily: "'poppins',sansSerif", marginLeft: "80px" }}
            >
              Some of the functions expected of the IQAC are::
            </p>
            <ul style={{ marginLeft: "80px" }}>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Development and application of quality benchmarks.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Parameters for various academic and administrative activities of
                the institution;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Facilitating the creation of a learner-centric environment
                conducive to quality education and faculty maturation to adopt
                the required knowledge and technology for participatory teaching
                and learning process;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Collection and analysis of feedback from all stakeholders on
                quality-related institutional processes; d) Dissemination of
                information on various quality parameters to all stakeholders;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Organization of inter and intra institutional workshops,
                seminars on quality related themes and promotion of quality
                circles;
              </li>{" "}
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Documentation of the various programmes/activities leading to
                quality improvement;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Acting as a nodal agency of the Institution for coordinating
                quality-related activities, including adoption and dissemination
                of best practices;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Development and maintenance of institutional database through
                MIS for the purpose of maintaining /enhancing the institutional
                quality;
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Periodical conduct of Academic and Administrative Audit and its
                follow-up
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Preparation and submission of the Annual Quality Assurance
                Report (AQAR) as per guidelines and parameters of NAAC.
              </li>
            </ul>
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Benefits
            </h5>
            <p
              style={{ fontFamily: "'poppins',sansSerif", marginLeft: "80px" }}
            >
              Will facilitate / contribute to:
            </p>
            <ul style={{ marginLeft: "80px" }}>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Ensure clarity and focus in institutional functioning towards
                quality enhancement
              </li>{" "}
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Ensure internalization of the quality culture
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Ensure enhancement and coordination among various activities of
                the institution and institutionalize all good practices
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Provide a sound basis for decision-making to improve
                institutional functioning
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Act as a dynamic system for quality changes in HEIs
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Build an organised methodology of documentation and internal
                communication
              </li>
            </ul>
            <br></br>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Outcomes of IQAC Activities at SSSUTSM
            </h5>
            <ul style={{ marginLeft: "80px" }}>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                NAAC Accreditation
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Ranking by NIRF
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Annual reports of the University
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Continous improvement in academic and non-academic activities of
                university
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                Analysis of Student/alumni/faculty/employer/parent feedbacks
              </li>{" "}
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                MHRD All India Survey for Higher Education
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Swachh Bharat Summer Internship Program
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "5px" }}
              >
                {" "}
                Swachhta Ranking
              </li>{" "}
            </ul>
            <br></br>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Constitution of IQAC Cell
            </h5>
            <br></br>
            <style>
              {`
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `}
            </style>
            <div className="tann">
              <table className="responsive-table">
                {" "}
                <thead>
                  <tr>
                    <th scope="col">S. No.</th> <th scope="col">Name</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Post</th>{" "}
                  </tr>
                </thead>{" "}
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>1 </td>
                    <td style={{ textAlign: "left" }}> Prof. Mukesh Tiwari</td>
                    <td style={{ textAlign: "left" }}> Vice-Chancellor</td>
                    <td style={{ textAlign: "left" }}> Chairperson</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>2</td>
                    <td style={{ textAlign: "left" }}>Prof. Hemant Sharma</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>{" "}
                  <tr>
                    <td style={{ textAlign: "left" }}>3</td>
                    <td style={{ textAlign: "left" }}>Prof. Sanjay Rathor</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>{" "}
                  <tr>
                    <td style={{ textAlign: "left" }}>4 </td>
                    <td style={{ textAlign: "left" }}>Prof. S.B. Tambe</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}> 5 </td>
                    <td style={{ textAlign: "left" }}> Prof. A.K Dubey </td>
                    <td style={{ textAlign: "left" }}> Professor </td>
                    <td style={{ textAlign: "left" }}> Member </td>
                  </tr>{" "}
                  <tr>
                    <td style={{ textAlign: "left" }}>6</td>
                    <td style={{ textAlign: "left" }}>Prof. Tabhessum Khan</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>7</td>
                    <td style={{ textAlign: "left" }}>Prof. C.K. Tyagi</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>8</td>
                    <td style={{ textAlign: "left" }}>Prof. Priyanka Jhawar</td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>{" "}
                  <tr>
                    <td style={{ textAlign: "left" }}>9</td>
                    <td style={{ textAlign: "left" }}>
                      Prof . Minakshi Pathak
                    </td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>10</td>
                    <td style={{ textAlign: "left" }}>
                      Prof. Manoj Singh Raghuwanshi
                    </td>
                    <td style={{ textAlign: "left" }}> Professor</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>11</td>
                    <td style={{ textAlign: "left" }}>Prof. Dheeraj Agarwal</td>
                    <td style={{ textAlign: "left" }}>External Menber</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>12</td>
                    <td style={{ textAlign: "left" }}>Mr. Anil kumar</td>
                    <td style={{ textAlign: "left" }}>External Menber</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>13</td>
                    <td style={{ textAlign: "left" }}>Prof. Manoj Shukla</td>
                    <td style={{ textAlign: "left" }}>External Menber</td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>14</td>
                    <td style={{ textAlign: "left" }}>
                      Prof. Rajendra Singh Kushwah
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {" "}
                      Professor & Director IQAC
                    </td>
                    <td style={{ textAlign: "left" }}> Member</td>
                  </tr>{" "}
                </tbody>
              </table>
            </div>
            <br></br>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Standard Operation Procedures
            </h5>
            <ol style={{ marginLeft: "80px" }}>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                The tenure of IQAC will be for a continuous period of 2 years
                from the date of appointment.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                The Meeting of the IQAC will be conducted quarterly. The quorum
                for the meeting shall be two-third of the total number of
                members. Additional meetings may be held as and when required.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                Director/Secretary in consultation with the chairperson will
                decide the agenda and send communication to all members. The
                date, time, venue and agenda of the meeting will be communicated
                to the members at least one week in advance.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                Minutes of the meeting will be uploaded to institutional website
                also.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                The agenda, minutes and action taken reports will be documented
                in hard and soft copy formats.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                Academic and Administrative audits will be done after completion
                of the academic year. The Schedule of the audit will be
                finalized by the Chairperson after discussion with the members
                during the IQAC meeting. Audit reports submitted by the auditors
                will be sent to the departments for corrective action.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                AQAR will be prepared and discussed during the IQAC meeting.
                Finalized AQAR will be placed before College Council for the
                approval and the approved AQAR will be submitted to the NAAC
                after Cycle 1 of accreditation.
              </li>
              <li
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "10px" }}
              >
                {" "}
                The composition of IQAC shall be as follows-
              </li>
              <ul>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Chairperson: Head of the Institution
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Teachers to represent all level (Three to eight)
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  One member from the Management{" "}
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  Few senior administrative officers
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  One nominee each from local society, Students, and Alumni{" "}
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  One nominee each from Employers/Industrialists/Stakeholders
                </li>
                <li
                  style={{
                    fontFamily: "'poppins',sansSerif",
                    marginTop: "5px",
                    marginLeft: "10px",
                  }}
                >
                  One of the senior teachers as the coordinator/Director of the
                  IQAC{" "}
                </li>
              </ul>{" "}
            </ol>
            <br></br>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              Minutes of the Meeting Internal Quality Assurance Cell (IQAC)
            </h5>
            <br></br>
            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    }    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    }    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family:'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>{" "}
            <div className="table-container">
              <table style={{ textAlign: "center" }}>
                <thead style={{ backgroundColor: "#588157" }}>
                  <tr>
                    <th scope="col">
                      <h6>
                        <b>Year</b>
                      </h6>
                    </th>
                    <th colSpan={4} scope="colgroup">
                      <h6>
                        <b>Minutes of Meeting</b>
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2024
                    </th>
                  </tr>
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/28 Feb 2024.pdf">
                        28 Feb 2024
                      </Link>
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2023
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/09Dec.pdf">
                        09 Dec 2023
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/20SEP.pdf">
                        20 Sep 2023
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/12May.pdf">
                        12 May 2023
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/28FEB2023.pdf">
                        28 Feb 2023
                      </Link>
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {" "}
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2022
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/30NOV.pdf">
                        30 Nov 2022
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/20AUG.pdf">
                        20 Aug 2022
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/7MAY.pdf">
                        07 May 2022
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/19FEB.pdf">
                        19 Feb 2022
                      </Link>
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {" "}
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2021
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/4OCT.pdf">
                        04 Oct 2021
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/9JUNE.pdf">
                        09 Jun 2021
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/5APRIL.pdf">
                        05 Apr 2021
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/8FEB.pdf">
                        08 Feb 2021
                      </Link>
                    </th>
                  </tr>
                </tbody>
              </table>{" "}
              <table style={{ textAlign: "center" }}>
                <thead style={{ backgroundColor: "#588157" }}>
                  <tr>
                    <th scope="col">
                      <h6>
                        <b>Year</b>
                      </h6>
                    </th>
                    <th colSpan={4} scope="colgroup">
                      <h6>
                        <b>Minutes of Meeting</b>
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {" "}
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2020
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/21DEC.pdf">
                        21 Dec 2020
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/22JULY.pdf">
                        22 Jul 2020
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      {" "}
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/16APRIL.pdf">
                        16 Apr 2020
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/15JAN.pdf">
                        15 Jan 2020
                      </Link>
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {" "}
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2019
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/17DECC.pdf">
                        17 Dec 2019
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/12SEP.pdf">
                        12 Sep 2019
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/28MAY.pdf">
                        28 May 2019
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/17FEB.pdf">
                        17 Feb2019
                      </Link>
                    </th>
                  </tr>
                </tbody>
                <tbody>
                  {" "}
                  <tr>
                    <th rowSpan={20} scope="rowgroup">
                      2018
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/24NOV.pdf">
                        24 Nov 2018
                      </Link>
                    </th>
                  </tr>
                  <tr>
                    <th scope="row">
                      <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/18AUG.pdf">
                        18 Aug 2018
                      </Link>
                    </th>
                  </tr>{" "}
                </tbody>
              </table>
            </div>{" "}
            <br></br>{" "}
            <h5
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#AE445A",
                marginLeft: "70px",
              }}
            >
              {" "}
              NAAC
            </h5>
            <br></br>{" "}
            <div className="tann">
              <table className="responsive-table">
                {" "}
                <thead>
                  <tr>
                    <th scope="col">Criteria </th>
                    <th scope="col"> </th>
                    <th scope="col">Supporting Document</th>
                  </tr>

                </thead>{" "}
                <tbody>
                  <tr>
                    <td> 1</td>
                    <td style={{ textAlign: "left" }}> </td>
                    <td>
                      {" "}
                      <table
                        style={{
                          width: "100%",
                          marginTop: "10px",
                          borderCollapse: "collapse",
                        }}
                      >
                        <tbody>
                          <tr>
                            {" "}
                            <th>S.No</th> <th>Facilities</th> <th>Session</th>{" "}
                            <th>Report</th>
                          </tr>
                          <tr>
                            {" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              1{" "}
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              Academic Council Meeting
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              2017-18 To 2021-22
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ACFINAL.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              2{" "}
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              Board of Mangement Meetings
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              2017-18 To 2021-22
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BOMFINAL.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              3{" "}
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              Board of Governance Meetings{" "}
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              {" "}
                              2017-18 To 2021-22
                            </td>{" "}
                            <td
                              style={{
                                border: "1px solid #999",
                                padding: "5px",
                              }}
                            >
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BOGFINAL.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>2</th>{" "}
                            <th columnSpan="4">
                              {" "}
                              Minutes of Relevant Board of Studies
                            </th>
                          </tr>
                          <tr>
                            {" "}
                            <th>School Name </th> <th>Department </th>{" "}
                            <th>Program </th>{" "}
                            <th>Session (2017-18 To 2021-22)</th>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="21">School of Engineering </th>{" "}
                            <td>Aeronautical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AE1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Chemical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CE1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Civil Engineering</td>{" "}
                            <td>Bachelor of Engineering</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CIVIL1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Structural Design</td>{" "}
                            <td>Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Computer Science and Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CS1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Computer Science and Engineering </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Software Engineering</td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical and Electronics Engineering</td>
                            <td> Bachelor of Engineering </td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EEE1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Electrical Engineering</td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EE1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical Power System</td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Power Electronics </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>
                              Electronic & Communication Engineering{" "}
                            </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EC1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Digital Communication </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>VLSI </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electronics & Instrumentation Engineering</td>
                            <td> Bachelor of Engineering</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EI1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/IT1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology</td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mechanical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MECHANICAL1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Industrial Design </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Thermal Engineering </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mining Engineering </td>
                            <td> Bachelor of Engineering</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MINING1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">Faculty of Pharmacy</th>{" "}
                            <td>Pharmacy </td> <td> Bachelor of Pharmacy</td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHARMACY1.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmacology</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmaceutics</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">
                              School of Management Studies
                            </th>{" "}
                            <td rowSpan="2">Management </td> <td>BBA</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>MBA</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of law</th>
                            <td> Law</td>
                            <td> Law</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="10">Faculty of Education</th>{" "}
                            <td rowSpan="2">Arts </td>{" "}
                            <td>Bachelor of Arts </td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ARTS1.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Arts</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>
                            <td>Bachelor of Physical Education</td>
                            <td>Click Here</td>
                          </tr>
                          <tr>
                            <td rowSpan="2">Science</td>{" "}
                            <td>Bachelor of Science</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENEC1.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Science</td>
                          <tr>
                            <td rowSpan="2">Commerce</td>{" "}
                            <td>Bachelor of Commerce</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Commerce</td>
                          <tr>
                            <td rowSpan="2">Education</td> <td>B.A. Ed</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BED1.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> B.Ed</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>{" "}
                            <td> Bachelor of Physical Education</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/bped1.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">
                              School Computer Application
                            </th>{" "}
                            <td rowSpan="3">Computer Application</td>
                            <td>P.G.D.C.A</td>
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>B.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>M.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> School Of Hotel Mangement</th>{" "}
                            <td> HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Bachelor of HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HM1.pdf">
                                {" "}
                                Click her
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Homeopathy</th> <td> Homeopathy</td>
                            <td> B.H.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HOMEO1.pdf">
                                {" "}
                                Click her
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Agriculture</th> <td> Agriculture</td>{" "}
                            <td> Bachelor of Agriculture</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AGRI1.pdf">
                                {" "}
                                Click her
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Ayurveda & Siddha Studies</th>{" "}
                            <td> Ayurveda</td> <td> B.A.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AYURVED1.pdf">
                                {" "}
                                Click her
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">School of Nursing</th>{" "}
                            <td rowSpan="2">Nursing</td>{" "}
                            <td>Bachelor of Nursing</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/NURSING1.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>P.B.Sc (Nursing)</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Paramedical</th> <td> Paramedical</td>
                            <td> B.P.T</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PERAMEDICAL1.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Arts</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Science</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENCE12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Commerce</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/COM12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Management Studies</th>{" "}
                            <td>Ph .D.</td>
                            <td> Mangement</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MGT12.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Physical Education</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PED12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Education</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EDU12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Engineering</th> <td> Ph .D.</td>
                            <td> Engineering</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ENGG12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>Faculty of Education</th> <td> Ph .D.</td>
                            <td> Library Science</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/LIB1.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>Faculty of Pharmacy</th> <td> Ph .D.</td>
                            <td> Pharmacy</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHARMACY12.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br></br>
                      <br></br>
                      <table>
                        <tbody>
                          <tr>
                          
                            <th>2</th>
                            <th colspan="3">
                              Curricular developed and implemented have
                              relevance to the local, national, regional, and
                              global developmental needs which is reflected in
                              Programme outcomes (POs), Programme Specific
                              Outcomes (PSOs), and Course Outcomes (COs) of the
                              Programmes offered by the Institution.
                            </th>
                          </tr>
                          <tr>
                            {" "}
                            <th>School Name </th> <th>Department </th>{" "}
                            <th>Program </th>{" "}
                            <th>Session (2017-18 To 2021-22)</th>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="21">School of Engineering </th>{" "}
                            <td>Aeronautical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td rowSpan="21">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SOE_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Chemical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Civil Engineering</td>{" "}
                            <td>Bachelor of Engineering</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Structural Design</td>{" "}
                            <td>Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Computer Science and Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Computer Science and Engineering </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Software Engineering</td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical and Electronics Engineering</td>
                            <td> Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Electrical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical Power System </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Power Electronics </td>
                            <td> Master of Technology</td>{" "}
                          </tr>
                          <tr>
                            {" "}
                            <td>
                              Electronic & Communication Engineering{" "}
                            </td>{" "}
                            <td>Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Digital Communication </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>VLSI </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electronics & Instrumentation Engineering</td>
                            <td> Bachelor of Engineering</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td>Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mechanical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Industrial Design </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Thermal Engineering </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mining Engineering </td>
                            <td> Bachelor of Engineering</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">Faculty of Pharmacy</th>{" "}
                            <td>Pharmacy </td> <td> Bachelor of Pharmacy</td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/FOP_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmacology</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmaceutics</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">
                              School of Management Studies
                            </th>{" "}
                            <td rowSpan="2">Management </td> <td>BBA</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MGMNT_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>MBA</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of law</th>
                            <td> Law</td>
                            <td> Law</td>{" "}
                            <td>
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/LAW_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="10">Faculty of Education</th>{" "}
                            <td rowSpan="2">Arts </td>{" "}
                            <td>Bachelor of Arts </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/ARTS_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <td> Master of Arts</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>
                            <td>Bachelor of Physical Education</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PHYEDU_COMBINE.pdf">
                                Click Here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="2">Science</td>{" "}
                            <td>Bachelor of Science</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/SCIENCE_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Science</td>
                          <tr>
                            <td rowSpan="2">Commerce</td>{" "}
                            <td>Bachelor of Commerce</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/COM_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Commerce</td>
                          <tr>
                            <td rowSpan="2">Education</td> <td>B.A. Ed</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/EDUCATION_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>{" "}
                          </tr>
                          <td> B.Ed</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>{" "}
                            <td> Bachelor of Physical Education</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">
                              School Computer Application
                            </th>{" "}
                            <td rowSpan="3">Computer Application</td>
                            <td>P.G.D.C.A</td>
                            <td rowSpan="3">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/CA_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>B.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>M.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> School Of Hotel Mangement</th>{" "}
                            <td> HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Bachelor of HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HM_COMBINE.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Homeopathy</th> <td> Homeopathy</td>
                            <td> B.H.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/HOMEO_COMBINE.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Agriculture</th> <td> Agriculture</td>{" "}
                            <td> Bachelor of Agriculture</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AGRI_COMBINE.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Ayurveda & Siddha Studies</th>{" "}
                            <td> Ayurveda</td> <td> B.A.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/AYURVED_COMBINE.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">School of Nursing</th>{" "}
                            <td rowSpan="2">Nursing</td>{" "}
                            <td>Bachelor of Nursing</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/NURS_COMBINE.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>P.B.Sc (Nursing)</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Paramedical</th> <td> Paramedical</td>
                            <td> B.P.T</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/PARAMED_COMBINE.pdf">
                                {" "}
                                Click here
                              </Link>
                            </td>
                          </tr>
                        </tbody>{" "}
                        <br></br>
                        <tbody>
                          <tr>
                            {" "}
                            <th>2</th>{" "}
                            <th colspan="8">
                              {" "}
                              Percentage of Programmes where syllabus revision
                              was carried out during the last five years.
                              Response
                            </th>
                          </tr>
                          <tr>
                            {" "}
                            <th>School Name </th> <th>Department </th>{" "}
                            <th>Program </th>{" "}
                            <th>Session (2017-18 To 2021-22)</th>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="21">School of Engineering </th>{" "}
                            <td>Aeronautical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Chemical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Civil Engineering</td>{" "}
                            <td>Bachelor of Engineering</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Structural Design</td>{" "}
                            <td>Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Computer Science and Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Computer Science and Engineering </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Software Engineering</td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical and Electronics Engineering</td>
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Electrical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical Power System </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Power Electronics </td>
                            <td> Master of Technology</td>{" "}
                          </tr>
                          <tr>
                            {" "}
                            <td>
                              Electronic & Communication Engineering{" "}
                            </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Digital Communication </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>VLSI </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>

                            </td>
                            <td> Bachelor of Engineering</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mechanical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Industrial Design </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Thermal Engineering </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mining Engineering </td>
                            <td> Bachelor of Engineering</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">Faculty of Pharmacy</th>{" "}
                            <td>Pharmacy </td> <td> Bachelor of Pharmacy</td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmacology</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmaceutics</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">
                              School of Management Studies
                            </th>{" "}
                            <td rowSpan="2">Management </td> <td>BBA</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>MBA</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of law</th>
                            <td> Law</td>
                            <td> Law</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="10">Faculty of Education</th>{" "}
                            <td rowSpan="2">Arts </td>{" "}
                            <td>Bachelor of Arts </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <td> Master of Arts</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>
                            <td>Bachelor of Physical Education</td>
                            <td>Click Here</td>
                          </tr>
                          <tr>
                            <td rowSpan="2">Science</td>{" "}
                            <td>Bachelor of Science</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Science</td>
                          <tr>
                            <td rowSpan="2">Commerce</td>{" "}
                            <td>Bachelor of Commerce</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Commerce</td>
                          <tr>
                            <td rowSpan="2">Education</td> <td>B.A. Ed</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> B.Ed</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>{" "}
                            <td> Bachelor of Physical Education</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">
                              School Computer Application
                            </th>{" "}
                            <td rowSpan="3">Computer Application</td>
                            <td>P.G.D.C.A</td>
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>B.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>M.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> School Of Hotel Mangement</th>{" "}
                            <td> HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Bachelor of HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Click her</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Homeopathy</th> <td> Homeopathy</td>
                            <td> B.H.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Agriculture</th> <td> Agriculture</td>{" "}
                            <td> Bachelor of Agriculture</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Ayurveda & Siddha Studies</th>{" "}
                            <td> Ayurveda</td> <td> B.A.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">School of Nursing</th>{" "}
                            <td rowSpan="2">Nursing</td>{" "}
                            <td>Bachelor of Nursing</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>P.B.Sc (Nursing)</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Paramedical</th> <td> Paramedical</td>
                            <td> B.P.T</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Arts</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Science</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Commerce</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Management Studies</th>{" "}
                            <td>Ph .D.</td>
                            <td> Mangement</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Physical Education</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> Faculty of Education</th> <td> Ph .D.</td>
                            <td> Education</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Engineering</th> <td> Ph .D.</td>
                            <td> Engineering</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>Faculty of Education</th> <td> Ph .D.</td>
                            <td> Library Science</td>
                            <td> Click here</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>Faculty of Pharmacy</th> <td> Ph .D.</td>
                            <td> Pharmacy</td>
                            <td> Click here</td>
                          </tr>
                        </tbody>
                        <br></br>
                        <br></br>
                        <tbody>
                          <tr>
                            {" "}
                            <th></th>{" "}
                            <th colspan="8">
                              {" "}
                              Average percentage of courses having focus on
                              employability/ entrepreneurship/ skill development
                              offered by the institution during the last five
                              years
                            </th>
                          </tr>
                          <tr>
                            {" "}
                            <th>School Name </th> <th>Department </th>{" "}
                            <th>Program </th>{" "}
                            <th>Session (2017-18 To 2021-22)</th>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="21">School of Engineering </th>{" "}
                            <td>Aeronautical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Chemical Engineering </td>{" "}
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Civil Engineering</td>{" "}
                            <td>Bachelor of Engineering</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Structural Design</td>{" "}
                            <td>Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Computer Science and Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Computer Science and Engineering </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Software Engineering</td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical and Electronics Engineering</td>
                            <td> Bachelor of Engineering </td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td> Electrical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electrical Power System </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Power Electronics </td>
                            <td> Master of Technology</td>{" "}
                          </tr>
                          <tr>
                            {" "}
                            <td>
                              Electronic & Communication Engineering{" "}
                            </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Digital Communication </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>VLSI </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Electronics & Instrumentation Engineering</td>
                            <td> Bachelor of Engineering</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Information Technology </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mechanical Engineering </td>{" "}
                            <td>Bachelor of Engineering </td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Industrial Design </td>{" "}
                            <td> Master of Technology </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Thermal Engineering </td>
                            <td> Master of Technology</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Mining Engineering </td>
                            <td> Bachelor of Engineering</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">Faculty of Pharmacy</th>{" "}
                            <td>Pharmacy </td> <td> Bachelor of Pharmacy</td>{" "}
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmacology</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>Pharmaceutics</td>
                            <td> Master of Pharmacy</td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">
                              School of Management Studies
                            </th>{" "}
                            <td rowSpan="2">Management </td> <td>BBA</td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>MBA</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of law</th>
                            <td> Law</td>
                            <td> Law</td>{" "}
                            <td>
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="10">Faculty of Education</th>{" "}
                            <td rowSpan="2">Arts </td>{" "}
                            <td>Bachelor of Arts </td>{" "}
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <td> Master of Arts</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>
                            <td>Bachelor of Physical Education</td>
                            <td>Click Here</td>
                          </tr>
                          <tr>
                            <td rowSpan="2">Science</td>{" "}
                            <td>Bachelor of Science</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Science</td>
                          <tr>
                            <td rowSpan="2">Commerce</td>{" "}
                            <td>Bachelor of Commerce</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> Master of Commerce</td>
                          <tr>
                            <td rowSpan="2">Education</td> <td>B.A. Ed</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>{" "}
                          </tr>
                          <td> B.Ed</td>
                          <tr>
                            {" "}
                            <td> Physical Education</td>{" "}
                            <td> Bachelor of Physical Education</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="3">
                              School Computer Application
                            </th>{" "}
                            <td rowSpan="3">Computer Application</td>
                            <td>P.G.D.C.A</td>
                            <td rowSpan="3">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>B.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <td>M.C.A</td>
                          </tr>
                          <tr>
                            {" "}
                            <th> School Of Hotel Mangement</th>{" "}
                            <td> HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Bachelor of HOTEL MANAGEMENT AND CATERING</td>{" "}
                            <td> Click her</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Homeopathy</th> <td> Homeopathy</td>
                            <td> B.H.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Agriculture</th> <td> Agriculture</td>{" "}
                            <td> Bachelor of Agriculture</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Ayurveda & Siddha Studies</th>{" "}
                            <td> Ayurveda</td> <td> B.A.M.S</td>
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <th rowSpan="2">School of Nursing</th>{" "}
                            <td rowSpan="2">Nursing</td>{" "}
                            <td>Bachelor of Nursing</td>
                            <td rowSpan="2">
                              <Link target='_blank' to="#">Click here</Link>
                            </td>
                          </tr>
                          <tr>
                            {" "}
                            <td>P.B.Sc (Nursing)</td>
                          </tr>
                          <tr>
                            {" "}
                            <th>School of Paramedical</th> <td> Paramedical</td>
                            <td> B.P.T</td>{" "}
                            <td>
                              {" "}
                              <Link target='_blank' to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/.pdf">
                                Click here
                              </Link>
                            </td>
                          </tr>



                          <br></br>
                        </tbody>




                        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                        <table className="resizable-table table table-hover" style={{ width: '750px' }}>

                          <tbody>
                            <tr>
                              <td>1.3.2</td>

                              <td>Number of value-added courses for imparting transferable and life skills offered during last five years. </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>Click here </Link>
                              </td>

                            </tr>


                            <tr>
                              <td>1.3.4</td>
                              <td>Percentage of students undertaking field projects / research projects / internships (Data for the latest completed academic year). </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/1.3.4 COMBINED PDF.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>
                              <td> 1.4.1

                                & 1.4.2</td>
                              <td> Structured feedback for design and review of syllabus – semester-wise / year-wise is received from 1) Students, 2) Teachers, 3) Employers, 4) Alumni 5) Professional and Action taken Report </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/feedback reports  Combine.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>2.2.1 </td>                <td></td>

                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/combined 2.2.1 final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>2.3.1 </td>                <td></td>

                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/combined 2.3.1 final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>2.3.2 </td>                <td></td>

                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/combined 2.3.2 final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>2.3.3 </td>
                              <td></td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/combined 2.3.3 final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>3.1.1 </td>
                              <td>Policies and Regulations for Conducting Research

                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1 research Policies and Regulations.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>3.1.2</td>

                              <td>The institution provides seed money to its teachers for research (average per year INR in Lakhs)     </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.2 additional uploading.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>3.1.3
                              </td><td>


                                The number of teachers who received national / international fellowship / financial support by various agencies for advanced studies / research year-wise during the last five years </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.1.3 additional uploading final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>3.2.1
                              </td><td>
                                Extramural funding for Research </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/3.2.1.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td> 3.4.3</td>
                              <td>

                              </td><td>

                              </td>

                            </tr>

                            <tr>

                              <td>3.4.4	</td><td>

                                Number  of Ph.D’s awarded per teacher during the last five years           </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>
                                3.4.5 </td>
                              <td>

                              </td>
                              <td>

                              </td>
                            </tr>

                            <tr>

                              <td> 3.3.2</td>
                              <td colSpan='2'>
                                Number of workshops/seminars conducted on Research methodology, Intellectual Property Rights (IPR),entrepreneurship, skill development during the last five years
                                <table style={{ width: '600px' }}><tbody>
                                  <tr> <th>S. No.</th>
                                    <th>	Report</th></tr>


                                  <tr><td>1</td><td><Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/MODERN.pdf'>	Modern Trend in Pharmaceutical Education and Research </Link></td></tr>
                                  <tr><td>2</td><td> <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>Solar and Other Renewable Energy Sources </Link> </td></tr>
                                  <tr><td>3</td><td> <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>International Conference on  Advance Studies in Engineering and Sciences” (ICASES-17) </Link></td></tr>
                                  <tr><td>4</td><td>	 <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/Activity_Report_IPR_17022023_1145.pdf'>Activity Report IPR  </Link></td></tr>
                                  <tr><td>5</td><td> <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/Commonly neglected cleaning areas in Hotel Guest Room”.pdf'>Commonly neglected cleaning areas in Hotel Guest Room </Link></td></tr>


                                </tbody>  </table></td></tr>
                            <tr>

                              <td>3.5.1	</td><td>

                                Guidelines For Consultancy Services </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/Guidelines_on_Consultancy_and_Testing_Projects final.pdf'>Click here </Link>
                              </td>

                            </tr>

                            <tr>

                              <td>	3.6.4	</td><td colSpan='2'>
                                Average percentage of students participating in extension activities  during the last five year
                                <table style={{ width: '600px' }}>
                                  <tbody>
                                    <tr>
                                      <th>S. No.	</th><th>Report</th>
                                    </tr>
                                    <tr><td>1</td><td>	  <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/BLOOD_DONATION_(4)_(1)_17022023_1148.pdf'> Activity Report : Organizing Blood Donation Camp </Link></td></tr>
                                    <tr><td>2</td><td> <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/Commonly Neglected Cleaning Areas in Hotel Guest Rooms.pdf'>	Commonly neglected cleaning areas in Hotel Guest Room </Link></td></tr>
                                  </tbody>
                                </table>

                              </td>



                            </tr>

                            <tr>

                              <td> 	4.1.1	</td><td>
                                Physical Facilites<br></br>

                                Facilities for Teaching - Learning   </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/sssutms.pdf'>Click here </Link>
                              </td>

                            </tr>


                            <tr>

                              <td>	4.1.2

                              </td> <td>Facilities for Cultural Activities, Yoga, Games and Sports
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>Click here </Link>
                              </td>

                            </tr>




                            <tr>

                              <td> 	4.1.3

                              </td>  <td>General Campus Facilities
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/4.1.3.pdf'>Click here </Link>
                              </td>

                            </tr>


                            <tr>

                              <td> 4.1.4	</td><td>

                                CA Certificate & Audited Income and Expenditure Statement
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/.pdf'>Click here </Link>
                              </td>

                            </tr><tr>

                              <td> 	4.2.1

                              </td><td>Library as a Learning Resource
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/LIBRARY AS A LEARNING RESOURESE.pdf'>Click here </Link>
                              </td>

                            </tr><tr>

                              <td> 	4.3.1
                              </td>
                              <td>                Percentage of classrooms and seminar halls with ICT - enabled facilities  such as  LCD, smart board, Wi-Fi/LAN, audio video recording facilities .(Data only for the latest completed academic year)
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/Criteria 4.3.1.pdf'>Click here </Link>
                              </td>

                            </tr><tr>

                              <td> 5.2.1	</td><td>

                                Average percentage of students qualifying in State / National / International level examinations during the last five years (Eg. IIT/JAM/CLAT/NET/SLET/GATE/GMAT/CAT/GRE/TOEFL/CIVIL SERVICES/STATE GOVERNMENT Examination Etc.)
                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/5.2.1 Score Card.pdf'>Click here </Link>
                              </td>

                            </tr><tr>

                              <td> 	5.2.2		</td><td><Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/5.2.2.pdf'>Average percentage of placement of outgoing students during the last five years (15) (2017-2021) </Link>
                                <hr></hr><Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/5.2.2 offerlatter .pdf'> Offer letter  (2017-2021)  </Link> </td>
                              <td>

                              </td>

                            </tr><tr>

                              <td> 5.2.3


                              </td><td>Percentage of recently graduated students who have progressed to higher education (previous graduating batch) (15) (2017-21)

                              </td>
                              <td>
                                <Link target='_blank' to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/IQAC/5.2.3.pdf'>Click here </Link>
                              </td>

                            </tr>

                          </tbody>
                        </table>

                      </table>
                    </td>
                  </tr>
                </tbody>{" "}
              </table>
            </div>{" "}
          </div>{" "}
          <div className="col-lg-3">
            {" "}
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Academic
              </h5>
              <br></br>{" "}
              <li className="lli">
                <Link  to="/phd-page">
                  <i class="fa fa-adjust" aria-hidden="true"></i>PHD
                </Link>
              </li>
              <li className="lli">
                <Link to="/academic-calender">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Calender
                </Link>
              </li>
             
              <li className="lli">
                <Link to="/constituent-units">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Constituent
                  Units
                </Link>
              </li>
              <li className="lli">
                <Link  to="/Hei-handbook">
                  <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook{" "}
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link  to="/iqac_cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link  to="/mandatory_disclosures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory
                  Disclosures{" "}
                </Link>
              </li>{" "}
            </ul>
          </div>
        </div>{" "}
      </div>{" "}
      <Footerpage />
    </>
  );
}
export default IQAC;

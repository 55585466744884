import React, { useState } from "react";
import axios from "axios";
import logo from "../../images/sssutms.jpg";
import jsPDF from "jspdf";
import AdminDashboard from "../HodDashboard";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  Paper,
  MenuItem,
} from "@mui/material";

function Provisional_Pay_Search() {
  const [enteredValue, setEnteredValue] = useState("");
  const [studentData, setStudentData] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const Associate = userData.Associated_branch;
  const courseName = Associate.map(item => item.courseName)


  const [formData, setFormData] = useState({
    reciept_No: "",
    paymentMode: "",
    Amount: "",
    Remark: "Student Admitted through Provisional Amount",
    payment_date: "",
    status: "",
    studentId: "",
    name: "",
    randomId: "",
    email: "",
    mobile: "",
    fathersname: "",
    mothersname: "",
    courseName: "",
    courseType: "",
    courseBranch: "",
    session: "",
  });

  const handleSearch = async () => {
    if (!enteredValue) {
      alert("Please enter a Registration Number.");
      return;
    }

    try {
      const api = `https://sssutms.ac.in/apitest/v1/hod/search?randomId=${enteredValue}&courseName=${courseName}`;
      const response = await axios.get(api);

      const student = response.data;

      if (student) {
        setStudentData(student);
        setFormData((prev) => ({
          ...prev,
          studentId: student._id,
          name: student.name,
          randomId: student.randomId,
          email: student.email,
          mobile: student.mobile,
          fathersname: student.fathersname,
          mothersname: student.mothersname,
          courseName: student.courseName,
          courseType: student.courseType,
          courseBranch: student.courseBranch,
          session: student.session,
        }));
      } else {
        alert("No student found with this ID.");
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        alert("Student is not approved.");
      } else {
        alert("Student not found or some other error occurred.");
      }
      console.error("API Error:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "reciept_No" || name === "Amount") {
      if (/^\d*$/.test(value)) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const generatePDF = (student, payment) => {
    const doc = new jsPDF();
    const receiptTime = new Date().toLocaleTimeString("en-IN", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });

    doc.setFontSize(60);
    doc.setTextColor(245, 245, 245);
    doc.text("SSSUTMS", 45, 130, { angle: 45 });
    doc.addImage(logo, "JPEG", 15, 10, 25, 25);

    doc.setFontSize(16);
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
      align: "center",
    });
    doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });

    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100);
    doc.setFont("helvetica", "normal");
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      28,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
      105,
      33,
      { align: "center" }
    );
    doc.text(`Academic Session ${student.session || "2025-2026"} `, 105, 38, {
      align: "center",
    });
    doc.setFillColor(0, 48, 135);
    doc.rect(0, 45, 210, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("PROVISIONAL E-PRAVESH FEE RECEIPT", 105, 51, { align: "center" });

    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Receipt Date: ${student.payment_date}`, 15, 65);
    doc.text(`Receipt Time: ${receiptTime}`, 150, 65);

    const startY = 80;
    const lineHeight = 7;
    let currentY = startY;

    const drawRow = (label, value) => {
      doc.setTextColor(100, 100, 100);
      doc.text(label, 25, currentY);
      doc.setTextColor(0, 0, 0);
      doc.text(": ", 70, currentY);
      doc.text(value, 75, currentY);
      currentY += lineHeight;
    };

    doc.setFillColor(240, 240, 240);
    doc.rect(15, 70, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DETAILS", 25, 75);
    currentY = 85;

    doc.setFont("helvetica", "normal");
    drawRow("Name", student.name.toUpperCase());
    drawRow("Father's Name", student.fathersname);
    drawRow("Email", student.email);
    drawRow("Mobile", student.mobile);
    drawRow("Course Type", student.courseType || "N/A");
    drawRow("Course Name", student.courseName);
    drawRow("Course Branch", student.courseBranch);
    drawRow("Date & Time", payment.payment_date);

    currentY += 5;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, currentY - 5, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT DETAILS", 25, currentY);
    currentY += 10;

    doc.setFont("helvetica", "normal");
    drawRow("Receipt No.", payment.reciept_No);
    drawRow("Payment Mode", payment.paymentMode);
    drawRow("Remark", payment.Remark);

    currentY += 5;
    doc.setFillColor(0, 48, 135);
    doc.rect(15, currentY - 5, 180, 12, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text("Amount Paid:", 25, currentY + 3);
    doc.text(`${payment.Amount}/-`, 160, currentY + 3);

    doc.setTextColor(100, 100, 100);
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");


    doc.save(`${student.name}_EPravesh_Fee_Receipt.pdf`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/provisional-payment",
        formData
      );

      const payment = response.data.payment;

      // Generate and download the PDF immediately after saving payment
      generatePDF(formData, payment);
      alert("Payment saved and receipt downloaded successfully!");
      // Optionally, reset the form or redirect somewhere else
      setFormData({
        reciept_No: "",
        paymentMode: "",
        Amount: "",
        Remark: "Student Admitted through Provisional Amount",
        payment_date: "",
        status: "",
        studentId: "",
        name: "",
        randomId: "",
        email: "",
        mobile: "",
        fathersname: "",
        mothersname: "",
        courseName: "",
        courseType: "",
        courseBranch: "",
        session: "",
      });
      setStudentData(null);
      setEnteredValue("");
    } catch (error) {
      console.error(error);
      alert("Failed to save payment information");
    }
  };

  return (
    <>
      <AdminDashboard />

      <Container maxWidth="lg" sx={{ mt: 9, ml: 12 }}>
        <Paper elevation={3} sx={{ p: 3, width: "1100px" }}>
          <Typography
            variant="h5"
            align="center"
            sx={{ color: "#16404D", mb: 3 }}
          >
            Provisional Payment Verification
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "#264653" }}
            >
              Enter Registration Number *
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                fullWidth
                value={enteredValue}
                onChange={(e) => setEnteredValue(e.target.value)}
                placeholder="Enter Registration Number"
                variant="outlined"
              />
              <Button
                variant="contained"
                onClick={handleSearch}
                sx={{
                  height: "56px",
                  width: "100px",
                  backgroundColor: "#004e92",
                  "&:hover": { backgroundColor: "#003b73" },
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Paper>

        {studentData && (
          <Paper elevation={3} sx={{ mt: 2, p: 3, height: "auto" }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Row 1 */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Session"
                    name="session"
                    value={formData.session}
                    onChange={handleChange}
                    placeholder="2024-2025"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Receipt No"
                    name="reciept_No"
                    value={formData.reciept_No}
                    onChange={handleChange}
                    placeholder="Enter Receipt No"
                    required
                    variant="outlined"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    select
                    label="Payment Mode"
                    name="paymentMode"
                    value={formData.paymentMode}
                    onChange={handleChange}
                    required
                    variant="outlined"
                  >
                    <MenuItem value="UPI">UPI</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Amount"
                    name="Amount"
                    value={formData.Amount}
                    onChange={handleChange}
                    placeholder="Enter Amount"
                    required
                    variant="outlined"
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                </Grid>

                {/* Row 2 */}
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Random Id"
                    name="randomId"
                    value={formData.randomId}
                    onChange={handleChange}
                    placeholder="Enter Random Id"
                    disabled
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter Name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Fathers Name"
                    name="fathersname"
                    value={formData.fathersname}
                    onChange={handleChange}
                    placeholder="Enter Fathers Name"
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Payment Date"
                    name="payment_date"
                    value={formData.payment_date}
                    onChange={handleChange}
                    placeholder="12-05-2024 01:23 pm"
                    required
                    variant="outlined"
                  />
                </Grid>

                {/* Row 3 */}
                {["courseType", "courseName", "courseBranch"].map((key) => (
                  <Grid item xs={12} sm={4} key={key}>
                    <TextField
                      fullWidth
                      label={key.replace(/([A-Z])/g, " $1")}
                      name={key}
                      value={formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                      disabled
                      required
                      variant="outlined"
                    />
                  </Grid>
                ))}

                {/* Row 4 */}
                {["email", "remark"].map((key) => (
                  <Grid item xs={12} sm={6} key={key}>
                    <TextField
                      fullWidth
                      label={key.replace(/([A-Z])/g, " $1")}
                      name={key}
                      value={key === "remark" ? formData.Remark : formData[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${key.replace(/([A-Z])/g, " $1")}`}
                      disabled={key === "remark"}
                      required
                      variant="outlined"
                    />
                  </Grid>
                ))}
              </Grid>

              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  backgroundColor: "#004e92",
                  "&:hover": { backgroundColor: "#003b73" },
                }}
              >
                Save Payment
              </Button>
            </form>
          </Paper>
        )}
      </Container>
    </>
  );
}

export default Provisional_Pay_Search;

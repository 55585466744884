import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../../images/sssutms.jpg";
import jsPDF from "jspdf";
import HodDashboard from "../HodDashboard";


const Provisional_Receipt = () => {

  const location = useLocation();
  const { student, payment } = location.state || {};
  
  const generatePDF = () => {
    const doc = new jsPDF();
    const receiptTime = new Date().toLocaleTimeString("en-IN", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    });
    // Add watermark
    doc.setFontSize(60);
    doc.setTextColor(245, 245, 245);
    doc.text("SSSUTMS", 45, 130, { angle: 45 });
    doc.addImage(
      logo,
      "JPEG",
      15,
      10,
      25,
      25
    );
   

    // University name and details
    doc.setFontSize(16);
    doc.setTextColor(0, 48, 135); // Navy blue for university name
    doc.setFont("helvetica", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
      align: "center",
    });
    doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });

    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100); // Gray for secondary text
    doc.setFont("helvetica", "normal");
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      28,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
      105,
      33,
      { align: "center" }
    );

    // Receipt title
    doc.setFillColor(0, 48, 135);
    doc.rect(0, 45, 210, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("PROVISIONAL E-PRAVESH FEE RECEIPT", 105, 51, { align: "center" });

    // Receipt number and date
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Receipt Date: ${student.payment_date}`, 15, 65);
    doc.text(`Receipt Time: ${receiptTime}`, 150, 65);

    // Main content
    const startY = 80;
    const lineHeight = 7;
    let currentY = startY;

    // Helper function for drawing rows
    const drawRow = (label, value) => {
      doc.setTextColor(100, 100, 100);
      doc.text(label, 25, currentY);
      doc.setTextColor(0, 0, 0);
      doc.text(": ", 70, currentY);
      doc.text(value, 75, currentY);
      currentY += lineHeight;
    };

    // Student details section
    doc.setFillColor(240, 240, 240);
    doc.rect(15, 70, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DETAILS", 25, 75);
    currentY = 85;

    doc.setFont("helvetica", "normal");
    drawRow("Name", student.name.toUpperCase());
    drawRow("Father's Name",student.fathersname);
    drawRow("Email", student.email);
    drawRow("Mobile", student.mobile);
    drawRow("Course Type", student.courseType || "N/A");
    drawRow("Course Name", student.courseName);
    drawRow("Course Branch", student.courseBranch);
    drawRow("Date & Time",payment.payment_date);

    // Payment details section
    currentY += 5;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, currentY - 5, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT DETAILS", 25, currentY);
    currentY += 10;

    doc.setFont("helvetica", "normal");
    drawRow("Receipt No.", payment.reciept_No);
    drawRow("Payment Mode", payment.paymentMode);
    drawRow("Remark",  payment.Remark);
    

    // Amount section with box
    currentY += 5;
    doc.setFillColor(0, 48, 135);
    doc.rect(15, currentY - 5, 180, 12, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text("Amount Paid:", 25, currentY + 3);
    doc.text(`${payment.Amount}/-`, 160, currentY + 3);

    // Footer
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
 

    // Save the PDF
    doc.save(`${student.name}_EPravesh_Fee_Receipt.pdf`);
  };

  return (
    <>
      <style>
        {`

.receipt-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top:100px;
    text-align: center;
  }
  
  .receipt-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .download-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  
`}
      </style>

      <HodDashboard />
      <div className="receipt-container">
        <h2 className="receipt-title">Payment Successful</h2>
        <button onClick={generatePDF} className="download-button">
          Download Receipt
        </button>
      </div>
    </>
  );
};

export default Provisional_Receipt;

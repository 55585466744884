import React, { useState, useEffect } from "react";
import Website from "./Website";
import b from "../images/sssutms.jpg";
import { Link } from "react-router-dom";

function MainPage() {
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [notices, setNotices] = useState([]);
  const [examTimeTables, setExamTimeTables] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Static data as fallback
  const staticNotices = [
    {
      id: 1,
      title: "Examination Form Notification - II MAY-JUNE 2025",
      url: "https://cdn.sssutms.ac.in/media/static/notification/exam_notification_may_25.pdf",
      weight: 800,
    },
    {
      id: 2,
      title: "Examination Form Notification B.Sc. (Nursing) JUNE 2025",
      url: "https://cdn.sssutms.ac.in/media/static/notification/exam_notification_nursing_june25.pdf",
      weight: 800,
    },
    {
      id: 3,
      title: "ExamForm Notification B.A.M.S. 1st Prof. March-2025",
      url: "https://cdn.sssutms.ac.in/media/static/notification/Examination_notification.pdf",
      weight: 700,
    },
    {
      id: 4,
      title: "M.TECH (Revised) Exam Time Table Notification 2024",
      url: "https://cdn.sssutms.ac.in/media/static/notification/NOTIFICATION.pdf",
      weight: 700,
    },
    {
      id: 5,
      title: "Notification For RDC",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/cvbnm.pdf",
      weight: 700,
    },
    {
      id: 6,
      title: "EPravesh Admission (2025-2026)",
      url: "https://sssutms.ac.in/Epravesh/Student/Register",
      weight: 700,
    },
    {
      id: 7,
      title: "Admission Enquiry",
      url: "https://sssutms.ac.in/admission/enquiry",
      weight: 700,
    },
    {
      id: 8,
      title: "Enrollment Form 2024",
      url: "https://sssutms.ac.in/erp/studentlogin",
      weight: 700,
    },
    {
      id: 9,
      title: "Alumni Form 2024",
      url: "https://sssutms.ac.in/alumini-form",
      weight: 700,
    },
  ];

  const staticExamTimeTables = [
    {
      id: 1,
      title:
        "Diploma_Pharmacy Exam Time Table I / II YEAR April - 2025 (Regular)",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Exam_Notification/Diploma_Pharmacy_I___II_YEAR_April_-_2025.pdf",
      weight: 700,
    },
    {
      id: 2,
      title: "BPES Exam Time Table I / II / III YEAR April - 2025 (Regular)",
      url: "https://cdn.sssutms.ac.in/media/static/PDF/Exam_Notification/BPES_I-III_YEAR_April_-2025.pdf",
      weight: 700,
    },
    {
      id: 3,
      title: "B.A.M.S. Exam Time Table [Regular Exam - 1st Professional]",
      url: "https://cdn.sssutms.ac.in/media/static/notification/TimeTable/BAMS_Time_Table.pdf",
      weight: 700,
    },
    {
      id: 4,
      title: "B.Sc.(Hons.) Agriculture Exam Time Table I Semester [Regular/Ex]",
      url: "https://cdn.sssutms.ac.in/media/static/notification/TimeTable/AG_I_SEM_DEC_2024.pdf",
      weight: 700,
    },
  ];

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Replace with your actual API endpoints
        const noticeResponse = await fetch("https://api.sssutms.ac.in/notices");
        const timetableResponse = await fetch(
          "https://api.sssutms.ac.in/exam-timetables"
        );

        if (noticeResponse.ok) {
          const noticeData = await noticeResponse.json();
          setNotices(noticeData);
        } else {
          setNotices(staticNotices); // Fallback to static data
        }

        if (timetableResponse.ok) {
          const timetableData = await timetableResponse.json();
          setExamTimeTables(timetableData);
        } else {
          setExamTimeTables(staticExamTimeTables); // Fallback to static data
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Use static data as fallback
        setNotices(staticNotices);
        setExamTimeTables(staticExamTimeTables);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  // Render list items dynamically
  const renderListItems = (items) => {
    return items.map((item) => (
      <div className="noti" key={item.id}>
        <li>
          <Link
            to={item.url}
            target="_blank"
            style={{ fontWeight: item.weight }}
          >
            <h6 className={item.title.includes("B.A.M.S.") ? "hh61" : "hh6"}>
              {item.title}
            </h6>
            <span className="sticker">Open</span>
          </Link>
        </li>
      </div>
    ));
  };

  return (
    <>
      <style>
        {`


        .tabs-container {
            display: flex;
        
             gap:1px;
            margin-top:10px;
          }

          .tab {
            padding: 10px 20px;
            cursor: pointer;
            font-weight: bold;
            font-size: 14px;
            border: 1px solid #ddd;
            border-bottom: none;
            background-color: #f9f9f9;
            color: #444;
            transition: all 0.3s ease;
          }

          .tab:hover {
            background-color: #ddd;
          }

          .tab.active {
            background-color: #DC3545;
            color: white;
          }
        .addclass {
        height: 300px;
        width: 300px;
       }
      .spann{
       color: #C80036;
       font-weight: 700;
       width:500px;
       font-size: 21px;
       font-family:"Poppins",Sans-serif;
       }


      .hh5{
      color:#433878;
      margin-left:-30px;
      font-weight:700;
      text-align:left;
      font-family: "Poppins", sans-serif;
      font-size:20px;
      margin-top:-15px;
     }

    .popup-container {
      position: fixed;
      // top: -20px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      margin-top:40px;
    }

    .popup-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      position: relative;
      width: 100%;
      height: 500px;
      max-width: 600px;
      text-align: center;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    img {
      max-width:100%;
      object-fit: cover;
    }

    // .d-flex {
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    // }

    .main-content {
      padding: 20px;
    }


    .sticker {
      background-color: orange; /* Sticker background color */
      color: white;          /* Text color */
      font-size: 10px;       /* Sticker text size */
      padding: 2px 6px;      /* Padding for the sticker */
      border-radius: 5px;    /* Rounded corners */
      position: absolute;
      top: -30px;            /* Adjust the position relative to the text */
      right: -260px;          /* Adjust the sticker position */



      cursor: pointer;
    font-weight: bold;
    display: inline-block;
    position: relative;
    animation: blink 1s infinite;
    transition: transform 0.3s ease;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      padding: 3rem 0;
    }

    .wrapper {
      padding: 2rem;
      height: 300px;
      margin-top:-30px;
      width: 850px;
      border-radius: 15px;
    }

    h1 {
      font-size: 1.1rem;
      font-family: sans-serif;
    }

    .noti {
      background-color: #D8DFEA;
      height: 40px;
      padding: 5px;
      width: 550px;
      margin-left: -20px;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .sessions {
      margin-top: 2rem;
      border-radius: 12px;
      position: relative;
    }

    .ooo {
      position: relative;
      list-style: none;
      padding: 0;
      max-height: 300px;
       overflow-y: scroll;
        padding: 10px;
         scrollbar-color: #d5bdaf;
          scrollbar-width: thin;
           width: 72%;
            margin-left: -35px;

    }
      
    .hh6{
margin-left:5px;
font-size:14px;
 font-family:"Poppins",Sans-serif;}


     .hh61{
margin-left:5px;
font-size:14px;
 font-family:"Poppins",Sans-serif;}


    .ooo a {
      position: relative;
      display: block;
      padding: .4em 2em; /* Adjust padding for full width */
      color: #444;
      text-decoration: none;
      border-radius: .3em;
      left: -10px;
      transition: background 0.3s; /* Smooth transition */
    }

    // .ooo a:hover {
    //   background: #D8DFEA; /* Keep the same background on hover */
    // }

    .arrow {
      position: absolute;
      left: 10px; /* Adjust based on padding */
      color: #4B79C2; /* Arrow color */
      font-size: 16px; /* Size of the arrow */
      line-height: 1; /* Centering the arrow vertically */
      // transition: transform 0.3s ease; /* Transition effect for hover */
      margin-right: 10px; /* Add space between arrow and text */
    }

    .ooo a:hover .arrow {
      transform: translateX(5px); /* Move the arrow on hover */
    }

    .lihai {
      padding-bottom: 1.5rem;
      position: relative;
      padding-left: 30px; /* Increase padding for the arrow */
      margin-left: 10px;
    }
.nav-bar{
margin-top:-10px;}
.image{
margin-top:-3px;}


    @media screen and (max-width: 480px) {
      .noti {
        background-color: #D8DFEA;
        height: 43px;
        padding: 5px;
        width: 350px;
        margin-left: -40px;
        border-radius: 5px;
        margin-bottom: 10px;
      }


    .ooo{
    max-height: 380px;
     overflow-y: scroll;
      padding: 5px;
      width: 55%;
      margin-left: -37px;
       overflow-y: scroll;
        scrollbar-width: thin;
         scrollbar-color: #d5bdaf;
      }
.popup-container
{
margin-left:10px;
}
      .image{
      height:40px;
      width:40px;
      margin-top:-3px;

      }

.spann{
 color: #C80036;
  font-weight: 700;
  font-size: 12px;
  width:250px
  }
.hh6{
margin-left:20px}
.sticker{
margin-left:-250px;
}
.hh6{
margin-left:5px;}



.hh61{
margin-left:28px;}
      p {
        font-size: 0.9rem;
      }
    }
  `}
      </style>

      <div>
        {isPopupVisible && (
          <div className="popup-container">
            <div className="popup-content">
              <span className="close-btn" onClick={handleClosePopup}>
                ×
              </span>
              <div className="nav-bar d-flex">
                <img src={b} className="image" alt="Logo" />
                <span className="spann">
                  SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
                </span>
              </div>
              <hr style={{ color: "black", height: "2px", marginTop: "2px" }} />
              <div className="tabs-container">
                <div
                  className={`tab ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => handleTabClick(0)}
                >
                  Notice
                </div>
                <div
                  className={`tab ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => handleTabClick(1)}
                >
                  Exam Time Table DEC-2024
                </div>
              </div>
              <div className="wrapper">
                {isLoading ? (
                  <div className="loading">Loading...</div>
                ) : (
                  <div>
                    {activeTab === 1 && (
                      <ol className="ooo">{renderListItems(examTimeTables)}</ol>
                    )}
                    {activeTab === 0 && (
                      <ol className="ooo">{renderListItems(notices)}</ol>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div>
          <Website />
        </div>
      </div>
    </>
  );
}

export default MainPage;

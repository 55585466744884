
import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Paper,
  Avatar,
  useTheme,
  useMediaQuery,
  Stack,
  Grow,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import logo from "../../AdminComponent/12.png";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import jsPDF from "jspdf";

function E_Success() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderId = queryParams.get("orderId") || "ORD12345678";
  const transactionTime = new Date(queryParams.get("TxDate") || Date.now());
  const bankTransactionId = queryParams.get("BankTxnId") || "BANKTXN123456";
  const Amount = queryParams.get("Transamount") || "5000";
  const paymentMode = queryParams.get("paymentMode") || "UPI";
  const TxnID = queryParams.get("txnId") || "TXN987654321";
  const Session = queryParams.get("admissonSession")
  
  const Name = queryParams.get("Name") || "John Doe";
  const FName = queryParams.get("FName") || "Robert Doe";
  const email = queryParams.get("email") || "john.doe@example.com";
  const mobile = queryParams.get("mobile") || "9876543210";
  const courseName = queryParams.get("courseName") || "B.Tech Computer Science";
  const courseType = queryParams.get("courseType");
  const courseBranch =
    queryParams.get("courseBranch") || "Software Engineering";

  const formattedDate = transactionTime.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = transactionTime.toLocaleTimeString("en-IN", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
  });

  const generatePDF = () => {
    const doc = new jsPDF();

    // Add watermark
    doc.setFontSize(60);
    doc.setTextColor(245, 245, 245);
    doc.text("SSSUTMS", 45, 130, { angle: 45 });

    // Header with logo and university details
    doc.addImage(
      logo,
      "JPEG",
      10,
      10,
      20,
      20
    );
    doc.setFontSize(16);
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
      align: "center",
    });
    doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });

    doc.setFontSize(10);
    doc.setTextColor(100, 100, 100); // Gray for secondary text
    doc.setFont("helvetica", "normal");
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      28,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
      105,
      33,
      { align: "center" }
    );
    doc.text(
      `Academic Session ${Session || "2025-2026"} `,
      105,
      38,
      { align: "center" }
    );

    // Receipt title
    doc.setFillColor(0, 48, 135);
    doc.rect(0, 45, 210, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text("E-PRAVESH FEE RECEIPT", 105, 51, { align: "center" });

    doc.setTextColor(100, 100, 100);
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text(`Receipt Date: ${formattedDate}`, 15, 65);
    doc.text(`Receipt Time: ${formattedTime}`, 150, 65);

    // Main content
    const startY = 80;
    const lineHeight = 7;
    let currentY = startY;

    // Helper function for drawing rows
    const drawRow = (label, value) => {
      doc.setTextColor(100, 100, 100);
      doc.text(label, 25, currentY);
      doc.setTextColor(0, 0, 0);
      doc.text(": ", 70, currentY);
      doc.text(value, 75, currentY);
      currentY += lineHeight;
    };

    // Student details section
    doc.setFillColor(240, 240, 240);
    doc.rect(15, 70, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("STUDENT DETAILS", 25, 75);
    currentY = 85;

    doc.setFont("helvetica", "normal");
    drawRow("Name", Name);
    drawRow("Father's Name", FName);
    drawRow("Email", email);
    drawRow("Mobile", mobile);
    drawRow("Course Type", courseType || "N/A");
    drawRow("Course Name", courseName);
    drawRow("Course Branch", courseBranch);

    // Payment details section
    currentY += 5;
    doc.setFillColor(240, 240, 240);
    doc.rect(15, currentY - 5, 180, 8, "F");
    doc.setTextColor(0, 48, 135);
    doc.setFont("helvetica", "bold");
    doc.text("PAYMENT DETAILS", 25, currentY);
    currentY += 10;

    doc.setFont("helvetica", "normal");
    drawRow("Transaction ID", TxnID);
    drawRow("Order ID", orderId);
    drawRow("Payment Mode", paymentMode);
    drawRow("Bank Transaction ID", bankTransactionId);

    // Amount section with box
    currentY += 5;
    doc.setFillColor(0, 48, 135);
    doc.rect(15, currentY - 5, 180, 12, "F");
    doc.setTextColor(255, 255, 255);
    doc.setFont("helvetica", "bold");
    doc.text("Amount Paid:", 25, currentY + 3);
    doc.text(`${Amount}/-`, 160, currentY + 3);

    // Footer
    doc.setTextColor(100, 100, 100);
    doc.setFontSize(8);
    doc.setFont("helvetica", "normal");
   

    // Save the PDF
    doc.save(`${Name}_EPravesh_Fee_Receipt.pdf`);
};

  return (
    <>
      <OnlyHeader />
      <Box sx={{ minHeight: "100vh", bgcolor: "#f5f5f5", pt: 10 }}>
        <Container maxWidth="md">
          <Paper elevation={3} sx={{ borderRadius: 2, overflow: "hidden" }}>
            <Box
              sx={{
                bgcolor: "#2258a7",
                p: 2,
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
              }}
            >
              <Grow
                in={true}
                timeout={500}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
              >
                <Avatar sx={{ bgcolor: "success.main", width: 56, height: 56 }}>
                  <CheckCircleIcon fontSize="large" />
                </Avatar>
              </Grow>
              <Box>
                <Typography variant="h5" fontWeight="bold">
                  Payment Successful!
                </Typography>
                <Typography variant="subtitle1">Thank you, {Name}!</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ alignItems: "center", justifyContent: "flex-end" }}
                  variant="h5"
                  color="success"
                  fontWeight="bold"
                >
                  ₹{Amount}
                </Typography>
              </Box>
            </Box>

            <CardContent sx={{ p: 3 }}>
              <Stack direction="row" spacing={2} justifyContent="center">
                <DateRangeIcon />
                <Typography variant="body1" color="text.secondary">
                  {formattedDate} {formattedTime}
                </Typography>
              </Stack>

              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Name
                      </Typography>
                      <Typography>{Name}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Father's Name
                      </Typography>
                      <Typography>{FName}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Email
                      </Typography>
                      <Typography>{email}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Contact
                      </Typography>
                      <Typography>{mobile}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Course Name
                      </Typography>
                      <Typography>{courseName}</Typography>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Transaction ID
                      </Typography>
                      <Typography fontWeight="500">{TxnID}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Order ID
                      </Typography>
                      <Typography fontWeight="500">{orderId}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Payment Mode
                      </Typography>
                      <Typography>{paymentMode}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Bank Transaction ID
                      </Typography>
                      <Typography>{bankTransactionId}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Course Branch
                      </Typography>
                      <Typography>{courseBranch}</Typography>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>

              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  onClick={generatePDF}
                  sx={{ backgroundColor: "#2258a7" }}
          
                  startIcon={<DownloadIcon />}
                >
                  Print Receipt
                </Button>
                <Link to="/student/erp/studentlogin" style={{ textDecoration: "none" }}>
                  <Button variant="contained" >
                    Close
                  </Button>
                </Link>
              </Stack>
            </CardContent>
          </Paper>
        </Container>
      </Box>
    </>
  );
}

export default E_Success;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TableRow,
  TablePagination,
  TableHead,
  TableContainer,
  TableBody,
  Table,
  Paper,
  CardContent,
  TableCell,
  Box,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";

import jsPDF from "jspdf";
import logo from "../../AdminComponent/12.png";


const Admission_Slip = ({
  session,
  courseType,
  course,
  branch,
  college,
  searchResult,
}) => {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [studentdata, setStudentData] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [newAdmissionDate, setAdmissionDate] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      if (searchResult && searchResult.length > 0) {
        setStudentData(searchResult);
      } else {
        const url = "https://sssutms.ac.in/apitest/admin/admissionSlipsearch";
        const requestData = { session, courseType, course, branch, college };

        const response = await axios.post(url, requestData);
        const data = response.data;

        if (!data || data.length === 0) {
          setDataNotFound(true);
        } else {
          setDataNotFound(false);
          setStudentData(data.students);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataNotFound(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [session, courseType, course, branch, college]);

  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata, newAdmissionDate]);

  const filterData = () => {
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.courseBranch.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const generatePDF = async (student) => {
    const pdf = new jsPDF();

    // Add header logo
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);

    // Add header text
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      110,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 110, 22, {
      align: "center",
    });
    pdf.text("Sehore (M.P.) Pin Code - 466001", 110, 28, { align: "center" });

    // Academic year
    pdf.setFont("helvetica", "bold");
    const nextYear = parseInt(student?.admissionSession) + 1;
    pdf.text(
      `Academic Year ${student?.admissionSession}`,
      115,
      34,
      { align: "center" }
    );

    // Title with a gray box
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(65, 38, 85, 10, "F");
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Admission Slip", 105, 45, { align: "center" });

    // Fields setup
    let y = 60; // Starting y position
    const lineSpacing = 8;
    const fieldValueStartX = 100;

    // Function to add fields with address wrapping logic
    const addField = (label, value, isMultiline = false) => {
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");
      pdf.text(`${label}`, 20, y);
      pdf.setFont("helvetica", "normal");

      if (isMultiline && value) {
        const splitValue = pdf.splitTextToSize(value, 80); // Wrap text to fit within 80 units
        splitValue.forEach((line) => {
          pdf.text(line, fieldValueStartX, y);
          y += lineSpacing; // Add spacing for each wrapped line
        });
      } else {
        const fieldValue = value ? String(value) : "Not Provided";
        pdf.text(fieldValue, fieldValueStartX, y);
        y += lineSpacing;
      }
    };


    const formatDOB1 = (dob) => {
      // If dob is not provided, return "Not Provided"
      if (!dob) return "Not Provided";
    
      // Check if dob is already in dd/mm/yyyy format using regex
      const isAlreadyFormatted = /^\d{2}\/\d{2}\/\d{4}$/.test(dob);
      if (isAlreadyFormatted) return dob;
    
      // Handle ISO format dates (like 2004-06-30T00:00:00.000+00:00)
      try {
        const date = new Date(dob);
        // Check if the date is valid
        if (isNaN(date.getTime())) return "Not Provided";
    
        // Convert to dd/mm/yyyy format with proper timezone handling
        return date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          timeZone: "UTC" 
        });
      } catch (error) {
        return "Not Provided";
      }
    };


















     const formattedDOB = formatDOB1(student?.dob) 

    // const formattedDOB = student?.dob
    //   ? new Date(student.dob).toLocaleDateString("en-GB")
    //   : "Not Provided";

    const admissionDate = student?.admissionDate || "Not Assigned";

    const formFields = [
      ["Enrollment Number", student?.enrollmentNumber || ""],
      ["Name of Candidate", student?.name || ""],
      ["Father's Name", student?.fathersname || ""],
      ["Mother's Name", student?.mothersname || ""],
      ["Gender", student?.gender || ""],
      ["Date of Birth", formattedDOB || ""],
      // ["Address", student?.address.address1  || "", true], 
      ["Address", `${student?.address?.address1 || ""} ${student?.address?.address2 || ""}`, true],
      ["Domicile", student?.domicile || ""],
      ["Category", student?.category || ""],
      ["Mode of Admission", student?.admissionMode || "Regular"],
      ["Email", student?.email || ""],
      ["Course Type", courseType || ""],
      ["Course Name", course || ""],
      ["Specialization", branch || ""],
      ["Institute", student?.assignedCollege || ""],
      ["Mobile Number", student?.mobile || ""],
      ["Date of Admission", admissionDate],
    ];

    formFields.forEach(([label, value, isMultiline]) =>
      addField(label, value, isMultiline)
    );

    // Student image
    const studentImage = student?.Documents?.applicantPhoto;
    if (studentImage) {
      pdf.addImage(studentImage, "JPEG", 162, 60, 30, 35);
    }

    // Notes section
    y += 10;
    pdf.setFont("helvetica", "normal");
    pdf.text(
      "- I have checked all the original documents and verified the eligibility of the candidate.",
      20,
      y
    );
    y += 6;
    pdf.text(
      "- If it found that candidate hide any information or provide any incorrect information at the time of admission,",
      20,
      y
    );
    y += 6;
    pdf.text(
      "University has right to cancel the admission at the stage.",
      20,
      y
    );

    // Signatures
    y += 30;
    const applicantSignature = student?.Documents?.applicantSignature;
    if (applicantSignature) {
      pdf.addImage(applicantSignature, "JPEG", 20, y - 5, 40, 15);
      pdf.text("Applicant's Signature", 20, y + 15);
    }

    const collegeName = `${student?.assignedCollege}`;
    const textStart = 130;
    pdf.text("Name & Signature With Seal", textStart, y);
    pdf.text("HOD/Principal/Dean", textStart, y + 7);

    if (collegeName.length > 45) {
      pdf.text(collegeName.substring(0, 45), textStart, y + 14);
      pdf.text(collegeName.substring(45), textStart, y + 21);
    } else {
      pdf.text(collegeName, textStart, y + 14);
    }

    // Save the PDF
    pdf.save(`${student?.name}_Admission_Slip.pdf`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  const handleDateInputChange = (e) => {
    setAdmissionDate(e.target.value);
  };

  const handleCheckboxChange = (studentId) => {
    const updatedSelectedStudentIds = selectedStudentIds.includes(studentId)
      ? selectedStudentIds.filter((id) => id !== studentId)
      : [...selectedStudentIds, studentId];

    setSelectedStudentIds(updatedSelectedStudentIds);
  };

  const handleClick = async () => {
    if (selectedStudentIds.length === 0) {
      swal({
        title: "Error",
        text: "Please select at least one student",
        icon: "warning",
        buttons: "OK",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/admin/admissionSlip",
        {
          newAdmissionDate: formatDate(newAdmissionDate),
          studentIds: selectedStudentIds,
        }
      );

      if (!studentdata?.admissionDate === "") {
        swal({
          title: "Date Already Assigned",
          text: `Date already assigned for these students`,
          icon: "info",
          buttons: "OK",
        });
      } else {
        swal({
          title: "Success",
          text: `Date assigned successfully`,
          icon: "success",
          buttons: "OK",
        });

        await fetchData();
        setSelectedStudentIds([]);
        setAdmissionDate("");
      }
    } catch (error) {
      console.error("Error updating admission date:", error);
      swal({
        title: "Error",
        text: "Failed to update admission date",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  /////////////////////////name sort ////////////////////////////
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };
  const getSortedAndSlicedData = () => {
    // Sort the data
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }

      return 0;
    });

    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "50px",
        }}
      >
        {dataNotFound ? (
          <div>
            <h2>Oops! No Student Available in this Course!!!!</h2>
          </div>
        ) : (
          <>
            <Box sx={{ width: "90%", marginTop: "20px" }}>
              {loading ? (
                <CircularProgress
                  color="success"
                  style={{ marginTop: "80px", marginLeft: "50%" }}
                />
              ) : (
                <CardContent>
                  <Paper sx={{ width: "980px", overflow: "auto" }}>
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ p: 2, width: "70%" }}>
                        <SearchIcon sx={{ mr: 1 }} />
                        <input
                          type="text"
                          placeholder="Search  by ID or Name"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </Box>
                      <Box sx={{ p: 2, width: "50%" }}>
                        <Button
                          variant="outlined"
                          disableElevation
                          onClick={handleClick}
                        >
                          UPDATE
                        </Button>
                      </Box>
                      <Box sx={{ p: 2, width: "50%", paddingLeft: "35%" }}>
                        <input
                          type="date"
                          placeholder="Admission Date"
                          value={newAdmissionDate}
                          onChange={handleDateInputChange}
                        />
                      </Box>
                    </Box>

                    <TableContainer
                      sx={{ maxHeight: "403px" }}
                      component={Paper}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        sx={{ minWidth: 700 }}
                      >
                        <TableHead>
                          <TableRow style={{ padding: "2px" }}>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 2,
                                left: 0,
                              }}
                            >
                              S.No.
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",

                                position: "sticky",
                                top: 0,
                                left: 60,
                                zIndex: 2,
                              }}
                            >
                              Enrollment Number
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",

                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                color: "white",
                              }}
                              // /////////////for sorting name//////////////////
                              onClick={() => handleSort("name")}
                            >
                              Random Id
                              {sortBy === "name" && (
                                <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                minWidth: "250px",
                                position: "sticky",
                                top: 0,
                                color: "white",
                                left: 180,
                                zIndex: 2, // Increased zIndex to ensure it stays above other columns
                              }}
                            >
                              Student Name
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                minWidth: "190px",
                              }}
                            >
                              Father's Name
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                minWidth: "260px",
                              }}
                            >
                              Course Branch
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                minWidth: "260px",
                              }}
                            >
                              Course Name
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                minWidth: "260px",
                              }}
                            >
                              College Name
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                backgroundColor: "#023047",
                                color: "white",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                minWidth: "120px",
                              }}
                            >
                              Download
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {getSortedAndSlicedData().map((student, index) => (
                            <TableRow key={index}>
                              <TableCell
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  left: 0,
                                  zIndex: 1,
                                  backgroundColor: "white",
                                  padding: "2px",
                                }}
                                align="left"
                                typeof="check"
                                onClick={() =>
                                  handleCheckboxChange(student._id)
                                }
                              >
                                <Checkbox />
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{
                                  position: "sticky",
                                  top: 0,
                                  left: 60,
                                  zIndex: 1,
                                  backgroundColor: "white",
                                  padding: "2px",
                                  minWidth: "120px",
                                }}
                              >
                                {student?.enrollmentNumber}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px", minWidth: "120px" }}
                              >
                                {student.randomId}
                              </TableCell>

                              <TableCell
                                align="left"
                                style={{
                                  color: "#6a040f",
                                  padding: "2px",
                                  fontWeight: 700,
                                  position: "sticky",
                                  top: 0,
                                  left: 180,
                                  zIndex: 1,
                                  backgroundColor: "white",
                                }}
                              >
                                {student.name}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px" }}
                              >
                                {student.fathersname}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px" }}
                              >
                                {student.courseBranch}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px" }}
                              >
                                {student.courseName}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px" }}
                              >
                                {student.assignedCollege}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "2px" }}
                              >
                                <Button
                                  variant="contained"
                                  color="success"
                                  onClick={() => generatePDF(student)}
                                  style={{ height: "30px", width: "100px" }}
                                >
                                  Download
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      rowsPerPageOptions={[5, 25, 100]}
                      component="div"
                      count={filteredData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </CardContent>
              )}

              <br />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default Admission_Slip;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import swal from "sweetalert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const validationSchema = yup.object({
  randomId: yup.string().required("Enter Id"),
  randomPassword: yup.string().required("Enter Password"),
});

export default function Signin() {
  useEffect(() => {
    const sessionData = sessionStorage.getItem("currentUser");
    if (sessionData) {
      sessionStorage.removeItem("currentUser");
    }
  }, []);
  const navigate = useNavigate();

  //////////////password/////////////////////////////
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setloading] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  ///////////////////////////////////////////////////////////

  const formik = useFormik({
    initialValues: {
      randomId: "",
      randomPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setloading(true);
        const response = await fetch("https://sssutms.ac.in/apitest/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          const data = await response.json();
          const user = data.UserResponse;
          sessionStorage.setItem("currentUser", JSON.stringify(user));

          if (user.StudentType === "EPravesh") {
            if (
              user.isRegistered === true &&
              user.StudentType === "EPravesh" &&
              user.isPaid === true &&
              user.isEnrolled === false
            ) {
              navigate("/studentalldetail");
            } else if (
              user.isRegistered === true &&
              user.StudentType === "EPravesh" &&
              user.isApproved === false
            ) {
              navigate("/epravesh/waiting");
            } else if (
              user.StudentType === "EPravesh" &&
              user.isRegistered === false
            ) {
              navigate("/Epravesh/Student/Register");
            } else if (
              user.isRegistered === true &&
              user.isApproved === true &&
              user.StudentType === "EPravesh" &&
              user.isPaid === false
            ) {
              const courseType = user.courseType;
              const courseName = user.courseName;
              const courseBranch = user.courseBranch;
              const id = user._id;
              const name = user.name;
              const email = user?.email;
              const mobile = user?.mobile;
              const admissionSession = user?.admissionSession;

              navigate("/Epravesh/Student/payment", {
                state: {
                  courseType,
                  courseName,
                  courseBranch,
                  id,
                  name,
                  email,
                  mobile,
                  admissionSession,
                },
              });
            } else if (
              user.StudentType === "EPravesh" &&
              user.isEnrolled === true &&
              user.isEnrollPaid === false
            ) {
              navigate("/Epravesh/Student/EnrollementFee");
            } else if (
              user.isRegistered === true &&
              user.StudentType === "EPravesh" &&
              user.isPaid === true &&
              user.isEnrolled === true &&
              user.isEnrollPaid === true
            ) {
              navigate("/studentalldetail");
            }
          } else {
            if (
              user.isApproved === true &&
              user.isEnrolled === false &&
              user.isPaid === true
            ) {
              navigate("/studentalldetail");
            } else if (user.isApproved === true && user.isPaid === false) {
              navigate("/PaymentPage");
            } else if (
              user.StudentType !== "EPravesh" &&
              user.isEnrolled === true
            ) {
              if (user.isPaid === true) {
                navigate("/studentalldetail");
              } else {
                navigate("/PaymentPage");
              }
            } else {
              if (user.isRegistered === true && user.isApproved === false) {
                navigate("/studentWaiting");
              } else {
                navigate("/studentlogin/selectCourse");
              }
            }
          }
        } else {
          swal({
            icon: "error",
            title: "Error",
            text: `Invalid Credentials`,
          });
        }
      } catch (error) {
        swal({
          icon: "error",
          title: "Error",
          text: `Something Went wrong!`,
        });
      } finally {
        setloading(false);
      }
    },
  });
  useEffect(() => {
    // Disable both backward and forward navigation
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      <style>
        {`

  body {
    background-image:
url('https://cdn.givingcompass.org/wp-content/uploads/2018/04/20112042/na-schools.jpg');
    background-size: cover;
    background-repeat: no-repeat;

  }

  @media only screen and (max-width: 600px) {
    body {
      background-size: cover;
    }
  }`}
      </style>

      <div
        style={{
          width: "90%",
          marginLeft: "5%",
          height: "100vh",
          marginTop: "30px",
        }}
      >
        <Grid
          container
          component="main"
          sx={{ height: "80vh", marginTop: "2%" }}
        >
          <CssBaseline />

          <Grid
            borderRadius={10}
            boxShadow={20}
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              mx: "auto",
              marginTop: "5px",
              width: { xs: "90%", sm: "70%" },
            }}
          >
            <Box
              sx={{
                my: 6,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "80px" }}
                src="https://tagvs.com/wp-content/uploads/2021/06/noun_User_1973987.png"
                alt=""
              />
              <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                <img
                  style={{ width: "100%" }}
                  src="https://www.sssutms.co.in/cms/Areas/Website/Content/images/logo21.png"
                  alt="logo"
                />
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={formik.handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="randomId"
                  label="Enter Id"
                  name="randomId"
                  autoComplete="off"
                  value={formik.values.randomId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.randomId && Boolean(formik.errors.randomId)
                  }
                  helperText={formik.touched.randomId && formik.errors.randomId}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="randomPassword"
                  label="Enter Password"
                  autoComplete="off"
                  value={formik.values.randomPassword}
                  onChange={formik.handleChange}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.randomPassword &&
                    Boolean(formik.errors.randomPassword)
                  }
                  helperText={
                    formik.touched.randomPassword &&
                    formik.errors.randomPassword
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  style={{ borderRadius: "40px", marginTop: "40px" }}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Sign In"
                  )}
                </Button>
                <Grid container>
                  <Grid item>
                    <Typography variant="bo dy2">
                      Don't have an account?{" "}
                      <Link to="/erp/studentregister">Sign Up</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

import React, { useState } from "react";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Paper,
} from "@mui/material";
import axios from "axios";
import jsPDF from "jspdf";
import logo from "../../images/sssutms.jpg"; // Make sure this path is correct
import AdminDashboard from "./AdminDashboard";

function ManualPaymentProcess() {

  // Search States
  const [selectedField, setSelectedField] = useState("option1");
  const [enteredValue, setEnteredValue] = useState("");
  const [student, setStudent] = useState(null);

  // Form States
  const [formData, setFormData] = useState({
    orderId: "",
    txnId: "",
    bankTxnId: "",
    paymentMode: "",
    txnDate: "",
    txnAmount: "",
    responseMessage: "",
    status: "",
    studentId: "",
    name: "",
    randomId: "",
    email: "",
    mobile: "",
    fathersname: "",
    mothersname: "",
    courseName: "",
    courseType: "",
    courseBranch: "",
    session: "",
  });

  // Receipt State
  const [payment, setPayment] = useState(null);

  const handleSearch = async () => {
    if (selectedField === "option1" || !enteredValue) {
      alert("Please select a valid search field and enter a value.");
      return;
    }

    try {
      const api = `https://sssutms.ac.in/apitest/v1/search?randomId=${enteredValue}`;
      const response = await axios.get(api);
      if (response.data) {
        const studentData = response.data;
        setStudent(studentData);
        setFormData({
          ...formData,
          studentId: studentData._id,
          name: studentData.name,
          randomId: studentData.randomId,
          email: studentData.email,
          mobile: studentData.mobile,
          fathersname: studentData.fathersname,
          mothersname: studentData.mothersname,
          courseName: studentData.courseName,
          courseType: studentData.courseType,
          courseBranch: studentData.courseBranch,
          session: studentData.session,
        });
      } else {
        alert("No student found");
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Error occurred while searching");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/save-payment",
        formData
      );
      const paymentData = response.data.payment;
      setPayment(paymentData);
    } catch (error) {
      console.error(error);
      alert("Failed to save payment information");
    }
  };

    const generatePDF = () => {
      const doc = new jsPDF();
  
      // Add watermark
      doc.setFontSize(60);
      doc.setTextColor(245, 245, 245);
      doc.text("SSSUTMS", 45, 130, { angle: 45 });
  
      // Header with logo and university details
      doc.addImage(
        logo,
        "JPEG",
        10,
        10,
        20,
        20
      );
      doc.setFontSize(16);
      doc.setTextColor(0, 48, 135);
      doc.setFont("helvetica", "bold");
      doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY", 105, 15, {
        align: "center",
      });
      doc.text("AND MEDICAL SCIENCES", 105, 22, { align: "center" });
  
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100); // Gray for secondary text
      doc.setFont("helvetica", "normal");
      doc.text(
        "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
        105,
        28,
        { align: "center" }
      );
      doc.text(
        "Sh-18 Bhopal-Indore Road, Pin Code - 466001 Pachama, Sehore",
        105,
        33,
        { align: "center" }
      );
      doc.text(
        `Academic Session ${student.Session || "2025-2026"} `,
        105,
        38,
        { align: "center" }
      );
  
      // Receipt title
      doc.setFillColor(0, 48, 135);
      doc.rect(0, 45, 210, 10, "F");
      doc.setTextColor(255, 255, 255);
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("E-PRAVESH FEE RECEIPT", 105, 51, { align: "center" });
  
      doc.setTextColor(100, 100, 100);
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");
      doc.text(`Receipt Date: ${"formattedDate"}`, 15, 65);
      doc.text(`Receipt Time: ${"formattedTime"}`, 150, 65);
  
      // Main content
      const startY = 80;
      const lineHeight = 7;
      let currentY = startY;
  
      // Helper function for drawing rows
      const drawRow = (label, value) => {
        doc.setTextColor(100, 100, 100);
        doc.text(label, 25, currentY);
        doc.setTextColor(0, 0, 0);
        doc.text(": ", 70, currentY);
        doc.text(value, 75, currentY);
        currentY += lineHeight;
      };
  
      // Student details section
      doc.setFillColor(240, 240, 240);
      doc.rect(15, 70, 180, 8, "F");
      doc.setTextColor(0, 48, 135);
      doc.setFont("helvetica", "bold");
      doc.text("STUDENT DETAILS", 25, 75);
      currentY = 85;
  
      doc.setFont("helvetica", "normal");
      drawRow("Name", student.name);
      drawRow("Father's Name", student.fathersname);
      drawRow("Email", student.email);
      drawRow("Mobile", student.mobile);
      drawRow("Course Type", student.courseType || "N/A");
      drawRow("Course Name", student.courseName);
      drawRow("Course Branch", student.courseBranch);
  
      // Payment details section
      currentY += 5;
      doc.setFillColor(240, 240, 240);
      doc.rect(15, currentY - 5, 180, 8, "F");
      doc.setTextColor(0, 48, 135);
      doc.setFont("helvetica", "bold");
      doc.text("PAYMENT DETAILS", 25, currentY);
      currentY += 10;
  
      doc.setFont("helvetica", "normal");
      drawRow("Transaction ID", payment.txnId);
      drawRow("Order ID", payment.orderId);
      drawRow("Payment Mode", payment.paymentMode);
      drawRow("Bank Transaction ID", payment.bankTxnId);
  
      // Amount section with box
      currentY += 5;
      doc.setFillColor(0, 48, 135);
      doc.rect(15, currentY - 5, 180, 12, "F");
      doc.setTextColor(255, 255, 255);
      doc.setFont("helvetica", "bold");
      doc.text("Amount Paid:", 25, currentY + 3);
      doc.text(`${payment.txnAmount}/-`, 160, currentY + 3);
  
      // Footer
      doc.setTextColor(100, 100, 100);
      doc.setFontSize(8);
      doc.setFont("helvetica", "normal");
     
  
      // Save the PDF
      doc.save(`${student.Name}_EPravesh_Fee_Receipt.pdf`);
  };

  return (
    <>
      <AdminDashboard />
      <Container sx={{ mt: 10, ml: "5%" }}>
        <Paper elevation={3} sx={{ p: 3, maxWidth: 600 }}>
          <Typography variant="h5" gutterBottom>
            Manual Payment Receipt
          </Typography>

          {/* Search Section */}
          <Box sx={{ mb: 4 }}>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Search by</InputLabel>
              <Select
                value={selectedField}
                onChange={(e) => setSelectedField(e.target.value)}
                label="Search by"
              >
                <MenuItem value="option1">Select</MenuItem>
                <MenuItem value="Regestration No">Registration No</MenuItem>
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Enter Registration No"
              value={enteredValue}
              onChange={(e) => setEnteredValue(e.target.value)}
              sx={{ mb: 2 }}
            />
            <Button
              variant="contained"
              onClick={handleSearch}
              sx={{ backgroundColor: "#004e92" }}
            >
              Search
            </Button>
          </Box>

          {/* Form Section */}
          {student && (
            <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Payment Information
              </Typography>
              {[
                "orderId",
                "txnId",
                "bankTxnId",
                "paymentMode",
                "txnDate",
                "txnAmount",
                "responseMessage",
                "name",
                "randomId",
                "email",
                "mobile",
                "fathersname",
                "mothersname",
                "courseName",
                "courseType",
                "courseBranch",
                "session",
              ].map((key) => (
                <TextField
                  key={key}
                  fullWidth
                  label={key.replace(/([A-Z])/g, " $1")}
                  name={key}
                  value={formData[key]}
                  onChange={handleChange}
                  sx={{ mb: 2 }}
                  required
                  disabled={
                    [
                      "name",
                      "randomId",
                      "email",
                      "mobile",
                      "fathersname",
                      "mothersname",
                      "courseName",
                      "courseType",
                      "courseBranch",
                      "session",
                    ].includes(key)
                  }
                />
              ))}
              <Button type="submit" variant="contained" color="primary">
                Save Payment
              </Button>
            </Box>
          )}

          {/* Receipt Section */}
          {payment && (
            <Box sx={{ textAlign: "center" }}>
              <Typography variant="h6" gutterBottom>
                Payment Successful
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={generatePDF}
              >
                Download Receipt
              </Button>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
}

export default ManualPaymentProcess;
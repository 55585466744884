import React, { useState, useEffect } from "react";
import bg from "../../src/Main_Admin/images/b.png";
import b from "../images/sssutms.jpg";
import scientist from "../images/scientist.png";
import ra from "../images/radiograph.png";
import yoga from "../images/yoga.png";
import fop4 from "../images/fop4.png";
import ed from "../images/education.png";
import pe from "../images/Admission_Enquiry/pe.png";
import com from "../images/Admission_Enquiry/com.png";
import hm from "../images/Admission_Enquiry/hm.png";
import ar from "../images/Admission_Enquiry/ar.png";
import folder from "../images/Admission_Enquiry/folder.png";
import ec from "../images/Admission_Enquiry/EC.png";
import SKETCH from "../images/Admission_Enquiry/sketch.png";
import inn from "../images/Admission_Enquiry/INN.png";
import art from "../images/Admission_Enquiry/art.png";
import useFetch from "../CUSTOM_HOOK/Usefetch";
import { State, City } from "country-state-city";

import { Form, Button } from "react-bootstrap";
import blood from "../images/blood.png";
import fop3 from "../images/Admission_Enquiry/fop3.png";
import ee from "../images/Admission_Enquiry/ee.png";

import dc from "../images/Admission_Enquiry/dc.png";
import law from "../images/Admission_Enquiry/law.png";
import se from "../images/Admission_Enquiry/se.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NURSE from "../images/Admission_Enquiry/nurse.png";
import AYURVEDA from "../images/Admission_Enquiry/AYURVEDA.png";
import HOMEO from "../images/Admission_Enquiry/homeopathy.png";
import physio from "../images/Admission_Enquiry/physio.png";
import agro from "../images/Admission_Enquiry/agro.png";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { BookOpen, GraduationCap, School, Award, Loader2 } from "lucide-react";
import { Search } from "lucide-react";

const Admission_Enquiry = () => {
  const [Loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [expandedCourse, setExpandedCourse] = useState(null);
  const [courseData, setCourseData] = useState({});
  const [dynamicCourseData, setDynamicCourseData] = useState({
    tab1: [], // UG
    tab2: [], // PG
    tab3: [], // Diploma
    tab4: [], // PG_Diploma
  });
  const [courseType, setCourseType] = useState("");
  const [courseName, setCourseName] = useState("");
  const [courseBranch, setCourseBranch] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    country: "IN",
    state: "",
    city: "",
    mobile: "",
    courseType: "",
    courseName: "",
    courseBranch: "",
    terms: false,
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const session = new Date().getFullYear();
  const { data, loading, error } = useFetch(
    `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
  );

  const tabMapping = {
    UG: "tab1",
    PG: "tab2",
    Diploma: "tab3",
    PG_Diploma: "tab4",
  };

  const imageMapping = {
    "BACHELOR OF DESIGN": SKETCH,
    "BACHELOR OF ENGINEERING": ar,
    "BACHELOR OF PHARMACY": fop4,
    "BACHELOR OF EDUCATION": ed,
    "BSC(Nursing)": NURSE,
    "BACHELOR OF HOTEL MANAGEMENT AND CATERING": hm,
    "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": pe,
    "BACHELOR OF ARTS BACHELOR OF EDUCATION (B. A. B. Ed.)": art,
    "BACHELOR OF ARCHITECTURE": com,
    "BACHELOR OF SCIENCE": inn,
    "BACHELOR OF BUSINESS ADMINISTRATION": folder,
    "BACHELOR OF ARTS": art,
    "BACHELOR OF COMMERCE": com,
    "BACHELOR OF COMPUTER APPLICATION": ec,
    "BACHELOR OF ENGINEERING(Lateral)": ar,
    "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY": AYURVEDA,
    "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY": HOMEO,
    "BACHELOR OF PHYSIOTHERAPY": physio,
    "BACHELOR OF LIBRARY SCIENCE": ed,
    "B.SC(Hons) AGRICULTURE": agro,
    "BACHELOR OF PHARMACY(lateral)": fop4,
    "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": pe,
    "BACHELOR OF LAWS": law,
    "BACHELOR OF MEDICAL LAB AND TECHNICIAN": scientist,
    "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)": scientist,
    "MASTER OF TECHNOLOGY": dc,
    "MASTER OF BUSINESS ADMINISTRATION(MBA)": folder,
    "MASTER OF PHYSIOTHERAPY": physio,
    "MASTER OF MEDICAL LAB TECHNOLOGY(MMLT)": scientist,
    "MASTER OF COMPUTER APPLICATION": se,
    "MASTER OF PHARMACY": fop4,
    "MASTER OF SCIENCE": inn,
    "MASTER OF COMMERCE": com,
    "MASTER OF ARTS": art,
    "MD(HOMEOPATHY)": HOMEO,
    "DIPLOMA PHARMACY": fop3,
    "DIPLOMA BLOOD TRANSFUSION": blood,
    "DIPLOMA PHARMACY (AYURVED)": AYURVEDA,
    "DIPLOMA MEDICAL LAB AND TECHNICIAN": scientist,
    "DIPLOMA X-RAY RADIOGRAPHER TECHNICIAN": ra,
    "DIPLOMA YOGA": yoga,
    "DIPLOMA ENGINEERING": ee,
    "DIPLOMA PHARMACY (HOMEOPATHY)": HOMEO,
    "DIPLOMA PARAMEDICAL OPTHALMIC ASSISTENT": scientist,
    "DIPLOMA ENGINEERING  (Lateral)": ee,
    "POST GRADUATION DIPLOMA IN COMPUTER APPLICATION": ec,
  };
  const durationMapping = {
    "BACHELOR OF DESIGN": "4 Years",
    "BACHELOR OF ENGINEERING": "4 Years",
    "BACHELOR OF PHARMACY": "4 Years",
    "BACHELOR OF EDUCATION": "2 Years",
    "BSC(Nursing)": "4 Years",
    "BACHELOR OF HOTEL MANAGEMENT AND CATERING TECHNOLOGY": "4 Years",
    "BACHELOR OF PHYSICAL EDUCATION(B. P. Ed.)": "2 Years",
    "BACHELOR OF ARTS BACHELOR OF EDUCATION (B. A. B. Ed.)": "4 Years",
    "BACHELOR OF ARCHITECTURE": "5 Years",
    "BACHELOR OF SCIENCE": "3 Years",
    "BACHELOR OF BUSINESS ADMINISTRATION": "3 Years",
    "BACHELOR OF ARTS": "3 Years",
    "BACHELOR OF COMMERCE": "3 Years",
    "BACHELOR OF COMPUTER APPLICATION": "3 Years",
    "BACHELOR OF ENGINEERING(Lateral)": "3 Years",
    "BACHELOR OF AYURVEDIC MEDICINE AND SURGERY": "5.5 Years",
    "BACHELOR OF HOMEOPATHY MEDICINE AND SURGERY": "5.5 Years",
    "BACHELOR OF PHYSIOTHERAPY": "4.5 Years",
    "BACHELOR OF LIBRARY SCIENCE": "1 Year",
    "B.SC(Hons) AGRICULTURE": "4 Years",
    "BACHELOR OF PHARMACY(lateral)": "3 Years",
    "BACHELOR OF PHYSICAL EDUCATION AND SPORTS(BPES)": "3 Years",
    "BACHELOR OF LAWS": "3 Years",
    "BACHELOR OF MEDICAL LAB AND TECHNICIAN": "3 Years",
    "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)": "5.5 Years",
    "MASTER OF TECHNOLOGY": "2 Years",
    "MASTER OF BUSINESS ADMINISTRATION(MBA)": "2 Years",
    "MASTER OF PHYSIOTHERAPY": "2 Years",
    "MASTER OF MEDICAL LAB TECHNOLOGY(MMLT)": "2 Years",
    "MASTER OF COMPUTER APPLICATION": "2 Years",
    "MASTER OF PHARMACY": "2 Years",
    "MASTER OF SCIENCE": "2 Years",
    "MASTER OF COMMERCE": "2 Years",
    "MASTER OF ARTS": "2 Years",
    "MD(HOMEOPATHY)": "3 Years",
    "DIPLOMA PHARMACY": "2 Years",
    "DIPLOMA BLOOD TRANSFUSION": "1 Year",
    "DIPLOMA PHARMACY (AYURVED)": "2 Years",
    "DIPLOMA MEDICAL LAB AND TECHNICIAN": "2 Years",
    "DIPLOMA X-RAY RADIOGRAPHER TECHNICIAN": "2 Years",
    "DIPLOMA YOGA": "1 Year",
    "DIPLOMA ENGINEERING": "3 Years",
    "DIPLOMA PHARMACY (HOMEOPATHY)": "2 Years",
    "DIPLOMA PARAMEDICAL OPTHALMIC ASSISTENT": "2 Years",
    "DIPLOMA ENGINEERING  (Lateral)": "2 Years",
    "POST GRADUATION DIPLOMA IN COMPUTER APPLICATION": "1 Year",
  };

  useEffect(() => {
    if (data) {
      console.log("Full API Response:", JSON.stringify(data, null, 2));

      const formattedData = {};
      const dynamicData = { tab1: [], tab2: [], tab3: [], tab4: [] };

      data.forEach((courseTypeData) => {
        const tabKey = tabMapping[courseTypeData.courseType];
        if (!tabKey) return;

        const courses = courseTypeData.courseNames
          .filter((course) => course.enabled)
          .map((course) => {
            const branches =
              course.branches && course.branches.length > 0
                ? course.branches
                    .filter((branch) => branch.enabled)
                    .map((branch) => {
                      return branch.branchName
                        ? branch.branchName.trim()
                        : "Not Specified";
                    })
                    .filter(
                      (branchName, index, self) =>
                        self.indexOf(branchName) === index
                    )
                : ["Not Specified"];

            return {
              name: course.courseName.trim(),
              branches,
              img: imageMapping[course.courseName] || folder,
              duration:
                durationMapping[course.courseName] || "Duration Not Specified", // Use static duration
            };
          });

        dynamicData[tabKey] = courses;

        if (!formattedData[courseTypeData.courseType]) {
          formattedData[courseTypeData.courseType] = {
            courses: [],
            branches: {},
          };
        }
        courseTypeData.courseNames.forEach((course) => {
          if (course.enabled) {
            formattedData[courseTypeData.courseType].courses.push(
              course.courseName
            );
            formattedData[courseTypeData.courseType].branches[
              course.courseName
            ] =
              course.branches && course.branches.length > 0
                ? course.branches
                    .filter((branch) => branch.enabled)
                    .map((branch) => {
                      return branch.branchName
                        ? branch.branchName.trim()
                        : "Not Specified";
                    })
                    .filter(
                      (branchName, index, self) =>
                        self.indexOf(branchName) === index
                    )
                : ["Not Specified"];
          }
        });
      });

      console.log("Dynamic Course Data:", dynamicData);
      setDynamicCourseData(dynamicData);
      setCourseData(formattedData);
    }
  }, [data]);

  useEffect(() => {
    const getAllStates = async () => {
      try {
        const statesData = await State.getStatesOfCountry("IN");
        setStates(statesData);
      } catch (err) {
        console.error("Error fetching states:", err);
      }
    };
    getAllStates();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedValue = type === "checkbox" ? checked : value;

    // Ensure mobile is treated as a string and only allows numbers
    if (name === "mobile") {
      updatedValue = value.replace(/[^0-9]/g, "").slice(0, 10); // Allow only digits, max 10
    }

    setFormData({ ...formData, [name]: updatedValue });

    // Clear the error for the field being updated
    if (updatedValue) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    }

    if (name === "state") {
      setCities([]);
      try {
        const stateCities = City.getCitiesOfState("IN", value);
        setCities(stateCities);
      } catch (err) {
        console.error("Error fetching cities:", err);
      }
    }
  };

  const handleCourseTypeChange = (e) => {
    setCourseType(e.target.value);
    setFormData({ ...formData, courseType: e.target.value });
    setCourseName("");
    setCourseBranch("");
    setErrors((prevErrors) => ({
      ...prevErrors,
      courseType: undefined,
      courseName: undefined,
      courseBranch: undefined,
    }));
  };

  const handleCourseChange = (e) => {
    setCourseName(e.target.value);
    setFormData({ ...formData, courseName: e.target.value });
    setCourseBranch("");
    setErrors((prevErrors) => ({
      ...prevErrors,
      courseName: undefined,
      courseBranch: undefined,
    }));
  };
  const handleBranchChange = (e) => {
    setCourseBranch(e.target.value);
    setFormData({ ...formData, courseBranch: e.target.value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      courseBranch: undefined,
    }));
  };

  const validate = () => {
    let newErrors = {};

    // Full Name Validation
    if (!formData.fullname.trim()) {
      newErrors.fullname = "Full Name is required";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.fullname)) {
      newErrors.fullname = "Name should contain only letters";
    } else if (formData.fullname.length < 2) {
      newErrors.fullname = "Name must be at least 2 characters";
    }

    // Email Validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)
    ) {
      newErrors.email = "Please enter a valid email (e.g., example@domain.com)";
    }

    // State Validation
    if (!formData.state) {
      newErrors.state = "Please select a state";
    }

    // City Validation
    if (!formData.city) {
      if (!formData.state) {
        newErrors.city = "Please select a state first";
      } else {
        newErrors.city = "Please select a city";
      }
    }

    // Mobile Validation
    const mobileValue = formData.mobile.toString().trim(); // Ensure it's a string and trim whitespace
    if (!mobileValue) {
      newErrors.mobile = "Mobile Number is required";
    } else if (mobileValue.length !== 10) {
      newErrors.mobile = "Must be exactly 10 digits";
    }

    // Course Type Validation
    if (!formData.courseType) {
      newErrors.courseType = "Please select a course type";
    }

    // Course Name Validation
    if (!formData.courseName) {
      newErrors.courseName = "Please select a course";
    }

    // Course Branch Validation
    if (!formData.courseBranch) {
      if (!formData.courseName) {
        newErrors.courseBranch = "Please select a course first";
      } else {
        newErrors.courseBranch = "Please select a specialization";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      setLoading(true);
      try {
        const response = await axios.post(
          "https://sssutms.ac.in/apitest/v1/admission-enquiry",
          formData
        );

        setFormData({
          fullname: "",
          email: "",
          country: "IN",
          state: "",
          city: "",
          mobile: "",
          courseType: "",
          courseName: "",
          courseBranch: "",
          terms: false,
        });

        if (response.data.status === 200) {
          swal({
            title: "Thank You for Your Enquiry! 🎉",
            content: {
              element: "p",
              attributes: {
                innerHTML:
                  "We’re excited to assist you with your admission to Sri Satya Sai University of Technology & Medical Sciences! Our team will reach out to you shortly to guide you through the next steps. Stay tuned!",
                style: "color: #05668d; font-size: 18px;",
              },
            },
            icon: "success",
            buttons: "OK",
          }).then(() => {
            navigate("/");
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        swal("Error", "Something went wrong. Please try again.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const toggleCourse = (courseName) => {
    setExpandedCourse(expandedCourse === courseName ? null : courseName);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter courses based on search term
  const filteredCourses = dynamicCourseData[activeTab].filter((course) =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <main className="mainnn">
        <body className="color-vars">
          <div className="bg-img">
            <header className="header-section">
              <div className="coner">
                <div className="nav-bar d-flex">
                  <Link to="/" className="contact-number d-flex">
                    <span>
                      <img src={b} className="image" alt="Logo" />
                    </span>
                    <span className="spann" style={{ color: "white" }}>
                      SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
                    </span>
                  </Link>
                  <div>
                    <Link to="#" className="toggle-btn" />
                    <div className="nav-right">
                      <div className="call-content">
                        <span className="call-title">Admission Enquiry</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>

            <div className="hero-section">
              <div className="container">
                <div className="hero-wrapper d-flex">
                  <div className="content-rgt">
                    <h2 className="hero-heading">ADMISSION Open</h2>
                  </div>
                  <div className="hero-form">
                    <div className="title-wrapper d-flex">
                      <h3
                        className="form-title"
                        style={{
                          color: "white",
                          fontFamily: "'Poppins',sans-serif",
                        }}
                      >
                        Enquiry Now
                      </h3>
                      <div className="ribbin"></div>
                    </div>
                    <p
                      className="form-text"
                      style={{
                        color: "white",
                        fontFamily: "'Poppins',sans-serif",
                      }}
                    >
                      Fill out the enquiry form, will call you back ASAP!
                    </p>

                    <div className="form-callback">
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="form-item">
                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              className="f-field"
                              type="text"
                              name="fullname"
                              value={formData.fullname}
                              onChange={handleInputChange}
                              placeholder="Full Name"
                              isInvalid={!!errors.fullname}
                              autoComplete="off"
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                                position: "absolute",
                                bottom: "-25px",
                                left: "0",
                                width: "100%",
                                whiteSpace: "normal",
                                paddingLeft: "5px",
                              }}
                            >
                              {errors.fullname}
                            </Form.Control.Feedback>
                          </div>

                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              className="f-field"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              placeholder="Email (example@gmail.com)"
                              isInvalid={!!errors.email}
                              autoComplete="off"
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <Form.Group className="form-item">
                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              as="select"
                              name="state"
                              value={formData.state}
                              onChange={handleInputChange}
                              className="f-field"
                              isInvalid={!!errors.state}
                            >
                              <option value="">State</option>
                              {states.map((state) => (
                                <option
                                  key={state.isoCode}
                                  value={state.isoCode}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.state}
                            </Form.Control.Feedback>
                          </div>

                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              as="select"
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange}
                              className="f-field"
                              isInvalid={!!errors.city}
                            >
                              <option value="">City</option>
                              {cities.map((city) => (
                                <option key={city.name} value={city.name}>
                                  {city.name}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.city}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <Form.Group className="form-item">
                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              as="select"
                              value={formData.courseType}
                              onChange={handleCourseTypeChange}
                              className="f-field"
                              isInvalid={!!errors.courseType}
                            >
                              <option value="">Course Type</option>
                              {Object.keys(courseData).map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </Form.Control>
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.courseType}
                            </Form.Control.Feedback>
                          </div>

                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              as="select"
                              value={formData.courseName}
                              onChange={handleCourseChange}
                              className="f-field"
                              isInvalid={!!errors.courseName}
                            >
                              <option value="">Courses</option>
                              {courseType &&
                                courseData[courseType].courses.map((course) => (
                                  <option key={course} value={course}>
                                    {course}
                                  </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.courseName}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <Form.Group className="form-item">
                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              as="select"
                              value={formData.courseBranch}
                              onChange={handleBranchChange}
                              className="f-field"
                              isInvalid={!!errors.courseBranch}
                            >
                              <option value="">Specialization</option>
                              {courseName &&
                                courseData[courseType].branches[courseName].map(
                                  (branch) => (
                                    <option key={branch} value={branch}>
                                      {branch}
                                    </option>
                                  )
                                )}
                            </Form.Control>
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.courseBranch}
                            </Form.Control.Feedback>
                          </div>

                          <div style={{ width: "48%", position: "relative" }}>
                            <Form.Control
                              className="f-field"
                              type="text"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleInputChange}
                              placeholder="Phone Number (10 digits)"
                              isInvalid={!!errors.mobile}
                              autoComplete="off"
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                display: "block",
                                marginTop: "5px",
                                color: "red",
                              }}
                            >
                              {errors.mobile}
                            </Form.Control.Feedback>
                          </div>
                        </Form.Group>

                        <div className="form-item">
                          <textarea
                            name="message"
                            rows={6}
                            style={{ width: "100%", height: "150px" }}
                            placeholder="Any other information..."
                            className="f-field"
                          />
                        </div>

                        <div className="submit-btn d-flex">
                          <Button type="submit" className="btn">
                            {Loading ? "Submitting..." : "Submit"}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </body>

        <style>
          {`
          *{
          font-family:'Poppins',sans-serif;
          }
            :root {
              --primary-text: #f5b41c;
              --secondary-text: #163f58;
              --white-text-white: #fff;
              --gray-text: #464646;
              --light-white: #c8c8c8;
            }
            .mainnn {
              overflow-x: hidden;
            }
            .image {
              height: 50px;
              width: 50px;
              border-radius: 50px;
            }
            ul {
              list-style-type: none;
            }
            a {
              text-decoration: none;
              display: inline-block;
            }
            img {
              max-width: 100%;
              object-fit: cover;
            }
            .d-flex {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
            .coner {
              width: 100%;
              max-width: 1166px;
              margin-left: auto;
              margin-right: auto;
              padding-inline: 15px;
            }
            .bg-img {
              background-image: linear-gradient(45deg, #0215209e, #163f5894), url(${bg});
              width: 100%;
              height: 830px;
              background-size: cover;
              background-repeat: no-repeat;
              position: relative;
              z-index: 10;
            }
            .bg-img::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 230px;
              z-index: -10;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%23ffffff' fill-opacity='1' d='M0,192L1440,64L1440,320L0,320Z' /%3E%3C/svg%3E");
              background-size: cover;
              background-repeat: no-repeat;
            }
            .header-section {
              background-color: rgb(89 89 89 / 50%);
              position: fixed;
              left: 0;
              top: 0;
              z-index: 999;
              width: 100%;
            }
            .nav-bar {
              padding: 10px 0;
              margin-left: -50px;
            }
            .nav-right {
              position: relative;
              width: 200px;
              margin-left: 180px;
            }
            .toggle-btn {
              display: none;
            }
            .contact-number {
              font-size: 20px;
              color: var(--white-text-white);
              font-weight: 700;
              font-family: 'Poppins',sans-serif;
              text-align: center;
            }
            .contact-number.d-flex {
              gap: 20px;
            }
            .call-content {
              height: 60px;
              width: 458px;
              padding-left: 25px;
              background-color: var(--primary-text);
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;
              right: 0;
              transform: translateX(-17%);
            }
            .nav-right .call-content::before {
              content: "";
              position: absolute;
              left: -13%;
              top: -2px;
              border-bottom: 62px solid var(--primary-text);
              border-left: 62px solid transparent;
            }
            .call-title {
              font-size: 20px;
              color: var(--white-text-white);
              font-weight: 700;
              font-family: 'Poppins',sans-serif;
              text-align: center;
            }
            .hero-section {
              padding-top: 180px;
              padding-bottom: 36px;
            }
            .hero-heading {
              font-size: 50px;
              // letter-spacing: -2px;
              line-height: 70px;
              text-transform: uppercase;
              color: orange;
              font-weight: 800;
              font-family: 'Poppins',sans-serif;
              max-width: 500px;
              margin-bottom: 46px;
            }
            .hero-wrapper .content-rgt {
              width: 627px;
              margin-left: 150px;
            }
            .hero-wrapper.d-flex {
              align-items: baseline;
            }
            .hero-form {
              width: 469px;
              background-color: var(--secondary-text);
              border: 4px solid var(--white-text-white);
              border-radius: 5px;
              padding-inline: 12px;
              padding-bottom: 20px;
              padding-top: 20px;
              height: 570px;
            }
            .hero-form .form-title {
              font-size: 25px;
              letter-spacing: 0px;
              line-height: 25px;
              text-transform: uppercase;
              color: var(--white-text-white);
              font-weight: 800;
              font-family: 'Poppins',sans-serif;
            }
            .form-text {
              width: 381px;
              font-size: 15px;
              letter-spacing: 0px;
              line-height: 27px;
              color: var(--white-text-white);
              font-family: 'Poppins',sans-serif;
            }
            .form-callback {
              margin-top: 22px;
            }
            .form-item {
              margin-bottom: 14px;
              display: flex;
              justify-content: space-between;
            }
            .form-item .f-field,
            .form-item textarea {
              width: 220px;
              height: 42px;
              border-radius: 2px;
              padding: 10px;
              background-color: var(--white-text-white);
              border: 1px solid var(--light-white);
              font-size: 15px;
              letter-spacing: 0px;
              line-height: 27px;
              color: var(--gray-text);
              font-family: 'Poppins',sans-serif;
            }
            .form-item textarea {
              width: 100%;
              height: 104px;
            }
            .submit-btn.d-flex {
              justify-content: center;
              flex-direction: column;
              gap: 20px;
            }
            .submit-btn .btn {
              width: 159px;
              height: 46px;
              padding: 9px 30px;
              background-color: var(--primary-text);
              font-size: 16px;
              letter-spacing: 0px;
              line-height: 27px;
              color: var(--white-text-white);
              font-weight: 500;
              font-family: 'Poppins',sans-serif;
              text-align: center;
              border: 1px solid var(--primary-text);
              transition: 0.3s;
              cursor: pointer;
              margin-top: 10px;
            }
            .submit-btn .btn:hover {
              border: 1px solid var(--white-text-white);
              background-color: var(--white-text-white);
              color: var(--primary-text);
            }
            .error-message {
              color: red;
              font-size: 12px;
              margin-top: 50px;
              width: 190px;
              margin-left: -170px;
            }
            .three {
              margin-left: 500px;
              margin-top: 40px;
            }
            .three h1 {
              font-size: 40px;
              font-weight: 800;
              letter-spacing: 0;
              line-height: 1.5em;
              padding-bottom: 15px;
              position: relative;
              color: #E4003A;
            }
            .three h1:before {
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              height: 5px;
              width: 65px;
              background-color: #973131;
            }
            .three h1:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: 2px;
              height: 1px;
              width: 95%;
              max-width: 355px;
              background-color: #333;
            }
.form-item .f-field + .invalid-feedback {
      display: block !important;
      margin-top: 5px;
      color: red;
      width: 100%;
      text-align: left;
      position: absolute;
      bottom: -25px; /* Adjusted for longer messages */
      left: 0;
      whiteSpace: normal;
      padding-left: 5px;
    }

    .form-item {
      position: relative;
      
      margin-bottom: 20px; /* Increased to accommodate longer error messages */
    }

    .form-item textarea {
      width: 100% !important; /* Ensure textarea takes full width */
      height: 100px !important; /* Override to match inline style */
    }

    @media screen and (max-width: 700px) {
      .form-item .f-field + .invalid-feedback {
        position: relative;
        bottom: auto;
        left: auto;
        margin-top: 5px;
      }
      .form-item textarea {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 420px) {
      .form-item .f-field + .invalid-feedback {
        position: relative;
        bottom: auto;
        left: auto;
        margin-top: 5px;
      }
      .form-item textarea {
        width: 100% !important;
      }
    }
            /* Responsive adjustments for 700px */
            @media screen and (max-width: 700px) {
              .bg-img {
                height: 900px;
              }
              .hero-wrapper.d-flex {
                flex-direction: column;
                align-items: center;
              }
              .hero-wrapper .content-rgt {
                width: 100%;
                margin-left: 0;
                text-align: center;
              }
              .hero-heading {
                font-size: 35px;
                line-height: 45px;
                max-width: 100%;
              }
              .hero-form {
                width: 90%;
                margin: 20px auto;
                height: auto;
                padding: 20px;
              }
              .form-text {
                width: 100%;
              }
              .form-item {
                flex-direction: column;
                gap: 10px;
              }
              .form-item .f-field,
              .form-item textarea {
                width: 100%;
                margin-left: 0;
              }
              .error-message {
                margin-left: 0;
                margin-top: 5px;
                width: 100%;
              }
              .nav-bar {
                margin-left: 0;
                flex-direction: column;
                align-items: flex-start;
              }
              .nav-right {
                width: 100%;
                margin-left: 0;
                margin-top: 10px;
              }
              .call-content {
                width: 100%;
                transform: translateX(0);
              }
              .three {
                margin-left: 20px;
                text-align: center;
              }
            }

            /* Responsive adjustments for 420px */
            @media screen and (max-width: 420px) {
           
            .bg-img {
                height: 950px;
              }
              .header-section {
                position: relative;
              }
              .nav-bar {
                padding: 5px 0;
              }
              .contact-number {
                font-size: 16px;
                flex-direction: row;
                text-align: center;
                gap: 10px;
              }
              .spann {
                font-size: 14px;
                width: 100%;
              }
              .call-content {
                height: 50px;
                padding-left: 15px;
              }
              .call-title {
                font-size: 16px;
              }
              .hero-section {
                padding-top: 100px;
              }
              .hero-heading {
                font-size: 25px;
                line-height: 35px;
              }
              .hero-form {
                padding: 15px;
                width:370px;
              }
              .form-title {
                font-size: 20px;
              }
              .form-text {
                font-size: 13px;
                line-height: 20px;
              }
              .submit-btn .btn {
                width: 100%;
              }
              .three h1 {
                font-size: 30px;
              }
            }
          `}
        </style>
        <div className="three">
          <h1> ACADEMIC COURSES </h1>
          <span
            className="course-span"
            style={{ fontFamily: "'Poppins',sans-serif" }}
          >
            Discover your perfect course and shape your future
          </span>
        </div>

        <div className="container">
          <div className="tabs">
            <div className="search-container">
              {/* <h5
    className="program-level-text"
    >
      Program Levels
    </h5> */}
              <Search className="search-icon" />
              <input
                type="text"
                placeholder="Search Courses..."
                className="search-bar"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            <hr />
            <button
              style={{ color: activeTab === "tab1" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab1")}
              className={activeTab === "tab1" ? "active" : ""}
            >
              <BookOpen /> UG Courses
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab2" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab2")}
              className={activeTab === "tab2" ? "active" : ""}
            >
              <GraduationCap /> PG Courses
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab3" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab3")}
              className={activeTab === "tab3" ? "active" : ""}
            >
              <School /> Diploma Courses
            </button>
            <hr />
            <button
              style={{ color: activeTab === "tab4" ? "orange" : "white" }}
              onClick={() => setActiveTab("tab4")}
              className={activeTab === "tab4" ? "active" : ""}
            >
              <Award /> PG Diploma Courses
            </button>
          </div>

          <div className="content">
            {loading && <p className="loading-text">Loading courses...</p>}
            {error && (
              <p className="error-text">
                Error fetching courses: {error.message}
              </p>
            )}
            {!loading && !error && (
              <>
                {filteredCourses.length === 0 && searchTerm && (
                  <p className="no-results">
                    No courses found matching "{searchTerm}"
                  </p>
                )}
                <div className="grid">
                  {filteredCourses.map((course) => {
                    const engineeringCourses = [
                      "BACHELOR OF ENGINEERING",
                      "BACHELOR OF ENGINEERING(Lateral)",
                      "MASTER OF TECHNOLOGY",
                      "DIPLOMA ENGINEERING",
                      "DIPLOMA ENGINEERING  (Lateral)",
                    ];
                    const isEngineeringCourse = engineeringCourses.some(
                      (engCourse) =>
                        engCourse.toUpperCase().trim() ===
                        course.name.toUpperCase().trim()
                    );
                    return (
                      <article
                        key={course.name}
                        className={`accordion-card ${
                          expandedCourse === course.name &&
                          course.branches.length > 3
                            ? "expanded"
                            : ""
                        }`}
                      >
                        <div
                          className="accordion-header"
                          onClick={() => toggleCourse(course.name)}
                        >
                          <img
                            className="course-icon"
                            src={course.img}
                            alt={course.name}
                          />
                          <div className="course-info">
                            <h5 className="course-title">{course.name}</h5>
                            <p className="course-details">
                              <span className="branch-count">
                                {course.branches.length} specialization
                              </span>
                              <span className="duration">
                                {" "}
                                | {course.duration}
                              </span>
                            </p>
                          </div>
                          <span className="accordion-icon">
                            {expandedCourse === course.name ? "" : ""}
                          </span>
                        </div>
                        {expandedCourse === course.name && (
                          <div className="accordion-content">
                            <ul
                              className={`branches-list ${
                                course.branches.length <= 3 ? "stacked" : ""
                              }`}
                            >
                              {course.branches.map((branch, index) => (
                                <li key={index} className="branch-item">
                                  {isEngineeringCourse
                                    ? branch
                                        .replace(" Engineering", "")
                                        .replace("Engineering ", "")
                                        .trim()
                                    : branch}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </article>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
        <style>
          {`
  .course-info {
        flex-grow: 1;
      }
      .course-title {
        font-size: 15px;
        font-weight: 600;
        color: #023047;
        margin-bottom: 1px;
      }
      .course-details {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0;
      }
      .branch-count {
        font-size: 12px;
        color: #333;
        font-weight: 500;
      }
      .duration {
        font-size: 12px;
        color: #666;
        font-weight: 500;
      }
      .accordion-icon {
        font-size: 13px;
        color: #023047;
        font-weight: bold;
      }
      .accordion-card:nth-child(3n+2) .course-title,
      .accordion-card:nth-child(3n+2) .branch-count,
      .accordion-card:nth-child(3n+2) .duration,
      .accordion-card:nth-child(3n+2) .accordion-icon {
        color: #023047;
      }

      /* Responsive adjustments */
      @media (max-width: 700px) {
        .course-title {
          font-size: 14px;
        }
        .branch-count,
        .duration {
          font-size: 11px;
        }
      }

      @media (max-width: 420px) {
        .course-title {
          font-size: 14px;
        }
        .branch-count,
        .duration {
          font-size: 10px;
        }
        .course-details {
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
        }
      }
    /* Global Styles */
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
       font-family:'Poppins',sans-serif;
    }
      .search-container {
  position: relative;
  width: 90%;
  border-radius:10px;
  margin-left:-10px;
  color:white;
  
}

.search-icon {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: gray;
}

.no-results {
      text-align: center;
      padding: 20px;
      color: #666;
      font-size: 16px;
      font-style: italic;
    }

    .search-bar {
      width: 100%;
      padding: 8px 8px 8px 30px;
      border-radius: 5px;
      border: 1px solid #ddd;
      font-size: 14px;
      background-color: #023047;
      margin-left: 20px;
      color: white;  /* Add text color for better visibility */
    }

    .search-bar::placeholder {
      color: #999;  /* Placeholder text color */
    }

    .search-bar:focus {
      outline: none;
      border-color: orange;
      box-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
    }
    body {
      font-family: 'Poppins', sans-serif;
      background-color: #f9f9f9;
      color: #333;
    }
.tabs .program-level-text {
  font-weight: 700;
        color: white;
        margin-left: 50px;
        // margin-top: 30px;
        font-family: 'Open Sans', Sans-serif;
        }
    /* Container */
    .container {
      display: flex;
      max-width: 1400px;
      margin: 40px auto;
      padding: 0 20px;
      gap: 30px;
      align-items: flex-start;
    }

    /* Tabs Section */
    .tabs {
      flex: 1;
      background-color: #023047;
      color: white;
      border-radius:20px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      height: 480px; /* Fixed height for tabs */
    }
    .tabs button {
      background: none;
      border: none;
      color: white;
      padding: 10px;
      text-align: left;
      cursor: pointer;
      font-size: 16px;
      transition: color 0.3s;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .tabs button:hover {
      color: orange;
    }
    .tabs button.active {
      font-weight: bold;
      color: orange;
      background-color: rgba(255, 255, 255, 0.1);
    }

    /* Content Section */
    .content {
      flex: 3;
      padding: 10px;
      border-radius: 10px;
      background-color: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      height: 480px; /* Tabs ke equal height */
      overflow-y: auto; /* Scroll content ke liye */
      padding-right: 10px; /* Scrollbar ke liye space */
    }
    .loading-text,
    .error-text {
      font-size: 16px;
      color: #555;
      text-align: center;
      margin-top: 20px;
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 20px;
      margin-top: 20px;
    }

    /* Accordion Card */
    .accordion-card {
      border: 1px solid #ddd;
      border-radius: 10px;
      background-color: white; /* Default background white */
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;
    }
    // .accordion-card:nth-child(3n+2) {
    //   background-color: #f5b41c; /* Yellow background for second card in each row */
    // }
    .accordion-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
       background-color: #f5b41c;
    }
    .accordion-header {
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 10px;
      background-color: transparent;
    }
    .course-icon {
      width: 30px;
      height: 30px;
      object-fit: contain;
      border-radius: 50%;
      background-color: white;
      padding: 5px;
    }
    .course-info {
      flex-grow: 1;
    }
    .course-title {
      font-size: 14px;
      font-weight: 600;
      color: #023047;
      margin-bottom: 1px;
    }
    .branch-count {
      font-size: 12px;
      color: #333;
      font-weight: 500;
      margin: 0;
    }
    .accordion-icon {
      font-size: 13px;
      color: #023047;
      font-weight: bold;
    }
    .accordion-card:nth-child(3n+2) .course-title,
    .accordion-card:nth-child(3n+2) .branch-count,
    .accordion-card:nth-child(3n+2) .accordion-icon {
      color: #023047;
    }
    .accordion-content {

      padding:5px;
      background-color: #fff;
      border-top: 1px solid #ddd;
    }
    .branches-list {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 10px;
      padding: 0;
    }
    .branches-list.stacked {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    .branch-item {
      font-size: 14px;
      color: #023047;
      padding: 10px 15px;
      background-color: #f5f5f5;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      text-align: center;
      transition: all 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }
    .branches-list.stacked .branch-item {
      text-align: left;
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding: 8px 0;
    }
    .branch-item:hover {
      background-color: #023047;
      color: white;
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .branches-list.stacked .branch-item:hover {
      background-color: transparent;
      color: #f5b41c;
      transform: none;
      box-shadow: none;
    }

    /* Expanded Card */
    .accordion-card.expanded {
      grid-column: 1 / -1;
      padding: 20px;
    }

@media (max-width: 700px) {
              .container {
                flex-direction: row;
                padding: 0 10px;
                margin: 20px auto;
              }
                .hero-heading{
                margin-top:-90px;
                }
.hero-form{
margin-top:-80px;}
              .tabs {
                height: auto;
                padding: 10px;
                width: 100%;
              }
              .tabs button {
                font-size: 14px;
                padding: 8px;
              }
              .content {
                height: auto;
                width: 100%;
                padding: 15px;
              }
              .grid {
                grid-template-columns: 1fr;
                gap: 15px;
              }
              .accordion-card {
                margin: 0;
              }
              .course-title {
                font-size: 14px;
              }
              .branch-count {
                font-size: 11px;
              }
              .branch-item {
                font-size: 13px;
                padding: 8px 12px;
              }
            }

            @media (max-width: 420px) {
               .container {
                flex-direction: column;
                padding: 0 10px;
                margin: 20px auto;
              }
                .hero-heading{
                margin-top:-90px;
                }
.hero-form{
margin-top:-80px;}
            
              .search-container {
    display: none;
  }
            .course-span{
            margin-left:-10px;
            font-size:15px;
            }
            .nav-right .call-content::before{
            display:none;
            }
            .tabs {
                padding: 5px;
                flex-direction:row;
              
                

              }
              .tabs button {
                font-size: 12px;
                padding: 6px;
                gap: 5px;
              }
              .tabs h5 {
                font-size: 16px;
                margin-left: 10px;
              }
              .content {
                padding: 10px;
              }
              .grid {
                gap: 10px;
              }
              .accordion-header {
                gap: 10px;
                padding: 8px;
              }
              .course-icon {
                width: 25px;
                height: 25px;
              }
              .course-title {
                font-size: 14px;
              }
              .branch-count {
                font-size: 10px;
              }
              .accordion-content {
                padding: 8px;
              }
              .branch-item {
                font-size: 12px;
                padding: 6px 10px;
              }
              .accordion-card.expanded {
                padding: 15px;
              }
            }
  `}
        </style>
      </main>
    </>
  );
};

export default Admission_Enquiry;

import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function EventCalender() {
  return (
    <>
      <Nav />
      <style>
        {`

        .custom_link{
        display: inline-block;
         width: 100%;
          text-align: left;
          }

.custom_span{
color:#003049;
  float: right;
        margin-left: 10px;
}

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 118px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
        }

         .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 30%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }  
        @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }  
        
      table {
        width: 50%; /* Make tables full-width on smaller screens */
      }
    }

 `}
      </style>

      <h2 className="h2 title-main" style={{ color: "#780000" }}>
        EVENT CALENDER
      </h2>
      <hr className="title-hr"></hr>
      <h5
        style={{
          fontFamily: "'poppins',sansSerif",
          textAlign: "center",
          color: "#FA812F",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        Event Calender for the year 2024-25
      </h5>
      <table
        style={{
          width: "80%",
          marginLeft: "70px",
          marginTop: "10px",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            {" "}
            <th>EVENT</th> <th>DEPARTMENT</th>
          </tr>

          <tr>
            <td rowSpan={3}>FACULTY OF EDUCATION </td>
            <td>
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
                target="_blank"
              >
                B.ED Department Calendar
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
                target="_blank"
              >
                {" "}
                B.A. B.ED Department Calendar{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
                target="_blank"
              >
                {" "}
                B.P.ED Department Calendar{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td rowSpan={3}> PHARMACY </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/College of Pharmacy.pdf"
                target="_blank"
              >
                {" "}
                COLLEGE OF PHARMACY{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Schoo Of Pharmacy (Revised).pdf"
                target="_blank"
              >
                {" "}
                SCHOOL OF PHARMACY{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Polytechnic%20Pharmacy.pdf"
                target="_blank"
              >
                {" "}
                POLYTECHNIC PHARMACY
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>
          <tr>
            <td rowSpan={8}> SCHOOL OF ENGINEERING</td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Aeronautical engineering department.pdf"
                target="_blank"
              >
                {" "}
                AERONAUTICAL DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Chemical Engineering department.pdf"
                target="_blank"
              >
                {" "}
                CHEMICAL DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Civil engineering department.pdf"
                target="_blank"
              >
                {" "}
                CIVIL DEPARTMENT <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                // to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Mechanical Engineering department.pdf"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Mechanical%20Engieering%20(Revised).pdf"
                target="_blank"
              >
                MECHANICAL DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Computer science and engineering department.pdf"
                target="_blank"
              >
                COMPUTER SCIENCE AND ENGINEERING DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/EC & EI.pdf"
                target="_blank"
              >
                ECE/EI ENGINEERING DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/EE & EEE.pdf"
                target="_blank"
              >
                EEE/EE ENGINEERING DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>

          <tr>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/mining.pdf"
                target="_blank"
              >
                MINING ENGINEERING DEPARTMENT{" "}
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>

          <br></br>

          <tr>
            <td> SCHOOL OF PARAMEDICAL STUDIES </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/paramedial revised.pdf"
                target="_blank"
              >
                {" "}
                PARAMEDICAL DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td> SCHOOL OF HOMEOPATHY </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/homeopathy.pdf"
                target="_blank"
              >
                {" "}
                HOMEOPATHY DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td> SCHOOL OF DESIGN </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Design (Architecture).pdf"
                target="_blank"
              >
                {" "}
                DESIGN DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td> SCHOOL OF AYURVEDA AND SIDDHA </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/ayurveda and siddha.pdf"
                target="_blank"
              >
                {" "}
                AYURVEDA AND SIDDHA DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td> SCHOOL OF NURSING </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Nursingh (Revised).pdf"
                target="_blank"
              >
                {" "}
                NURSING DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>
          <tr>
            <td> SCHOOL OF AGRICULTURE </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Agriculture.pdf"
                target="_blank"
              >
                {" "}
                AGRICULTURE DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>
          <tr>
            <td> SCHOOL OF HOTEL MANAGEMENT </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Hotel Management.pdf"
                target="_blank"
              >
                {" "}
                HOTEL MANAGEMENT AND CATERING TECHNOLOGY
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td> SCHOOL OF LAW </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Revised Event Calendar LLB.pdf"
                target="_blank"
              >
                {" "}
                BACHELOR OF LAWS(LL.B.)
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>
          <tr>
            <td> SCHOOL OF MANAGEMENT STUDIES </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Management Studies.pdf"
                target="_blank"
              >
                {" "}
                MANAGEMENT DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>

          <tr>
            <td>SCHOOL OF POLYTECHNIC ENGINEERING </td>
            <td>
              {" "}
              <Link
                className="custom_link"
                to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Polytechnic engineering.pdf"
                target="_blank"
              >
                {" "}
                MECHANICAL DEPARTMENT
                <span className="custom_span">Click here</span>
              </Link>
            </td>
          </tr>
          <br></br>
        </tbody>
      </table>

      <Footerpage />
    </>
  );
}

export default EventCalender;
